import React, { useState, useMemo, useRef, useEffect } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MdDelete, MdDragIndicator, MdEdit } from 'react-icons/md'
import { FaCircleXmark, FaPlus, FaRegCircleXmark } from 'react-icons/fa6'
import Button from '../Button'
import { IoAddCircleOutline } from 'react-icons/io5'
import axios from "utils/axios";
import PageLoader from '../PageLoader'

// const defaultColumns = [
//   'Buyer',
//   'Contract Requests',
//   'Strata Report',
//   'Re-inspection',
//   'Pre-offers',
//   'Still engaged after increased price point'
// ]
const callApi = async (
  url,
  method = 'GET',
  data = null,
) => {
  try {
    const response = await axios({
      method,
      url,
      data,
    })
    return response?.data
  } catch (error) {
    console.error(error)
    return null
  }
}
const SortableTableRow = ({ row, children }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
  } = useSortable({
    id: row.original._id || row.original.id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <tr 
      ref={setNodeRef}
      style={style}
      className={`px-3 py-3 whitespace-nowrap border border-b-2 transition-colors duration-200 ${row.original.isMarked ? 'bg-red-100' : ''}`}
    >
      {React.Children.map(children, (child, index) => {
        // Apply drag handlers only to the first cell (drag column)
        if (index === 0) {
          return React.cloneElement(child, {
            ...child.props,
            ...attributes,
            ...listeners
          });
        }
        return child;
      })}
    </tr>
  );
};

export default function InteractiveTable({
  propertyId,
  address,
  customTable,
}) {
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [editingCell, setEditingCell] = useState(null)
  const [editingHeader, setEditingHeader] = useState(null)
  const newHeaderRef = useRef(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchTableData = async () => {
      
      const response = await callApi(`/customTable/getTable/${propertyId}`, 'GET');
      if (response) {
        setColumns(response?.data?.columns);
        setData(response?.data?.rows);
        setLoading(false);
      }
    };
    setLoading(true)
    fetchTableData();
  }, []);
  const columnHelper = createColumnHelper()

  const tableColumns = useMemo(
    () => [
      columnHelper.display({
        id: 'drag',
        header: () => <span className="font-semibold"></span>,
        cell: () => (
          <div className="cursor-move px-1">
            <MdDragIndicator 
              size={18} 
              className="text-dark"
            />
          </div>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: () => <span className="font-semibold">Actions</span>,
        cell: ({ row }) => (
          <div className="flex items-center space-x-2"><button
            onClick={() => {
              setData(data.filter(item => {
                return item?._id !== row.original?._id
              }))
              callApi(`/customTable/delete-row/${propertyId}/${
                row?.original?._id
              }`, 'DELETE')
            }}
            className="p-1 text-darkergray"
            title="Delete row"
          >
            <MdDelete size={18} />
          </button>
          <button
  onClick={() => {
    setData(data.map(item => 
      item?._id === row?.original?._id 
        ? { ...item, isMarked: !item.isMarked }
        : item
    ))
    callApi(`/customTable/update-row-stataus/${propertyId}/${
                row?.original?._id
              }`, 'PUT',{
                status: !row.original.isMarked
              })
  }}
  className={`p-1 ${row.original.isMarked ? 'text-red-500 hover:text-red-700' : 'text-gray-500 hover:text-gray-700'} transition-colors duration-200`}
  title={row.original.isMarked ? 'Unmark row' : 'Mark row'}
>
{
  row.original.isMarked ? <FaRegCircleXmark size={18} /> : <FaCircleXmark size={18} color='red'/>
}

</button></div>
          
        ),
      }),
      ...columns.map((col) =>
        columnHelper.accessor(col?.headerName, {
          header: () => (
            <div className="flex items-center justify-between">
              {((editingHeader === col?.id) || (editingHeader === col?._id)) ? (
                <input
                  ref={newHeaderRef}
                  defaultValue={col?.headerName}
                  onBlur={(e) => {
  const newColumns = columns.map((c) => {
      if (c?.id && col?.id && c?.id === col?.id) {
        const updatedColumn = {
        ...c,
        headerName: e.target.value || '[Enter Name]',
      };

        callApi(`/customTable/add-column/${propertyId}`, 'POST', {
          headerName: e.target.value || '[Enter Name]',
          tempId: col?.id,
        }).then((promise) => {
        // Handle the API response (e.g., setting _id later)
        setColumns((prevColumns) =>
          prevColumns.map((prevCol) =>
            prevCol.id === col.id ? { headerName:prevCol?.headerName, _id: promise?.data?._id } : prevCol
          )
        );
      });
      return updatedColumn;
      }

      if (c?._id && col?._id && c?._id === col?._id) {
        callApi(`/customTable/update-header/${propertyId}/${col?._id}`, 'PUT', {
          headerName: e.target.value || '[Enter Name]',
        });

        return {
          ...c,
          headerName: e.target.value || '[Enter Name]',
        };
      }

      return c;
    })

  setColumns(newColumns);
  setEditingHeader(null);
}}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.currentTarget.blur()
                    }
                  }}
                  className="form-input w-auto"
                />
              ) : (
                <span className="font-semibold">{col?.headerName}</span>
              )}
              <div className="flex items-center">
                <button
                  onClick={() => setEditingHeader(
                    col?.id || col?._id
                  )}
                  className="p-1 text-gray-500 hover:text-gray-700 transition-colors duration-200"
                  title="Edit column name"
                >
                  <MdEdit size={14} />
                </button>
                <button
                  onClick={() => {
                    console.log(col, columns)
                    if(col?._id) {
                      setColumns(columns.filter((c) => c?._id !== col?._id))
                    }
                    if(col?.id) {
                      setColumns(columns.filter((c) => c?.id !== col?.id))
                    }
                    setData(data.map(row => {
                      const newRow = {...row}
                      delete newRow[col]
                      
                      return newRow
                    }))
                    callApi(`/customTable/delete-column/${propertyId}/${col?._id}`, 'DELETE')
                  }}
                  className="p-1 text-darkergray"
                  title="Delete column"
                >
                  <MdDelete size={14} />
                </button>
              </div>
            </div>
          ),
          cell: ({ row, column }) => (
            editingCell?.rowId === row.original._id && editingCell?.columnId === col?._id ? (
              <input
                value={row.getValue(column.id)}
                onChange={(e) =>
                  setData(
                    data.map((r) => {
                      if (r._id === row.original._id) {
                        return {
                          ...r,
                          [column.id]: e.target.value,
                        }
                      }
                      return r
                    })
                  )
                }
                onBlur={(e) => {
                  setEditingCell(null)
                  callApi(`/customTable/update-cell/${propertyId}`, 'PUT', {
                    rowId: row.original._id,
                    columnId: col?._id,
                    value: e.target.value,
                  })
                }}
                autoFocus
                className="form-input w-full"
              />
            ) : (
              <div
                onClick={() => {
                  return setEditingCell({ rowId: row.original._id, columnId: col?._id })
                }}
                className="cursor-pointer px-2 py-1"
              >
                {
                  row.getValue(column.id) || row.original?.data?.[col?._id]
                  || <IoAddCircleOutline size={18} />
                }
              </div>
            )
          ),
        })
      )
    ],
    [columns, data, editingCell, editingHeader]
  )

  const table = useReactTable({
    data,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  })

  const addNewRow = async() => {
    
    const newRow = { id: Date.now().toString() }
    columns.forEach((col) => {
      newRow[
        col?._id || col?.id
      ] = ''
    })
    setData([...data, newRow])
    const response= await callApi(`/customTable/add-row/${propertyId}`, 'POST', {
      data: {},
      tempId: newRow.id,
    })
    setData([...data, {
      ...newRow,
      _id: response?.data?._id,
    }])
  }

  const addNewColumn = () => {
    const newColumn = {
      id: Date.now().toString(),
      headerName: `Column ${columns.length + 1}`,
    }
    setColumns([...columns, newColumn])
    setData(data.map(row => ({ ...row, [newColumn?.id]: '' })))
    setEditingHeader(newColumn?.id)
  }

  useEffect(() => {
    if (editingHeader && newHeaderRef.current) {
      newHeaderRef.current.focus()
    }
  }, [editingHeader])
  const handleDragEnd = (event) => {
    console.log(event)
    const { active, over } = event;
    
    if (active.id !== over.id) {
      setData((items) => {
        const oldIndex = items.findIndex(item => (item._id || item.id) === active.id);
        const newIndex = items.findIndex(item => (item._id || item.id) === over.id);
        
        const newOrder = arrayMove(items, oldIndex, newIndex);
        
        callApi(`/customTable/reorder-rows/${propertyId}`, 'PUT', {
          rowOrder:newOrder
        });
        
        return newOrder;
      });
    }
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="container mx-auto">
      <div className="mb-3 flex justify-end items-center">
        <div className="flex space-x-2">
          <Button
            onClick={addNewColumn}
            className='black-button flex'
          >
            <FaPlus size={18} className="mr-2" />
            Add Column
          </Button>
          <Button
            onClick={addNewRow}
            className='black-button flex'
          >
            <FaPlus size={18} className="mr-2" />
            Add Row
          </Button>
        </div>
      </div>
      <div className="table-responsive overflow-x-auto">
      <DndContext
        sensors={useSensors(
          useSensor(isMobile ? TouchSensor : PointerSensor),
          useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
          })
        )}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <table className=" bg-lightgray">
          <thead
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="px-3 py-3 text-left text-xs font-medium text-black uppercase tracking-wider whitespace-nowrap w-full overflow-hidden">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          <SortableContext
              items={data.map(item => item._id || item.id)}
              strategy={verticalListSortingStrategy}
            >
            {table.getRowModel().rows.map((row) => (
              <SortableTableRow key={row.original._id || row.original.id} row={row} className={`px-3 py-3 whitespace-nowrap border border-b-2 transition-colors duration-200 ${row.original.isMarked ? 'bg-red-100' : ''}`}>
              {/* <tr key={row.id} className={`px-3 py-3 whitespace-nowrap border border-b-2 transition-colors duration-200 ${row.original.isMarked ? 'bg-red-100' : ''}`}> */}
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={`${row.original.isMarked ? 'text-red-600' : ''}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
                
              {/* </tr> */}
              </SortableTableRow>
            ))}</SortableContext>
          </tbody>
        </table>
        </DndContext>
      </div>
    </div>
  )
}