import React, { useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import Lightbox from "react-image-lightbox"; // Import lightbox
import "react-image-lightbox/style.css"; // Import lightbox styles
import Modal from "components/ui/Modal"; // Assuming Modal component is in the same directory
import Button from "components/ui/Button"; // Assuming Button component is in the same directory
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  FaFileUpload,
  FaTrash,
  FaThLarge,
  FaList,
  FaPaperPlane,
} from "react-icons/fa";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { TouchSensor, MouseSensor } from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const ImageContainer = ({
  image,
  isSelected,
  onSelect,
  onPreview,
  viewMode = "grid",
}) => {

  return (
    <div
      className={`relative group cursor-move ${
        isSelected ? "border border-darkgray p-1" : ""
      }`}
    >
      <img
        src={image.url}
        alt={image.name}
        className={`${
          viewMode === "grid" ? "h-40" : "max-h-[320px]"
        } w-full object-cover cursor-pointer border border-lightgray p-2`}
        onClick={() => onPreview(image.id)} // Open lightbox on click
      />
      <div className="absolute top-2 right-2 flex space-x-2 items-center">
        {/* Checkbox for selection */}

        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => {
            console.log("Checkbox clicked for image ID:", image.id);
            onSelect(image.id); // Toggle selection
          }}
          className=" bg-lightgray rounded hover:bg-mediumgray m-0 p-0 scale-125"
        />

        {/* Delete button */}
        {/* <button
          onClick={(e) => {
            e.stopPropagation();
            onDelete(image.id);
          }}
          className="bg-lightgray rounded-full p-1 hover:bg-mediumgray"
        >
          <FaTrash className="text-black text-xs" />
        </button> */}
      </div>
    </div>
  );
};

const DraggableImage = ({ image }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="p-2 border border-lightgray rounded cursor-move w-auto h-24"
    >
      <img
        src={image.url}
        alt={image.name}
        className="w-full h-full object-cover"
      />
    </div>
  );
};

// Main Photo Management Component
const ImageUpload = ({ images, setImages }) => {
  // const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [viewMode, setViewMode] = useState("grid");
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailData, setEmailData] = useState({ email: "", message: "" });
  const [lightboxIndex, setLightboxIndex] = useState(-1);

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);

  const openOrderModal = () => setIsOrderModalOpen(true);
  const closeOrderModal = () => setIsOrderModalOpen(false);

  const sensors = useSensors(
    useSensor(MouseSensor), // For desktop drag-and-drop
    useSensor(TouchSensor) // For mobile drag-and-drop
  );

  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files).map((file, index) => ({
      id: `${Date.now()}-${index}`, // Unique ID
      file,
      url: URL.createObjectURL(file),
      name: file.name,
    }));
    setImages((prevImages) => [...prevImages, ...newFiles]);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setImages((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const toggleImageSelection = (imageId) => {
    setSelectedImages((prevSelected) => {
      if (prevSelected.includes(imageId)) {
        return prevSelected.filter((id) => id !== imageId);
      } else {
        return [...prevSelected, imageId];
      }
    });
  };

  const selectAllImages = () => {
    setSelectedImages(
      selectedImages.length === images.length ? [] : images.map((img) => img.id)
    );
  };

  const deleteSelectedImages = () => {
    setImages(images.filter((img) => !selectedImages.includes(img.id)));
    setSelectedImages([]);
  };

  const deleteImage = (imageId) => {
    setImages(images.filter((img) => img.id !== imageId));
    setSelectedImages((prev) => prev.filter((id) => id !== imageId));
  };

  const sendToEditor = () => {
    console.log("Sending images:", selectedImages);
    console.log("To email:", emailData.email);
    console.log("Message:", emailData.message);
    setIsEmailModalOpen(false);
  };

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <DisclosureButton className="rounded-lg w-full cursor-pointer bg-lightgray p-4 my-1 flex items-center justify-between text-black font-semibold">
              <div className="flex items-center space-x-2">
                <p>Upload Photos</p>
              </div>
              <HiChevronDown
                className={`transition-transform duration-300 ${
                  open ? "rotate-180" : ""
                }`}
              />
            </DisclosureButton>
            <DisclosurePanel className="py-2 text-sm text-start">
              <div className="w-full">
                <div className="flex justify-between mb-4">
                  <div className="flex gap-2">
                    <input
                      type="file"
                      multiple
                      onChange={handleFileUpload}
                      className="hidden"
                      id="file-upload"
                      accept="image/*"
                    />
                    <label
                      htmlFor="file-upload"
                      className="flex items-center px-4 py-1.5 bg-black text-white rounded cursor-pointer"
                    >
                      <FaFileUpload className="mr-2" /> Upload
                    </label>
                    {images.length > 0 && (
                      <div className="flex items-center space-x-2">
                        <Button
                          className="gray-button"
                          onClick={selectAllImages}
                        >
                          {selectedImages.length === images.length
                            ? "Deselect All"
                            : "Select All"}
                        </Button>
                        {selectedImages.length > 0 && (
                          <Button
                            className="gray-button p-2 text-black"
                            onClick={deleteSelectedImages}
                          >
                            <FaTrash />
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                  {images.length > 0 && (
                    <div className="flex space-x-2">
                      <Button
                        onClick={() => setViewMode("grid")}
                        className={viewMode === "grid" ? "gray-button" : ""}
                      >
                        <FaThLarge />
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => setViewMode("list")}
                        className={viewMode === "list" ? "gray-button" : ""}
                      >
                        <FaList />
                      </Button>
                    </div>
                  )}
                </div>

                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={images.map((img) => img.id)}
                    strategy={rectSortingStrategy}
                  >
                    <div
                      className={`grid gap-4 mb-4 ${
                        viewMode === "grid"
                          ? "grid-cols-2 sm:grid-cols-3 md:grid-cols-4"
                          : "grid-cols-1"
                      }`}
                    >
                      {images.map((image, index) => (
                        <ImageContainer
                          key={image.id}
                          image={image}
                          isSelected={selectedImages.includes(image.id)} // Check by unique ID
                          onSelect={toggleImageSelection}
                          onDelete={deleteImage}
                          onPreview={() => setLightboxIndex(index)}
                          viewMode={viewMode}
                        />
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>

                {images.length > 0 && (
                  <div className="text-center mb-4 flex gap-2">
                    <Button onClick={openOrderModal} className="gray-button">
                      Change Order
                    </Button>
                    <Button
                      onClick={() => setIsEmailModalOpen(true)}
                      className="black-button flex"
                    >
                      <FaPaperPlane className="mr-2" /> Send to Editor
                    </Button>
                  </div>
                )}

                <Modal
                  isOpen={isOrderModalOpen}
                  onClose={closeOrderModal}
                  title="Change Order of Images"
                >
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={images.map((img) => img.id)}
                      strategy={rectSortingStrategy}
                    >
                      <div className="grid grid-cols-3 gap-4">
                        {images.map((image) => (
                          <DraggableImage key={image.id} image={image} />
                        ))}
                      </div>
                    </SortableContext>
                  </DndContext>
                </Modal>

                {/* Email Modal */}
                <Modal
                  isOpen={isEmailModalOpen}
                  onClose={() => setIsEmailModalOpen(false)}
                  title="Send to Editor"
                >
                  <div className="space-y-4 text-start">
                    <div>
                      <label className="form-label">Email</label>
                      <input
                        id="email"
                        type="email"
                        value={emailData.email}
                        onChange={(e) =>
                          setEmailData((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }
                        className="form-input border border-mediumgray"
                        placeholder="Enter email address"
                      />
                    </div>
                    <div>
                      <label className="form-label">Message</label>
                      <textarea
                        id="message"
                        value={emailData.message}
                        onChange={(e) =>
                          setEmailData((prev) => ({
                            ...prev,
                            message: e.target.value,
                          }))
                        }
                        className="form-textarea border border-mediumgray"
                        placeholder="Optional message"
                        rows="3"
                      />
                    </div>
                    <div className="flex justify-end space-x-2">
                      <Button
                        variant="outline"
                        onClick={() => setIsEmailModalOpen(false)}
                        className="gray-button"
                      >
                        Cancel
                      </Button>
                      <Button onClick={sendToEditor} className="black-button">
                        Send
                      </Button>
                    </div>
                  </div>
                </Modal>

                {/* Lightbox for Image Preview */}
                {lightboxIndex >= 0 && (
                  <Lightbox
                    mainSrc={images[lightboxIndex].url}
                    nextSrc={
                      images[(lightboxIndex + 1) % images.length]?.url ||
                      images[0].url
                    }
                    prevSrc={
                      images[
                        (lightboxIndex + images.length - 1) % images.length
                      ]?.url || images[0].url
                    }
                    onCloseRequest={() => setLightboxIndex(-1)}
                    onMovePrevRequest={() =>
                      setLightboxIndex(
                        (lightboxIndex + images.length - 1) % images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setLightboxIndex((lightboxIndex + 1) % images.length)
                    }
                    reactModalStyle={{
                      overlay: {
                        zIndex: 9999999, // Set the maximum z-index
                      },
                    }}
                  />
                )}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </>
  );
};

const PrepareMarketing = ({ property }) => {
  const [brochureImagesModal, setBrochureImagesModal] = useState(false);
  const [brochureImages, setBrochureImages] = useState([]); // State for selected brochure images
  const [images, setImages] = useState([]); // Shared state for all uploaded images

  const toggleBrochureImageSelection = (imageId) => {
    setBrochureImages(
      (prevSelected) =>
        prevSelected.includes(imageId)
          ? prevSelected.filter((id) => id !== imageId) // Deselect if already selected
          : [...prevSelected, imageId] // Add if not already selected
    );
  };

  return (
    <div className="w-full h-full max-w-4xl mx-auto text-center flex flex-col justify-between">
      <p className="mb-4">{property.address}</p>
      <ImageUpload images={images} setImages={setImages} />
      <br></br>
      {/* Brochure Images section */}
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h4>Brochure</h4>
          <Button
            className="black-button"
            onClick={() => setBrochureImagesModal(true)}
          >
            Select Images
          </Button>
        </div>

        <div className="grid gap-4 mb-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4">
          {brochureImages.map((imageId) => {
            const image = images.find((img) => img.id === imageId); // Find image object by ID
            return (
              image && (
                <img
                  key={image.id}
                  src={image.url}
                  alt={image.name}
                  className="h-40 object-cover rounded border border-lightgray p-2"
                />
              )
            );
          })}
        </div>
      </div>

      <Modal
        isOpen={brochureImagesModal}
        onClose={() => setBrochureImagesModal(false)}
        title="Select Brochure Images"
      >
        <div className="grid gap-4 mb-4 grid-cols-2 sm:grid-cols-3">
          {images.map((image, index) => (
            <ImageContainer
              key={image.id}
              image={image}
              isSelected={brochureImages.includes(image.id)} // Check by unique ID
              onSelect={toggleBrochureImageSelection}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default PrepareMarketing;
