import React, { useState, useRef, useEffect } from "react";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import axios from "utils/axios";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { fetchSignatureUrl, formatDateToAEDT } from "utils/helperFunctions";
import "../components/chat/AuthoriseSchedule.css";
import PageLoader from "components/ui/PageLoader";
import AuthoriseScheduleEditorLock from "components/chat/AuthoriseScheduleEditorLock";
import Tooltip from "components/ui/Tooltip";
import MarketingPrice from "components/ui/MarketingPrice";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa6";

const Esign = () => {
  const [loading, setLoading] = useState(false);
  const sigCanvas = useRef(null);
  const [signConfirm, setSignConfirm] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [termsOpen, setTermsOpen] = useState(false);

  const { propertyId, vendorId } = useParams();
  const [authData, setAuthData] = useState(null);
  const [marketingTable, setMarketingTable] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [vendorStatus, setVendorStatus] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(true);

  const signatureSectionRef = useRef(null);
  const scrollToSignatureSection = () => {
    signatureSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: vendor,
    mode: "onChange", // Enable real-time validation
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingData(true);
        const response = await axios.get(`/authSchedule/${propertyId}`);
        const data = response.data.data;
        setAuthData(data);
        if (data && data.vendors) {
          const index = parseInt(vendorId, 10);
          if (!isNaN(index) && index >= 0 && index < data.vendors.length) {
            const vendorData = data.vendors[index];
            if (!vendorData.viewedDate) {
              await axios.put(`/authSchedule/viewedDate/${propertyId}`, {
                viewedDate: formatDateToAEDT(null),
                index,
              });
            }
            setVendor(vendorData);
            setIsSigned(vendorData.isSigned);

            // Set form default values when vendor data is available
            Object.keys(vendorData).forEach((key) => {
              setValue(key, vendorData[key]);
            });
          }
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      } finally {
        setIsFetchingData(false);
      }
    };

    fetchData();
  }, [propertyId, vendorId]);

  const clear = () => {
    sigCanvas.current?.clear();
  };

  const handleSaveClick = async () => {
    // Get all field names and exclude "agreeTerms" from validation
    const allFields = Object.keys(getValues()); // Get all registered fields
    console.log(allFields);
    const fieldsToValidate = allFields.filter(
      (field) => field !== "agreeTerms"
    ); // Exclude agreeTerms
    console.log(fieldsToValidate);

    // Trigger validation only for the filtered fields
    const result = await trigger(fieldsToValidate);

    if (result && !sigCanvas.current?.isEmpty()) {
      const trimmedCanvas = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignatureDataURL(trimmedCanvas);
      setSignConfirm(true);
    } else if (!result) {
      console.log("Form validation failed");
    } else {
      console.log("Signature is empty");
    }
  };

  const handleConfirmSign = async () => {
    // Get the form values from the react-hook-form (watch or getValues can be used)
    const fields = [
      "firstName",
      "lastName",
      "email",
      "address",
      "licence",
      "isCompany",
      "company",
      "abn",
      "gst",
    ];
    const formValues = fields.reduce((acc, field) => {
      acc[field] = watch(field);
      return acc;
    }, {});

    const isTermsValid = watch("agreeTerms");
    if (!isTermsValid) {
      setError("agreeTerms", {
        type: "manual",
        message: "You must agree to the terms before signing",
      });
      return;
    } else {
      clearErrors("agreeTerms"); // Clear the error when terms are valid
    }

    // Update vendor with the form values and additional fields
    const updatedVendor = {
      ...formValues, // Include firstName, lastName, email, and address from the form
      signature: signatureDataURL,
      signedDate: formatDateToAEDT(null),
      agreeTerms: true,
      isSigned: true,
    };

    try {
      setLoading(true);
      const res = await axios.put(`/authSchedule/${propertyId}`, {
        vendorId,
        vendor: updatedVendor,
      });
      if (res.data.success) {
        setSignConfirm(false);
        setIsSigned(true);
        setVendor(updatedVendor);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const generateAndOpenPDF = async () => {
    try {
      // Open a blank tab immediately in response to user action
      const newWindow = window.open("", "_blank");

      if (!newWindow) {
        console.error("Popup was blocked or could not be opened");
        return; // Exit if the window couldn't be opened
      }

      newWindow.document.write(`
        <div style="display: flex; justify-content: center; align-items: center; height: 100vh; font-size: 32px; font-family: Arial, sans-serif;">
          <p style="font-size: 32px;">Loading PDF, please wait...</p>
        </div>
      `);

      // Make sure to set responseType to 'blob' to handle binary data
      const response = await axios.post(
        "/authSchedule/generatePdf",
        { content: authData }, // This is the request body
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob", // Important for handling binary data
        }
      );

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Generate a URL for the Blob
      const pdfUrl = window.URL.createObjectURL(blob);

      // Redirect the new tab to the generated PDF
      newWindow.location.href = pdfUrl;

      // (Optional) Clean up the URL object after some time to release memory
      setTimeout(() => {
        window.URL.revokeObjectURL(pdfUrl);
      }, 1000 * 60); // 1 minute
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  // Add this to the top after useRef:
  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click event
    }
  };

  const handleLicenceUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type and size
      if (!file.type.startsWith("image/")) {
        alert("Please upload a valid image file.");
        e.target.value = null;
        return;
      }

      const maxSizeInBytes = 20 * 1024 * 1024; // 20MB limit
      if (file.size > maxSizeInBytes) {
        alert("File size exceeds 20MB.");
        e.target.value = null;
        return;
      }

      // Prepare for file upload (you can reset validation state here if necessary)
      const reader = new FileReader();
      reader.onloadend = async () => {
        const formData = new FormData();
        formData.append("systemPrompt", "AUTH_SCHEDULE_LICENCE_SCAN");
        formData.append(
          "userMessage",
          "Uploaded Image. Check valid licence image or not."
        );
        formData.append("files", file);

        try {
          setVendorStatus({ isScanning: true, isValidLicence: false });
          const response = await axios.post("/openai/image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          const data = response.data.data;

          if (data.isLicence) {
            // If valid, populate form values
            setValue("firstName", data.firstName || "");
            setValue("lastName", data.lastName || "");
            setValue("address", data.address || "");
            setValue("licence", reader.result, { shouldValidate: true });
            trigger("licence"); // Trigger validation

            setVendorStatus({ isScanning: false, isValidLicence: true });
          } else {
            setVendorStatus({ isScanning: false, isValidLicence: false });
          }
        } catch (error) {
          console.error("Error uploading image:", error);
          setVendorStatus({ isScanning: false, isValidLicence: null });
        } finally {
          e.target.value = null; // Clear the file input after processing
        }
      };
      reader.readAsDataURL(file);
    }
  };

  if (isSigned) {
    return (
      <div className="w-full min-h-screen max-w-4xl mx-auto flex flex-col items-center justify-center px-4">
        You have successfully signed the document
      </div>
    );
  }

  if (isFetchingData || !vendor || !authData) {
    return <PageLoader />;
  }

  const {
    address = "",
    solicitor = {},
    commissionFee = "",
    commissionRange = "",
    terms = "",
    status = "",
    startPrice = "",
    endPrice = "",
    saleProcess = "",
    services,
    fraudPrevention,
    marketing,
    prepareMarketing,
    access,
    conclusionDate,
  } = authData;

  const {
    name: solicitorName = "",
    email: solicitorEmail = "",
    address: solicitorAddress = "",
    mobile: solicitorMobile = "",
  } = solicitor;

  const {
    firstName = "",
    lastName = "",
    email = "",
    mobile = "",
    licence = "",
    address: vendorAddress = "",
    isCompany = false,
    company = "",
    abn = "",
    gst = "",
    agreeTerms = false,
  } = vendor;

  return (
    <>
      <div className="w-full fixed top-0 alert-card bg-lightgray px-4 py-2 flex items-center justify-between z-10">
        <p>Please review the documents below.</p>
        <Button onClick={scrollToSignatureSection} className="black-button">
          Sign Now
        </Button>
      </div>
      <div className="px-4">
        <div className="w-full h-full max-w-4xl mx-auto flex flex-col justify-between authorise-form px-4 pb-8 mt-16">
          <p className="mb-4 text-darkgray text-center">{address}</p>
          <div className="flex flex-col gap-4">
            <div className="w-[50%]">
              <input
                type="text"
                value={saleProcess}
                className={`form-input border border-mediumgray`}
                placeholder="SALE PROCESS"
                readOnly
              />
            </div>

            {/* Vendor Information */}
            <div className="mb-4">
              <div
                id="vendor-info"
                className="col-span-12 grid grid-cols-12 gap-4"
              >
                <div className="col-span-12 flex items-center justify-end gap-2">
                  <input
                    type="checkbox"
                    checked={watch("isCompany")}
                    onChange={(e) => {
                      setValue("isCompany", e.target.checked); // Set the isCompany field
                      if (!e.target.checked) {
                        setValue("company", ""); // Clear company fields if unchecked
                        setValue("abn", ""); // Clear ABN field if unchecked
                        setValue("gst", false); // Clear GST field if unchecked
                      }
                    }}
                    className="p-0 cursor-pointer"
                  />
                  Company
                </div>

                {/* Licence Upload Section */}
                <div className="col-span-12 flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <label>Upload Driver Licence</label>
                    <FaPlus
                      onClick={() => triggerFileInput()}
                      className="cursor-pointer"
                    />
                    {/* Hidden File Input */}
                    <input
                      type="file"
                      ref={fileInputRef} // Use the defined ref for programmatic triggering
                      onChange={handleLicenceUpload}
                      className="hidden"
                      accept="image/*"
                    />

                    <input
                      type="hidden"
                      {...register(`licence`, {
                        required: "Licence is required",
                      })}
                    />
                    {errors?.licence && (
                      <span className="form-error-message text-start pt-1">
                        {errors?.licence.message}
                      </span>
                    )}
                  </div>

                  {/* Conditionally Render Scanning or Validation Messages */}
                  <div className="flex items-center gap-2">
                    {vendorStatus?.isScanning && (
                      <>
                        <i className="fa-solid fa-spinner animate-spin"></i>
                        <span>Scanning image...</span>
                      </>
                    )}
                    {!vendorStatus?.isScanning &&
                      vendorStatus?.isValidLicence === false && (
                        <span className="text-red-500">
                          Not a valid licence
                        </span>
                      )}
                    {((watch("licence") &&
                      vendorStatus?.isValidLicence === true) ||
                      vendor.licence) && (
                      <div className="flex items-center justify-between gap-2">
                        <span>Licence Uploaded!</span>
                        <i
                          className="fa-solid fa-trash cursor-pointer"
                          onClick={() => {
                            setValue("licence", null); // Reset the licence field
                            setVendorStatus({ isValidLicence: null }); // Reset validation status
                          }}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-6">
                  <input
                    type="text"
                    className={`form-input border ${
                      errors?.firstName ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("firstName", {
                      required: "First Name is required",
                    })}
                    placeholder="FIRST NAME"
                  />
                  {errors?.firstName && (
                    <span className="form-error-message text-start pt-1">
                      {errors?.firstName.message}
                    </span>
                  )}
                </div>

                <div className="col-span-6">
                  <input
                    type="text"
                    className={`form-input border ${
                      errors?.lastName ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("lastName", {
                      required: "Last Name is required",
                    })}
                    placeholder="LAST NAME"
                  />
                  {errors?.lastName && (
                    <span className="form-error-message text-start pt-1">
                      {errors?.lastName.message}
                    </span>
                  )}
                </div>

                <div className="col-span-12">
                  <input
                    type="email"
                    className={`form-input border ${
                      errors?.email ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("email", { required: "Email is required" })}
                    placeholder="EMAIL"
                  />
                  {errors?.email && (
                    <span className="form-error-message text-start pt-1">
                      {errors?.email.message}
                    </span>
                  )}
                </div>

                <div className="col-span-12">
                  <input
                    type="text"
                    className="form-input border border-mediumgray"
                    value={mobile}
                    readOnly
                  />
                </div>

                <div className="col-span-12">
                  <input
                    type="text"
                    className={`form-input border ${
                      errors?.address ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("address", {
                      required: "Address is required",
                    })}
                    placeholder="ADDRESS"
                  />
                  {errors?.address && (
                    <span className="form-error-message text-start pt-1">
                      {errors?.address.message}
                    </span>
                  )}
                </div>

                {watch("isCompany") && (
                  <>
                    {/* Company Name Input */}
                    <div className="col-span-12">
                      <input
                        type="text"
                        className={`form-input border ${
                          errors?.company
                            ? "border-red-500"
                            : "border-mediumgray"
                        }`}
                        {...register("company", {
                          required: "Company Name is required",
                        })}
                        placeholder="COMPANY"
                      />
                      {errors?.company && (
                        <span className="form-error-message text-start pt-1">
                          {errors?.company.message}
                        </span>
                      )}
                    </div>

                    {/* ABN Input */}
                    <div className="col-span-12">
                      <input
                        type="text"
                        className={`form-input border ${
                          errors?.abn ? "border-red-500" : "border-mediumgray"
                        }`}
                        {...register("abn", {
                          required: "ABN is required",
                        })}
                        placeholder="ABN"
                      />
                      {errors?.abn && (
                        <span className="form-error-message text-start pt-1">
                          {errors?.abn.message}
                        </span>
                      )}
                    </div>

                    {/* GST Checkbox */}
                    <div className="col-span-12 flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={watch("gst")}
                        onChange={(e) => setValue("gst", e.target.checked)}
                        className="p-0 cursor-pointer"
                      />
                      Registered for GST
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Solicitor Information */}
            <div className="col-span-12 grid grid-cols-12 gap-3">
              <label className="form-label m-0">Solicitor</label>

              <div className="col-span-12 ">
                <input
                  type="text"
                  className={`form-input border border-mediumgray`}
                  value={solicitorName}
                  placeholder="NAME"
                  readOnly
                />
              </div>

              <div className="col-span-12 ">
                <input
                  type="text"
                  className={`form-input border border-mediumgray`}
                  value={solicitorAddress}
                  readOnly
                />
              </div>

              <div className="col-span-12">
                <input
                  type="email"
                  className={`form-input border border-mediumgray`}
                  value={solicitorEmail}
                  readOnly
                />
              </div>

              <div className="col-span-12 ">
                <input
                  type="text"
                  className={`form-input border border-mediumgray`}
                  value={solicitorMobile}
                  readOnly
                />
              </div>
            </div>

            <div className="col-span-12 grid grid-cols-12 gap-3">
              <div className="col-span-6 text-start">
                <label className="form-label">Status</label>
                <input
                  type="text"
                  className={`form-input border border-mediumgray`}
                  value={status}
                  readOnly
                />
              </div>
              <div className="col-span-6 text-start">
                <label className="form-label">Terms</label>

                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    className={`form-input border border-mediumgray`}
                    value={terms}
                    readOnly
                  />
                  <span>days</span>
                </div>

                <div className="flex items-center gap-2 relative">
                  <p className="italic">*1 day termination</p>{" "}
                  <Tooltip
                    className="w-[150px]"
                    text={<i className="fa fa-info-circle text-xs"></i>}
                    tooltip="Termination clause: The vendor may terminate this agreement at any time with one (1) days notice."
                  />
                </div>
              </div>
            </div>

            <div className="col-span-12 grid grid-cols-12 gap-3">
              <div className="col-span-6 text-start">
                <div className="flex items-start gap-2">
                  <label className="form-label">Price & Fee</label>
                  <Tooltip
                    className="w-[200px]"
                    text={<i className="fa fa-info-circle text-xs"></i>}
                    tooltip="This price is the agent’s estimated selling price, determined through a logical assessment of recent nearby sales. The vendor has no obligation to sell the property."
                  />
                </div>

                <div className="flex">
                  <input
                    type="text"
                    className={`form-input rounded-none border border-mediumgray`}
                    value={startPrice}
                    readOnly
                  />

                  <input
                    type="text"
                    className={`form-input rounded-none border border-mediumgray`}
                    value={endPrice}
                    readOnly
                  />
                </div>

                <div className="flex items-center gap-2 justify-start relative">
                  <input
                    type="text"
                    className={`form-input rounded-none border border-t-0 border-b-0 border-mediumgray`}
                    value={commissionFee}
                    readOnly
                  />
                  <span className="absolute right-2">%</span>
                </div>

                <input
                  value={commissionRange}
                  className="form-input rounded-none border border-mediumgray"
                  readOnly
                />
              </div>

              <div className="col-span-6 text-start">
                <div>
                  <label className="form-label">Marketing</label>
                  <div className="border border-mediumgray py-1 px-2">
                    <p>As per shopping cart</p>
                    {marketing && (
                      <p
                        className="underline cursor-pointer"
                        onClick={() => setMarketingTable(true)}
                      >
                        View
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 grid grid-cols-12 gap-3">
              <div className="col-span-4 flex flex-col justify-between">
                <label className="form-label">Prepare Marketing</label>
                <input
                  type="text"
                  className={`form-input border border-mediumgray`}
                  value={prepareMarketing}
                  readOnly
                />
              </div>
              <div className="col-span-4 flex flex-col justify-between">
                <label className="form-label">
                  {saleProcess === "Auction" ? "Auction Date" : "Closing Date"}
                </label>
                <input
                  type="text"
                  className={`form-input border border-mediumgray`}
                  value={conclusionDate}
                  readOnly
                />
              </div>
              <div className="col-span-4 flex flex-col justify-between">
                <label className="form-label">Access</label>
                <input
                  type="text"
                  className={`form-input border border-mediumgray`}
                  value={access}
                  readOnly
                />
              </div>
            </div>

            <div>
              <label className="form-label">Services</label>
              <div className="col-span-12 grid grid-cols-12 gap-3">
                <div className="col-span-4 flex flex-col justify-between">
                  <label
                    className="form-label m-0 font-normal"
                    style={{ fontSize: "10px" }}
                  >
                    Building & Pest
                  </label>
                  <div className="flex flex-col mt-auto">
                    <p className="italic" style={{ fontSize: "10px" }}>
                      *Upfront $55
                    </p>
                    <input
                      type="text"
                      className={`form-input border border-mediumgray`}
                      value={services[0].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-span-4 flex flex-col justify-between">
                  <label
                    className="form-label m-0 font-normal"
                    style={{ fontSize: "10px" }}
                  >
                    Strata Report
                  </label>
                  <div className="flex flex-col mt-auto">
                    <p className="italic" style={{ fontSize: "10px" }}>
                      *Upfront $50 (homesafe)
                    </p>
                    <input
                      type="text"
                      className={`form-input border border-mediumgray`}
                      value={services[1].value}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-span-4 flex flex-col justify-between">
                  <label
                    className="form-label m-0 font-normal"
                    style={{ fontSize: "10px" }}
                  >
                    Styling
                  </label>
                  <div className="flex flex-col mt-auto">
                    <input
                      type="text"
                      className={`form-input border border-mediumgray`}
                      value={services[2].value}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 text-start">
              <label className="form-label">ID Check</label>
              <p className="italic">
                *Sight ID, credit card, water rates, council rates
              </p>
              <input
                type="text"
                className={`form-input border border-mediumgray`}
                value={fraudPrevention}
                readOnly
              />
            </div>

            {/* Signature Pad */}
            <div
              ref={signatureSectionRef}
              className="w-full flex flex-col sm:flex-row sm:gap-4"
            >
              <div className="mb-4 w-full sm:w-1/2">
                <div className="w-full text-start relative">
                  {!isSigned ? (
                    <>
                      <p>
                        {watch("firstName")} {watch("lastName")}, please sign
                        below
                      </p>
                      <div className="border border-mediumgray overflow-hidden">
                        <SignatureCanvas
                          ref={sigCanvas}
                          penColor="black"
                          canvasProps={{
                            className:
                              "w-full max-w-[400px] h-[120px] signature-canvas bg-white",
                          }}
                          clearOnResize={false}
                        />
                      </div>
                      <div className="absolute top-28 right-2 flex space-x-2">
                        <Button onClick={clear} className="text-xs">
                          Clear
                        </Button>
                      </div>
                      <div className="flex justify-between mt-2">
                        <Button
                          onClick={generateAndOpenPDF}
                          className="gray-button"
                        >
                          View Agreement
                        </Button>
                        <Button
                          className="black-button"
                          onClick={handleSaveClick}
                          loading={loading}
                        >
                          Finish
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center gap-2">
                        <p>Signed Successfully</p>
                      </div>
                      {signatureDataURL && (
                        <div className="mt-2">
                          <img
                            src={signatureDataURL}
                            alt="Signature"
                            className="w-auto p-2 h-16 object-fill border border-mediumgray"
                          />
                        </div>
                      )}
                    </>
                  )}

                  {/* Modal for Sign Confirmation */}
                  <Modal
                    isOpen={signConfirm}
                    onClose={() => setSignConfirm(false)}
                    title="Sign Confirmation"
                  >
                    <div className="flex flex-col items-center justify-center gap-4">
                      <p>
                        I, {watch("firstName")} {watch("lastName")}, confirm I
                        am {watch("firstName")} {watch("lastName")} as
                        identified by documents provided and/or sighted by
                        Ausrealty. This signature is my own and I also confirm I
                        agree to this electronic signing format and the agency
                        agreement terms and conditions.
                      </p>

                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            className="p-0"
                            {...register("agreeTerms", {
                              required:
                                "You must agree to the terms before signing",
                            })}
                            onChange={(e) => {
                              setValue("agreeTerms", e.target.checked); // Set the value manually
                              if (e.target.checked) {
                                clearErrors("agreeTerms"); // Clear error if checked
                              }
                            }}
                          />

                          <label
                            onClick={() => {
                              setSignConfirm(false);
                              setTermsOpen(true);
                            }}
                            className="underline cursor-pointer"
                          >
                            TERMS
                          </label>
                        </div>
                      </div>

                      {errors.agreeTerms && (
                        <span className="form-error-message text-start pt-1 text-red-500">
                          {errors.agreeTerms.message}
                        </span>
                      )}

                      <div className="w-full flex gap-2 justify-end py-4">
                        <Button
                          onClick={() => setSignConfirm(false)}
                          className="gray-button"
                        >
                          No
                        </Button>
                        <Button
                          onClick={handleConfirmSign}
                          className="black-button"
                          loading={loading}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  </Modal>

                  {/* Modal for Agreed Terms */}
                  <Modal
                    isOpen={termsOpen}
                    onClose={() => {
                      setTermsOpen(false);
                      setSignConfirm(true);
                    }}
                    title=""
                    className="max-w-4xl max-h-[94vh] px-2"
                  >
                    <AuthoriseScheduleEditorLock formData={authData} />
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          <Modal
            isOpen={marketingTable}
            onClose={() => setMarketingTable(false)}
            title="Shopping Cart"
          >
            {/* <MarketingPrice /> */}
            <MarketingPrice
              suburb={authData?.property?.suburb}
              price={authData?.endPrice}
              postcode={authData?.property?.postcode}
              isEdit={false}
              existingData={marketing} // Watch for existing form data
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Esign;
