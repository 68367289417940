import React, { useState, useEffect } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import axios from "utils/axios";
import { HiChevronDown } from "react-icons/hi2";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { useForm } from "react-hook-form";
import { showToast } from "components/ui/toast";

const Compliance = ({ property }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    unregister,
    setValue, // Used to set field value programmatically
    trigger,
  } = useForm({
    defaultValues: {
      propertyId: property?._id,
    },
  });

  const [agreementUrl, setAgreementUrl] = useState(null);

  const [proofUrl, setProofUrl] = useState(null);
  const [solicitorUrl, setSolicitorUrl] = useState(null);

  const [serviceBuildingUrl, setServiceBuildingUrl] = useState(null);
  const [serviceStrataUrl, setServiceStrataUrl] = useState(null);

  const [vendors, setVendors] = useState([]);
  const [authData, setAuthData] = useState(null);

  const [otherDocuments, setOtherDocuments] = useState([]);

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteloading, setDeleteLoading] = useState(false);
  const [uploadType, setUploadType] = useState(null);

  const onSubmit = async (data) => {
    try {
      setUploadLoading(true);
      // Create a FormData object
      const formData = new FormData();
      formData.append("propertyId", data.propertyId);
      formData.append("name", data.name);
      console.log(uploadType)
      if (uploadType) {
        formData.append("documentType", uploadType);
      } else {
        formData.append("description", data.description);
      }
      formData.append("file", data.file[0]); // Access the first file from the FileList

      // Make the API call to upload the document
      const response = await axios.post("/compliance", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set correct content type
        },
      });

      if (response?.data?.success) {
        showToast("success", "Document uploaded successfully");
        setOtherDocuments((prev) => [...prev, response.data.data]);
        setUploadModalOpen(false);
      } else {
        throw new Error("Unexpected response from the server");
      }
    } catch (error) {
      console.error("Upload document failed:", error);
      showToast("error", "Upload document failed. Please try again.");
    } finally {
      setUploadLoading(false);
    }
  };

  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const handleDeleteDocument = async (documentId) => {
    try {
      setDeleteLoading(true);
      await axios.delete(`/compliance/${documentId}`);
      showToast("success", "Document deleted successfully");
      setOtherDocuments((prev) => prev.filter((doc) => doc._id !== documentId)); // Remove the document from the state
    } catch (error) {
      console.error("Error deleting document:", error);
      showToast("error", "Failed to delete document. Please try again.");
    } finally {
      setDeleteLoading(false);
      setDeleteConfirmation(false);
    }
  };

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        const objectIdString = property._id.toString(); // Convert ObjectId to string

        const response = await axios.get(
          `/authSchedule/get-signature-url/${objectIdString}`
        );
        if (response.data.success) {
          setAgreementUrl(response.data.data.agreementUrl);
          setProofUrl(response.data.data.proofUrl);
          setVendors(response.data.data.vendors);
          setSolicitorUrl(response.data.data?.solicitorUrl || null);
          setServiceBuildingUrl(response.data.data?.serviceBuildingUrl || null);
          setServiceStrataUrl(response.data.data?.serviceStrataUrl || null);
        }
      } catch (err) {
        console.error("Error fetching the PDF URL", err);
      }
    };

    fetchPdfUrl();
  }, [property]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/authSchedule/${property._id}`);
        const data = response.data.data; // Adjust according to your API response structure

        if (data) {
          setAuthData(data);
        }
      } catch (error) {
        console.error("Error fetching authorise schedule data:", error);
      }
    };

    fetchData();
  }, [property]);

  useEffect(() => {
    const fetchComplianceDocuments = async () => {
      try {
        const response = await axios.get(`/compliance`, {
          params: { propertyId: property._id }, // Properly closing the object
        });

        if (response?.data?.success) {
          setOtherDocuments(response.data.data); // Update the state with the fetched documents
        } else {
          throw new Error("Failed to fetch compliance documents");
        }
      } catch (error) {
        console.error("Error fetching compliance documents:", error);
        showToast(
          "error",
          "Failed to fetch compliance documents. Please try again."
        );
      }
    };
    fetchComplianceDocuments();
  }, [property]);

  return (
    <>
      <div className="w-full max-w-4xl mx-auto text-center flex flex-col justify-between space-y-4">
        <h4 className="text-center">Compliance</h4>
        <p>{property.address}</p>
        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">Agency agreement</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="mt-2">
            <div className="flex justify-between p-2">
              <p>Agreement</p>
              <i
                className="fa-solid fa-circle-down cursor-pointer"
                onClick={() => {
                  window.location.href = agreementUrl;
                }}
              ></i>
            </div>
            <div className="flex justify-between p-2">
              <p>Proof of ID Checklist</p>
              <i
                className="fa-solid fa-circle-down cursor-pointer"
                onClick={() => {
                  window.location.href = proofUrl;
                }}
              ></i>
            </div>
            {vendors.map((vendor, index) => (
              <div
                key={index}
                className="flex justify-between p-2 items-center"
              >
                <p>
                  Vendor: {vendor.firstName} {vendor.lastName}
                </p>
                {vendor.licenceSignedUrl ? (
                  <i
                    className="fa-solid fa-circle-down cursor-pointer"
                    onClick={() => {
                      window.location.href = vendor.licenceSignedUrl;
                    }}
                  ></i>
                ) : (
                  <p>No License Image Available</p>
                )}
              </div>
            ))}
          </DisclosurePanel>
        </Disclosure>
        <br></br>
        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">Contract of Sale</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="text-start mt-2">
            {solicitorUrl && (
              <div className="flex justify-between p-2">
                <p>Contract</p>
                <i
                  className="fa-solid fa-circle-down cursor-pointer"
                  onClick={() => {
                    window.location.href = solicitorUrl;
                  }}
                ></i>
              </div>
            )}
            {serviceBuildingUrl && (
              <div className="flex justify-between p-2">
                <p>Building & Pest</p>
                <i
                  className="fa-solid fa-circle-down cursor-pointer"
                  onClick={() => {
                    window.location.href = serviceBuildingUrl;
                  }}
                ></i>
              </div>
            )}
            {serviceStrataUrl && (
              <div className="flex justify-between p-2">
                <p>Strata</p>
                <i
                  className="fa-solid fa-circle-down cursor-pointer"
                  onClick={() => {
                    window.location.href = serviceStrataUrl;
                  }}
                ></i>
              </div>
            )}
            <div className="mt-4">
              {otherDocuments.length > 0 &&
                otherDocuments
                  .filter((doc) => doc.documentType === "Contract")
                  .map((doc, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between mb-3 p-2"
                    >
                      <div>
                        <p>{doc.name}</p>
                        <p className="text-sm">{doc.description}</p>
                      </div>
                      <div className="flex space-x-2">
                        {/* Download Icon */}
                        <i
                          className="fa-solid fa-circle-down cursor-pointer "
                          onClick={() => {
                            window.location.href = doc.downloadUrl;
                          }}
                        ></i>
                        {/* Delete Icon */}
                        <i
                          className="fa-solid fa-trash cursor-pointer"
                          onClick={() => {
                            setSelectedDocumentId(doc._id);
                            setDeleteConfirmation(true);
                          }}
                        ></i>
                      </div>
                    </div>
                  ))}
            </div>
            <Button
              className="black-button mt-4"
              onClick={() => {
                setUploadType("Contract");
                setUploadModalOpen(true);
              }}
            >
              Upload Document
            </Button>
          </DisclosurePanel>
        </Disclosure>
        <br></br>
        <Disclosure as="div" className="w-full" defaultOpen={false}>
          <DisclosureButton className="bg-lightgray p-2 group flex w-full items-center justify-between">
            <span className="font-medium">Other Documents</span>
            <HiChevronDown className="group-data-[open]:rotate-180" />
          </DisclosureButton>
          <DisclosurePanel className="text-start mt-2">
            <div className="mt-4">
              {otherDocuments.length > 0 &&
                otherDocuments
                  .filter((doc) => doc.documentType  !== "Contract")
                  .map((doc, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between mb-3"
                    >
                      <div>
                        <p className="font-medium">{doc.name}</p>
                        <p className="text-sm">{doc.description}</p>
                      </div>
                      <div className="flex space-x-2">
                        {/* Download Icon */}
                        <i
                          className="fa-solid fa-circle-down cursor-pointer "
                          onClick={() => {
                            window.location.href = doc.downloadUrl;
                          }}
                        ></i>
                        {/* Delete Icon */}
                        <i
                          className="fa-solid fa-trash cursor-pointer"
                          onClick={() => {
                            setSelectedDocumentId(doc._id);
                            setDeleteConfirmation(true);
                          }}
                        ></i>
                      </div>
                    </div>
                  ))}
            </div>
            <Button
              className="black-button mt-4"
              onClick={() => setUploadModalOpen(true)}
            >
              Upload Document
            </Button>
          </DisclosurePanel>
        </Disclosure>
      </div>

      <Modal
        isOpen={uploadModalOpen}
        onClose={() => {
          setUploadModalOpen(false);
          setUploadType(null);
        }}
        title="Upload"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-center justify-center">
            <div className="w-full">
              <label className="form-label">Name</label>
              <input
                type="text"
                className={`form-input border ${
                  errors?.name ? "border-red-500" : "border-mediumgray"
                }`}
                {...register(`name`, {
                  required: "Name is required",
                })}
                placeholder="NAME"
              />
              {errors?.name && (
                <span className="form-error-message text-start pt-1">
                  {errors?.name.message}
                </span>
              )}
            </div>
          </div>

          {!uploadType && (
            <div className="flex flex-col items-center justify-center mt-4">
              <div className="w-full">
                <label className="form-label">Description</label>
                <textarea
                  className={`form-input border ${
                    errors?.description ? "border-red-500" : "border-mediumgray"
                  }`}
                  {...register(`description`)}
                  placeholder="DESCRIPTION"
                  rows={3}
                />
                {errors?.description && (
                  <span className="form-error-message text-start pt-1">
                    {errors?.description.message}
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="flex flex-col items-center justify-center mt-4">
            <div className="w-full">
              <label className="form-label">File</label>
              <input
                type="file"
                className={`form-input border ${
                  errors?.file ? "border-red-500" : "border-mediumgray"
                }`}
                {...register(`file`, {
                  required: "File is required",
                  validate: (value) =>
                    value?.length > 0 || "Please upload a file",
                })}
              />
              {errors?.file && (
                <span className="form-error-message text-start pt-1">
                  {errors?.file.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex justify-end">
            <Button
              type="submit"
              className="black-button mt-4"
              loading={uploadLoading}
              disabled={uploadLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        title="Delete Confirmation"
      >
        <div className="flex flex-col items-center justify-center">
          <p>Are you sure you want to delete the document?</p>
          <div className="w-full flex gap-2 justify-end py-4">
            <Button
              onClick={() => setDeleteConfirmation(false)}
              className="gray-button"
            >
              No
            </Button>
            <Button
              onClick={() => handleDeleteDocument(selectedDocumentId)}
              className="black-button"
              loading={deleteloading}
              disabled={deleteloading}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Compliance;
