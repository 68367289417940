import React, { useState, useEffect, useMemo, useContext } from "react";
import axios from "utils/axios";
import { GoCheckCircle, GoClock } from "react-icons/go";
import { CiFilter } from "react-icons/ci";
import { FaXmark } from "react-icons/fa6";
import { HiChevronDown } from "react-icons/hi2";
import PageLoader from "components/ui/PageLoader";
import Button from "components/ui/Button";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import Select from "react-select";

const tabs = [
  {
    title: "Launching",
    tab: "Launching",
  },
  {
    title: "Live",
    tab: "Live",
  },
  {
    title: "Impact",
    tab: "Impact",
  },
  {
    title: "Community",
    tab: "Community",
  },
];

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  // State to track which cards have their dropdown open
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [dashboard, setDashboard] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");

  // Utility function to parse date
  const parseDate = (dateString) => {
    return dateString === "Pending" ? null : new Date(dateString);
  };

  const [selectedImpactBox, setSelectedImpactBox] = useState(null);

  const getDateRange = (range) => {
    const now = new Date();
    switch (range) {
      case "This week":
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - now.getDay());
        return startOfWeek;
      case "Last week":
        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      case "Last month":
        return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      case "This month":
        return new Date(now.getFullYear(), now.getMonth(), 1);
      case "3 months":
        return new Date(now.getFullYear(), now.getMonth() - 3, now.getDate());
      case "6 months":
        return new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      case "1 year":
        return new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      default:
        return null;
    }
  };

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  // Filters state
  // Separate filters for "Launching" and "Impact"
  const [launchingFilters, setLaunchingFilters] = useState({
    dateRange: "",
    steps: [],
    agentId: "",
    company: "",
  });

  const [liveFilters, setLiveFilters] = useState({
    dateRange: "",
    steps: [],
    agentId: "",
    company: "",
  });

  const [impactFilters, setImpactFilters] = useState({
    agentId: "",
    dateRange: "",
    company: "",
    customStartDate: null,
    customEndDate: null,
  });

  // Toggle dropdown for a specific card
  const toggleDropdown = (id) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const formatDate = (dateString) => {
    if (dateString === "Pending") {
      return dateString;
    }
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(new Date(dateString));
  };

  // Update reset filters to include new custom date fields
  const resetFilters = (filterType) => {
    if (filterType === "Launching") {
      setLaunchingFilters({
        dateRange: "",
        steps: [],
        agentId: "",
        company: "",
      });
    } else if (filterType === "Impact") {
      setImpactFilters({
        agentId: "",
        dateRange: "",
        company: "",
        customStartDate: null,
        customEndDate: null,
      });
    } else if (filterType === "Impact") {
      setLiveFilters({
        dateRange: "",
        steps: [],
        agentId: "",
        company: "",
      });
    }
  };

  const filteredLaunchingData = useMemo(() => {
    return (dashboard?.launching || []).filter((item) => {
      if (!item.history) return false;

      const { agentId, dateRange, company } = launchingFilters;

      // Filter by agent ID
      if (agentId && item.userId !== agentId) return false;

      // Filter by company
      if (company && item.company !== company) return false;

      // Filter by date range
      const dateRangeStart = dateRange ? getDateRange(dateRange) : null;
      const launchedDate = item.history.find(
        (h) => h.name === "Launch to Market"
      )?.date;

      console.log(launchedDate);
      // Ensure launchedDate exists and is within the range
      if (dateRangeStart && (!launchedDate || launchedDate < dateRangeStart)) {
        return false;
      }

      return true;
    });
  }, [launchingFilters, dashboard]);

  const filteredLiveData = useMemo(() => {
    return (dashboard?.live || []).filter((item) => {
      if (!item.history) return false;

      const { agentId, company } = liveFilters;

      // Filter by agent ID
      if (agentId && item.userId !== agentId) return false;

      // Filter by company
      if (company && item.company !== company) return false;

      return true;
    });
  }, [liveFilters, dashboard]);

  const filteredImpactData = useMemo(() => {
    const { agentId, dateRange, customStartDate, customEndDate, company } =
      impactFilters;
    const impact = dashboard?.impact || {};

    let filtered = {
      bookAppraisal: impact.bookAppraisal || [],
      appraised: impact.appraised || [],
      listed: impact.listed || [],
    };

    // Filter by agent ID
    if (agentId) {
      filtered = {
        bookAppraisal: filtered.bookAppraisal.filter(
          (entry) => entry.userId === agentId
        ),
        appraised: filtered.appraised.filter(
          (entry) => entry.userId === agentId
        ),
        listed: filtered.listed.filter((entry) => entry.userId === agentId),
      };
    }

    if (company) {
      filtered = {
        bookAppraisal: filtered.bookAppraisal.filter(
          (entry) => entry.company === company
        ),
        appraised: filtered.appraised.filter(
          (entry) => entry.company === company
        ),
        listed: filtered.listed.filter((entry) => entry.company === company),
      };
    }

    // Date Range Filtering
    const filterByDate = (entries) => {
      return entries.filter((entry) => {
        const entryDate = new Date(entry.startTime || entry.completedAt);

        // Predefined date ranges
        if (dateRange && dateRange !== "Custom") {
          const dateRangeStart = getDateRange(dateRange);
          return dateRangeStart ? entryDate >= dateRangeStart : true;
        }

        // Custom date range
        if (customStartDate || customEndDate) {
          const start = customStartDate ? new Date(customStartDate) : null;
          const end = customEndDate ? new Date(customEndDate) : new Date();

          // Extend end date to include the entire day
          end.setHours(23, 59, 59, 999);

          if (start && end) {
            return entryDate >= start && entryDate <= end;
          }
          if (start) {
            return entryDate >= start;
          }
          if (end) {
            return entryDate <= end;
          }
        }

        return true;
      });
    };

    // Apply date filtering
    filtered = {
      bookAppraisal: filterByDate(filtered.bookAppraisal),
      appraised: filterByDate(filtered.appraised),
      listed: filterByDate(filtered.listed),
    };

    return filtered;
  }, [impactFilters, dashboard]);

  const userOptions =
    dashboard?.users?.map((user) => ({
      value: user._id,
      label: user.name,
    })) || [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/dashboard`);
        if (response.data.success) {
          setDashboard(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="container mx-auto p-0">
      <div className="relative mb-8">
        <div className="fixed top-[80px] left-0 right-0 z-10 bg-white px-6 py-1 w-full">
          <div className="max-w-md mx-auto grid grid-cols-4 gap-2">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`cursor-pointer flex flex-col items-center justify-center px-2 py-2.5 rounded font-semibold ${
                  activeTab === tab.tab ? "bg-black text-white" : "bg-lightgray"
                }`}
                onClick={() => {
                  setActiveTab(tab.tab);
                }}
              >
                <span className="text-xs">{tab.title}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {activeTab && (
        <div className="mb-2 flex justify-end">
          <Button
            onClick={() => setIsFilterPanelOpen(!isFilterPanelOpen)}
            className="flex items-center gray-button"
          >
            <CiFilter className="mr-2" size={20} />
            Filters
          </Button>
        </div>
      )}

      {/* Filter Panel */}
      {isFilterPanelOpen && (
        <div className="bg-white shadow rounded p-4 mb-8">
          <div className="flex justify-between items-center mb-4">
            <h5>{activeTab} Filters</h5>
            <i
              onClick={() => setIsFilterPanelOpen(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>

          {activeTab === "Launching" && (
            <>
              <Select
                options={userOptions}
                value={userOptions.find(
                  (option) => option.value === launchingFilters.agentId
                )}
                onChange={(selectedOption) =>
                  setLaunchingFilters((prev) => ({
                    ...prev,
                    agentId: selectedOption?.value || "",
                  }))
                }
                isClearable
                placeholder="Select Agent"
                classNamePrefix="react-select"
                className=" w-full mb-4"
              />

              {user.role === "admin" && (
                <select
                  className="w-full form-select border border-mediumgray mb-4"
                  value={launchingFilters.company}
                  onChange={(e) =>
                    setLaunchingFilters((prev) => ({
                      ...prev,
                      company: e.target.value,
                    }))
                  }
                >
                  {[
                    "Select Company",
                    "Ausrealty (Riverwood) Pty Ltd (Licenced user of Ausrealty)",
                    "KK Property Services Pty Ltd (Licenced user of Ausrealty)",
                    "I.M Group Pty Ltd (Licenced user of Ausrealty)",
                    "MRL Property Group Pty Ltd (Licenced user of Ausrealty)",
                    "Hani Property Services Pty Ltd (Licenced user of Ausrealty)",
                    "Suti Investments Pty Ltd (Licenced user of Ausrealty)",
                    "Anodos Enterprises Pty Ltd (Licenced user of Ausrealty)",
                    "I Sayed Investments Pty Ltd (Licenced user of Ausrealty)",
                  ].map((name, index) => (
                    <option
                      key={index}
                      value={name === "Select Company" ? "" : name}
                    >
                      {name}
                    </option>
                  ))}
                </select>
              )}

              <div className="mb-4">
                <p>Date Range</p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {["This week", "This month"].map((range) => (
                    <Button
                      key={range}
                      onClick={() =>
                        setLaunchingFilters((prev) => ({
                          ...prev,
                          dateRange: range,
                        }))
                      }
                      className={`p-2 rounded text-xs ${
                        launchingFilters.dateRange === range
                          ? "bg-black text-white"
                          : "bg-lightgray text-black"
                      }`}
                    >
                      {range}
                    </Button>
                  ))}
                </div>
              </div>
            </>
          )}

          {activeTab === "Live" && (
            <>
              <Select
                options={userOptions}
                value={userOptions.find(
                  (option) => option.value === liveFilters.agentId
                )}
                onChange={(selectedOption) =>
                  setLiveFilters((prev) => ({
                    ...prev,
                    agentId: selectedOption?.value || "",
                  }))
                }
                isClearable
                placeholder="Select Agent"
                classNamePrefix="react-select"
                className=" w-full mb-4"
              />

              {user.role === "admin" && (
                <select
                  className="w-full form-select border border-mediumgray mb-4"
                  value={liveFilters.company}
                  onChange={(e) =>
                    setLiveFilters((prev) => ({
                      ...prev,
                      company: e.target.value,
                    }))
                  }
                >
                  {[
                    "Select Company",
                    "Ausrealty (Riverwood) Pty Ltd (Licenced user of Ausrealty)",
                    "KK Property Services Pty Ltd (Licenced user of Ausrealty)",
                    "I.M Group Pty Ltd (Licenced user of Ausrealty)",
                    "MRL Property Group Pty Ltd (Licenced user of Ausrealty)",
                    "Hani Property Services Pty Ltd (Licenced user of Ausrealty)",
                    "Suti Investments Pty Ltd (Licenced user of Ausrealty)",
                    "Anodos Enterprises Pty Ltd (Licenced user of Ausrealty)",
                    "I Sayed Investments Pty Ltd (Licenced user of Ausrealty)",
                  ].map((name, index) => (
                    <option
                      key={index}
                      value={name === "Select Company" ? "" : name}
                    >
                      {name}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}

          {activeTab === "Impact" && (
            <>
              <Select
                options={userOptions}
                value={userOptions.find(
                  (option) => option.value === impactFilters.agentId
                )}
                onChange={(selectedOption) =>
                  setImpactFilters((prev) => ({
                    ...prev,
                    agentId: selectedOption?.value || "",
                  }))
                }
                isClearable
                placeholder="Select Agent"
                classNamePrefix="react-select"
                className=" w-full mb-4"
              />

              <div className="mb-4">
                <p>Date Range</p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {[
                    "Last week",
                    "Last month",
                    "3 months",
                    "6 months",
                    "1 year",
                    "Custom",
                  ].map((range) => (
                    <Button
                      key={range}
                      onClick={() =>
                        setImpactFilters((prev) => ({
                          ...prev,
                          dateRange: range,
                        }))
                      }
                      className={`p-2 rounded text-xs ${
                        impactFilters.dateRange === range
                          ? "bg-black text-white"
                          : "bg-lightgray text-black"
                      }`}
                    >
                      {range}
                    </Button>
                  ))}
                </div>

                {impactFilters.dateRange === "Custom" && (
                  <div className="mt-4 flex space-x-4">
                    <div className="flex-1">
                      <label className="block text-sm mb-1">Start Date</label>
                      <input
                        type="date"
                        className="w-full border border-mediumgray rounded p-2"
                        value={impactFilters.customStartDate || ""}
                        onChange={(e) =>
                          setImpactFilters((prev) => ({
                            ...prev,
                            customStartDate: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="flex-1">
                      <label className="block text-sm mb-1">End Date</label>
                      <input
                        type="date"
                        className="w-full border border-mediumgray rounded p-2"
                        value={impactFilters.customEndDate || ""}
                        onChange={(e) =>
                          setImpactFilters((prev) => ({
                            ...prev,
                            customEndDate: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              {user.role === "admin" && (
                <select
                  className="w-full form-select border border-mediumgray mb-4"
                  value={impactFilters.company}
                  onChange={(e) =>
                    setImpactFilters((prev) => ({
                      ...prev,
                      company: e.target.value,
                    }))
                  }
                >
                  {[
                    "Select Company",
                    "Ausrealty (Riverwood) Pty Ltd (Licenced user of Ausrealty)",
                    "KK Property Services Pty Ltd (Licenced user of Ausrealty)",
                    "I.M Group Pty Ltd (Licenced user of Ausrealty)",
                    "MRL Property Group Pty Ltd (Licenced user of Ausrealty)",
                    "Hani Property Services Pty Ltd (Licenced user of Ausrealty)",
                    "Suti Investments Pty Ltd (Licenced user of Ausrealty)",
                    "Anodos Enterprises Pty Ltd (Licenced user of Ausrealty)",
                    "I Sayed Investments Pty Ltd (Licenced user of Ausrealty)",
                  ].map((name, index) => (
                    <option
                      key={index}
                      value={name === "Select Company" ? "" : name}
                    >
                      {name}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}

          <div className="flex justify-end">
            <Button
              onClick={() => resetFilters(activeTab)}
              className="gray-button"
            >
              Reset Filters
            </Button>
          </div>
        </div>
      )}

      {activeTab === "Launching" && (
        <div className="grid grid-cols-1 gap-4 mb-48">
          {filteredLaunchingData?.length > 0 &&
            filteredLaunchingData?.map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg border px-4 py-2.5 transition-all duration-300 cursor-pointer"
                onClick={() => toggleDropdown(index)}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <Link
                      to={`/chat/${encodeURIComponent(
                        item.address
                      )}?tab=book-appraisal`}
                    >
                      <h5 className="underline">{item.address}</h5>
                    </Link>

                    <div className="flex items-center mt-2 text-xs">
                      <span className={`text-xs font-medium text-darkergray`}>
                        {item.status}
                      </span>
                    </div>
                  </div>
                  <HiChevronDown
                    className={`text-black transform transition-transform ${
                      openDropdowns[index] ? "rotate-180" : ""
                    }`}
                    size={16}
                  />
                </div>

                {openDropdowns[index] && (
                  <div className="mt-4 border-t pt-4">
                    <p className="text-md font-semibold mb-3 text-darkergray">
                      Property Listing Journey
                    </p>
                    <div className="space-y-2">
                      {item.history.map((step, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-3"
                        >
                          {step.completed ? (
                            <GoCheckCircle className="text-black" size={16} />
                          ) : (
                            <GoClock className="text-darkgray" size={16} />
                          )}
                          <span
                            className={`flex-grow ${
                              step.completed ? "text-black" : "text-darkgray"
                            }`}
                          >
                            {step.name}
                          </span>
                          <span
                            className={`text-sm ${
                              step.completed ? "text-black" : "text-darkgray"
                            }`}
                          >
                            {step.date ? formatDate(step.date) : "Pending"}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}

      {activeTab === "Live" && (
        <div className="grid grid-cols-1 gap-4 mb-48">
          {filteredLiveData?.length > 0 &&
            filteredLiveData?.map((item, index) => {
              // Find the "Launch to Market" step in the item's history
              const launchToMarketStep = item.history.find(
                (step) => step.name === "Launch to Market"
              );
              let daysDifference = null;
              if (launchToMarketStep?.date) {
                const launchDate = new Date(launchToMarketStep.date);
                const today = new Date();
                daysDifference = Math.ceil(
                  (today - launchDate) / (1000 * 60 * 60 * 24)
                );
              }

              return (
                <div
                  key={index}
                  className="bg-white rounded-lg border px-4 py-2.5 transition-all duration-300 cursor-pointer"
                  onClick={() => toggleDropdown(index)}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <Link
                        to={`/chat/${encodeURIComponent(
                          item.address
                        )}?tab=book-appraisal`}
                        className="flex gap-4"
                      >
                        <h5 className="underline">{item.address}</h5>
                        {launchToMarketStep && (
                          <div>
                            <span
                              className={`px-2 py-0.5 text-xs font-semibold rounded-full ${
                                daysDifference > 31
                                  ? "bg-red-50 text-red-500"
                                  : "bg-green-50 text-green-500"
                              }`}
                            >
                             {daysDifference} days ago
                            </span>
                          </div>
                        )}
                      </Link>

                      <div className="flex items-center mt-2 text-xs">
                        <span className={`text-xs font-medium text-darkergray`}>
                          {item.status}
                        </span>
                      </div>
                    </div>
                    <HiChevronDown
                      className={`text-black transform transition-transform ${
                        openDropdowns[index] ? "rotate-180" : ""
                      }`}
                      size={16}
                    />
                  </div>

                  {openDropdowns[index] && (
                    <div className="mt-4 border-t pt-4">
                      <p className="text-md font-semibold mb-3 text-darkergray">
                        Property Listing Journey
                      </p>
                      <div className="space-y-2">
                        {item.history.map((step, index) => (
                          <div
                            key={index}
                            className="flex items-center space-x-3"
                          >
                            {step.completed ? (
                              <GoCheckCircle className="text-black" size={16} />
                            ) : (
                              <GoClock className="text-darkgray" size={16} />
                            )}
                            <span
                              className={`flex-grow ${
                                step.completed ? "text-black" : "text-darkgray"
                              }`}
                            >
                              {step.name}
                            </span>
                            <span
                              className={`text-sm ${
                                step.completed ? "text-black" : "text-darkgray"
                              }`}
                            >
                              {step.date ? formatDate(step.date) : "Pending"}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      )}

      {activeTab === "Impact" && (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-40">
          {Object.entries(filteredImpactData).map(([key, value]) => (
            <div
              key={key}
              className="bg-white rounded-lg border p-4 cursor-pointer"
              onClick={() => setSelectedImpactBox(key)} // Add click handler
            >
              <h5>{key.replace(/([a-z])([A-Z])/g, "$1 $2")}</h5>{" "}
              {/* Add spaces between camelCase */}
              {impactFilters.agentId ? (
                value?.length
              ) : (
                <h4>{value?.length || 0}</h4>
              )}
              {selectedImpactBox === key && (
                <div className="mt-4 max-h-[150px] overflow-y-auto">
                  {value?.map((item, index) => (
                    <Link
                      to={`/chat/${encodeURIComponent(
                        item.address
                      )}?tab=book-appraisal`}
                    >
                      <p
                        key={index}
                        className="text-sm border-b border-lightgray py-2"
                      >
                        {item.address}
                      </p>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {activeTab === "Community" && (
        <div className="flex justify-center items-center w-full mx-auto">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26473.263567857735!2d151.04448629206544!3d-33.96277962268254!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b92f4afb3cb9%3A0x5017d681632c610!2sPeakhurst%20NSW%202210%2C%20Australia!5e0!3m2!1sen!2s!4v1733914700715!5m2!1sen!2s"
            width="400"
            height="400"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="border border-lightgray rounded-lg"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
