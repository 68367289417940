import React, { useEffect } from 'react';
import ReactQuill from 'react-quill'; // Import Quill
import 'react-quill/dist/quill.snow.css'; // Import Quill's default CSS theme

export default function MarkdownEditor({ value, setValue }) {
  
  const handleChange = (value) => {
    setValue(value); 
  };

  return (
    <div>
      <ReactQuill 
        theme="snow" 
        value={value} 
        onChange={handleChange} 
        placeholder="Write something amazing..."
        modules={{
          toolbar: [
            // [{ 'header': '1'}, { 'header': '2' }],  // Headings
            [{ 'header': [1, 2, 3, 4, false] }],  // Add H3 and H4
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],  // Formatting
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],  // Lists
            [{ 'indent': '-1' }, { 'indent': '+1' }],  // Indentation
            ['link'],  // Links
            ['clean'],  // Remove formatting
          ],
        }}
      />
    </div>
  );
}
