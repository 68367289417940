import GooglePlacesAutocomplete from "components/GooglePlacesAutoComplete";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { showToast } from "components/ui/toast";
import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "utils/axios";

const RecentAreaSoldProcess = ({
  recentAreaSoldProcess,
  propertyType,
  bedrooms,
  sourceAddress
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [filterMonths, setFilterMonths] = useState(36); // Default is 18 months
  const [suggestions, setSuggestions] = useState([]); // Store all suggestions from the API
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); // Store filtered suggestions based on user input
  const [searchTerm, setSearchTerm] = useState(""); // Search input value
  const [showSuggestions, setShowSuggestions] = useState(false); // To toggle the visibility of the dropdown
  const [filteredRecentAreaSoldProcess, setFilteredRecentAreaSoldProcess] =
    useState(recentAreaSoldProcess); // Store filtered data

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index); // Toggle row
  };

  // Fetch all suggestions once when the component mounts
  useEffect(() => {
    axios
      .get("/property/suburbName")
      .then((response) => {
        const suburbData = response.data.data; // Assuming the response has a `data` field containing suburbs
        setSuggestions(suburbData); // Store all suggestions
      })
      .catch((error) => {
        console.error("Error fetching suggestions:", error);
      });
  }, []);

  // Handle search term change and filter suggestions locally
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    // Show the dropdown only when the user starts typing
    if (searchValue.length > 0) {
      setShowSuggestions(true);

      // Filter suggestions based on the search term
      const filtered = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSuggestions(filtered); // Update filtered suggestions based on input
    } else {
      setShowSuggestions(false); // Hide the dropdown if the input is empty
    }
  };
  const [loading, setLoading] = useState(false);

  const handleSuggestionClick = async (suggestion) => {
    try {
      setLoading(true);
      setShowSuggestions(false);
      const response = await axios.post("/property/recentAreaSoldProcess", {
        suggestion,
        propertyType,
        bedrooms,
      });

      setFilteredRecentAreaSoldProcess(response.data.data);
      setSearchTerm(suggestion);

      setFilterMonths(12);
    } catch (error) {
      console.error("Error fetching recent area sold process:", error);
    } finally {
      setLoading(false);
    }
  };

  const calculateMonthsAgo = (months) => {
    const date = new Date();
    date.setMonth(date.getMonth() - months);
    return date;
  };

  const twelveMonthsAgo = calculateMonthsAgo(filterMonths); // Based on selected months
  const parseDate = (dateString) => new Date(dateString);

  // useEffect(
  //   () =>
  //     setFilteredRecentAreaSoldProcess(
  //       filteredRecentAreaSoldProcess.filter((property) => {
  //         const saleDateValue =
  //           property?.saleHistory?.sales?.[0]?.saleDate?.value;
  //         if (saleDateValue) {
  //           const saleDate = parseDate(saleDateValue); // Convert string to Date object
  //           return saleDate >= twelveMonthsAgo; // Perform the comparison
  //         }
  //         return false; // Exclude properties without a valid saleDate.value
  //       })
  //     ),
  //   [filterMonths]
  // );

  const [selectedAddress, setSelectedAddress] = useState("");
  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
    handleAdd(address);
  };

  const handleAdd = async (address) => {
    try {
      setLoading(true);
      setShowSuggestions(false);
      const response = await axios.post(
        "/property/recentAreaSoldProcess/single",
        {
          sourceAddress,
          address,
        }
      );
      if (response.data.success) {
        const newProperty = response.data.data; // Assuming `data` contains the new property details
        setFilteredRecentAreaSoldProcess((prevState) => [
          ...prevState,
          newProperty,
        ]); // Append the new property to the existing array
        showToast("success", "Property added successfully");
      } else {
        showToast("error", "Property not exists on PriceFinder");
      }
    } catch (error) {
      console.error("Error fetching recent area sold process:", error);
      showToast("error", "Property not exists on PriceFinder");
    } finally {
      setLoading(false);
      setSelectedAddress("")
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    // Check if the 'tab' parameter is set to 'authorise-and-schedule'
    if (searchParams.get("tab") === "price-and-process") {
      const styleSheets = document.styleSheets;
      for (let i = 0; i < styleSheets.length; i++) {
        try {
          const rules = styleSheets[i].cssRules;
          for (let j = 0; j < rules.length; j++) {
            // Find the rule that applies to .pac-container.pac-logo and remove it
            if (rules[j].selectorText === ".pac-container.pac-logo") {
              styleSheets[i].deleteRule(j);
              break;
            }
          }
        } catch (e) {
          // Ignore cross-origin or inaccessible stylesheets
          if (e.name !== "SecurityError") {
            console.error(e);
          }
        }
      }
    }
  }, []);

  return (
    <div className="w-full space-y-4 text-center">
      <div>
        <h4 className="text-center">RECENT AREA SOLD PROCESS</h4>
        <div className="relative py-4">
          <input
            className="search-input bg-lightgray rounded-md w-full py-2 pr-3 pl-8"
            type="text"
            placeholder="Search by Suburb..."
            value={searchTerm}
            onChange={handleSearchChange} // Update search term and filter suggestions
          />
          <div className="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              className="text-sm text-darkergray"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64z"
              ></path>
              <path
                fill="none"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M338.29 338.29 448 448"
              ></path>
            </svg>
          </div>
          {/* Display filtered suggestions below input */}
        </div>
        {showSuggestions && filteredSuggestions.length > 0 && (
          <ul className="form-input border border-mediumgray bg-white w-full p-0 py-1 m-0 mt-2 list-none max-h-[120px] overflow-y-auto text-start">
            {filteredSuggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)} // Pass full user object
                className="px-2 py-1 cursor-pointer hover:bg-lightgray m-0"
              >
                {suggestion} {/* Display user name */}
              </li>
            ))}
          </ul>
        )}
        <GooglePlacesAutocomplete
          onSelectAddress={handleAddressSelect}
          isNSWCheck={false}
          address={selectedAddress}
          setAddress={setSelectedAddress}
          placeholder="Add a property..."
        />
        {loading && (
          <p>
            <i className="fa-solid fa-spinner animate-spin mr-2"></i> Loading
            ...
          </p>
        )}
        {/* <div className="mt-2 flex items-center justify-end">
          <p className="mr-2">Show sales from the last:</p>
          <select
            value={filterMonths}
            onChange={(e) => setFilterMonths(Number(e.target.value))}
            className="form-select border border-mediumgray w-auto min-w-[100px] py-1 px-2 min-h-[20px]"
          >
            <option value={6}>6 months</option>
            <option value={12}>12 months</option>
            <option value={18}>18 months</option>
          </select>
        </div> */}
      </div>

      {filteredRecentAreaSoldProcess &&
        filteredRecentAreaSoldProcess.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-center border-collapse border  mx-auto">
                <thead>
                  <tr className="border-b ">
                    <th className="hidden sm:table-cell py-2 px-3 border-r "></th>
                    <th className="py-2 px-3 border-r  min-w-[120px]">
                      Address
                    </th>
                    <th className="hidden sm:table-cell py-2 px-3 border-r ">
                      Agency
                    </th>
                    {/* <th className="py-2 px-3 border-r  min-w-[100px]">
                        Process
                      </th> */}
                    <th className="py-2 px-3 border-r  min-w-[75px]">
                      Sale Price
                    </th>
                    <th className="py-2 px-3">Days on Market</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecentAreaSoldProcess.map((property, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`border-b  cursor-pointer hover:bg-lightgray ${
                          expandedRow === index ? "bg-mediumgray" : ""
                        }`}
                        onClick={() => toggleRow(index)}
                      >
                        <td className="hidden sm:table-cell py-2 px-3 border-r ">
                          {index + 1}
                        </td>
                        <td className="py-2 px-3 border-r ">
                          {property.listingHistory?.listings?.[0]?.address
                            ?.streetAddress || "N/A"}
                        </td>
                        <td className="hidden sm:table-cell py-2 px-3 border-r ">
                          {property.listingHistory?.listings?.[0]?.agencies?.[0]
                            ?.name || "N/A"}
                        </td>
                        {/* <td className="py-2 px-3 border-r ">
                            {property.beleefSaleProcess || "N/A"}
                          </td> */}
                        <td className="py-2 px-3 border-r  ">
                          {property?.saleHistory?.sales?.[0]?.price?.value
                            ? `$${property.saleHistory?.sales?.[0]?.price.value.toLocaleString()}`
                            : "N/A"}
                        </td>
                        <td className="py-2 px-3 w-24">
                          {property?.saleHistory?.sales?.[0]?.listingHistory
                            ?.daysToSell + 1 || "N/A"}
                        </td>
                      </tr>

                      {expandedRow === index && (
                        <tr className="border-b ">
                          <td colSpan="6" className="border-t ">
                            <div className="p-4">
                              <table className="bg-lightergray min-w-full table-auto border-collapse border">
                                <thead>
                                  <tr>
                                    <th className="px-3 py-2 border">Date</th>
                                    <th className="px-3 py-2 border">
                                      Listing Price
                                    </th>
                                    <th className="px-3 py-2 border">
                                      Agency Name
                                    </th>
                                    <th className="px-3 py-2 border">
                                      Agent Name
                                    </th>
                                    <th className="px-3 py-2 border">DOM</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {property?.listingHistory?.listings.map(
                                    (listing, index) => (
                                      <tr
                                        key={index}
                                        className="hover:bg-darkgray"
                                      >
                                        <td className="px-3 py-2 border">
                                          {listing.startDate?.display || "N/A"}
                                        </td>
                                        <td className="px-3 py-2 border">
                                          {listing.price?.display || "N/A"}
                                        </td>

                                        <td className="px-3 py-2 border">
                                          {listing.agencies?.[0]?.name || "N/A"}
                                        </td>
                                        <td className="px-3 py-2 border">
                                          {listing.agents?.[0]?.name || "N/A"}
                                        </td>
                                        <td className="px-3 py-2 border">
                                          {listing.listingHistory
                                            ?.daysOnMarket || "N/A"}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="text-start text-xs">
              *Last {filterMonths} months sales
            </p>
          </>
        )}
    </div>
  );
};

export default RecentAreaSoldProcess;
