import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "utils/axios";
import Error from "components/ui/Error";
import {
  Autocomplete,
  GoogleMap,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";
import Button from "components/ui/Button";
import { showToast } from "components/ui/toast";
import { IoSend } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { MdOutlineEdit } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi2";
import PageLoader from "components/ui/PageLoader";
import GoogleMaps from "components/GoogleMaps";
import GoogleChart from "components/GoogleChart";
import video from "assets/videos/postlist-video.mp4";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "components/ui/Tooltip";
import arrow from "assets/images/arrow.png";
import GooglePieChart from "components/GooglePieChart";
import Modal from "components/ui/Modal";
import ausrealtyLogo from "assets/images/ausrealy-logo.svg";
import { PropertyDetailsModal } from "components/ui/PropertyDetailsModal";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  adaptiveHeight: true,
  arrows: true,
  // centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
};

const RecentAreaSoldProcess = ({ recentAreaSoldProcess }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [filterMonths, setFilterMonths] = useState(12); // Default is 12 months
  const [suggestions, setSuggestions] = useState([]); // Store all suggestions from the API
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); // Store filtered suggestions based on user input
  const [searchTerm, setSearchTerm] = useState(""); // Search input value
  const [showSuggestions, setShowSuggestions] = useState(false); // To toggle the visibility of the dropdown
  const [filteredRecentAreaSoldProcess, setFilteredRecentAreaSoldProcess] =
    useState(recentAreaSoldProcess); // Store filtered data

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index); // Toggle row
  };

  // Fetch all suggestions once when the component mounts
  useEffect(() => {
    axios
      .get("/property/suburbName")
      .then((response) => {
        const suburbData = response.data.data; // Assuming the response has a `data` field containing suburbs
        setSuggestions(suburbData); // Store all suggestions
      })
      .catch((error) => {
        console.error("Error fetching suggestions:", error);
      });
  }, []);

  // Handle search term change and filter suggestions locally
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    // Show the dropdown only when the user starts typing
    if (searchValue.length > 0) {
      setShowSuggestions(true);

      // Filter suggestions based on the search term
      const filtered = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredSuggestions(filtered); // Update filtered suggestions based on input
    } else {
      setShowSuggestions(false); // Hide the dropdown if the input is empty
    }
  };
  const [loading, setLoading] = useState(false);
  // Handle suggestion click and call API to replace recentAreaSoldProcess
  const handleSuggestionClick = async (suggestion) => {
    try {
      setLoading(true); // Set loading to true before making the API call
      const response = await axios.post(
        `/property/recentAreaSoldProcess/${suggestion}/House`
      );

      setFilteredRecentAreaSoldProcess(response.data.data); // Replace recentAreaSoldProcess data with new data
      setSearchTerm(suggestion); // Set the selected suggestion as the input value
      setShowSuggestions(false); // Hide the dropdown after a suggestion is selected
      setFilterMonths(12);
    } catch (error) {
      console.error("Error fetching recent area sold process:", error);
    } finally {
      setLoading(false); // Set loading to false once the API call is completed
    }
  };

  const calculateMonthsAgo = (months) => {
    const date = new Date();
    date.setMonth(date.getMonth() - months);
    return date;
  };

  const twelveMonthsAgo = calculateMonthsAgo(filterMonths); // Based on selected months
  const parseDate = (dateString) => new Date(dateString);

  useEffect(
    () =>
      setFilteredRecentAreaSoldProcess(
        filteredRecentAreaSoldProcess.filter((property) => {
          const saleDateValue =
            property?.saleHistory?.sales?.[0]?.saleDate?.value;
          if (saleDateValue) {
            const saleDate = parseDate(saleDateValue); // Convert string to Date object
            return saleDate >= twelveMonthsAgo; // Perform the comparison
          }
          return false; // Exclude properties without a valid saleDate.value
        })
      ),
    [filterMonths]
  );

  return (
    <>
      {filteredRecentAreaSoldProcess &&
        filteredRecentAreaSoldProcess.length > 0 && (
          <div className="w-full space-y-4 text-center">
            <div>
              <h4 className="text-center">RECENT AREA SOLD PROCESS</h4>
              <div className="relative py-4">
                <input
                  className="search-input bg-lightgray rounded-md w-full py-2 pr-3 pl-8"
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange} // Update search term and filter suggestions
                />
                <div className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    className="text-sm text-darkergray"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="none"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64z"
                    ></path>
                    <path
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M338.29 338.29 448 448"
                    ></path>
                  </svg>
                </div>
                {/* Display filtered suggestions below input */}
              </div>
              {showSuggestions && filteredSuggestions.length > 0 && (
                <ul className="form-input border border-mediumgray bg-white w-full p-0 py-1 m-0 mt-2 list-none max-h-[120px] overflow-y-auto text-start">
                  {filteredSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)} // Pass full user object
                      className="px-2 py-1 cursor-pointer hover:bg-lightgray m-0"
                    >
                      {suggestion} {/* Display user name */}
                    </li>
                  ))}
                </ul>
              )}
              {loading && (
                <p>
                  <i className="fa-solid fa-spinner animate-spin mr-2"></i>{" "}
                  Loading ...
                </p>
              )}
              <div className="mt-2 flex items-center justify-end">
                {/* Select box for choosing months */}
                <p className="mr-2">Show sales from the last:</p>
                <select
                  value={filterMonths}
                  onChange={(e) => setFilterMonths(Number(e.target.value))}
                  className="form-select border border-mediumgray w-auto min-w-[100px] py-1 px-2 min-h-[20px]"
                >
                  <option value={6}>6 months</option>
                  <option value={12}>12 months</option>
                </select>
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full text-sm text-center border-collapse border  mx-auto">
                <thead>
                  <tr className="border-b ">
                    <th className="hidden sm:table-cell py-2 px-3 border-r "></th>
                    <th className="py-2 px-3 border-r  min-w-[120px]">
                      Address
                    </th>
                    <th className="hidden sm:table-cell py-2 px-3 border-r ">
                      Agency
                    </th>
                    {/* <th className="py-2 px-3 border-r  min-w-[100px]">
                      Process
                    </th> */}
                    <th className="py-2 px-3 border-r  min-w-[75px]">
                      Sale Price
                    </th>
                    <th className="py-2 px-3">Days on Market</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecentAreaSoldProcess.map((property, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`border-b  cursor-pointer hover:bg-lightgray ${
                          expandedRow === index ? "bg-mediumgray" : ""
                        }`}
                        onClick={() => toggleRow(index)}
                      >
                        <td className="hidden sm:table-cell py-2 px-3 border-r ">
                          {index + 1}
                        </td>
                        <td className="py-2 px-3 border-r ">
                          {property.listingHistory?.listings?.[0]?.address
                            ?.streetAddress || "N/A"}
                        </td>
                        <td className="hidden sm:table-cell py-2 px-3 border-r ">
                          {property.listingHistory?.listings?.[0]?.agencies?.[0]
                            ?.name || "N/A"}
                        </td>
                        {/* <td className="py-2 px-3 border-r ">
                          {property.beleefSaleProcess || "N/A"}
                        </td> */}
                        <td className="py-2 px-3 border-r  ">
                          {property?.saleHistory?.sales?.[0]?.price?.value
                            ? `$${property.saleHistory?.sales?.[0]?.price.value.toLocaleString()}`
                            : "N/A"}
                        </td>
                        <td className="py-2 px-3 w-24">
                          {property?.saleHistory?.sales?.[0]?.listingHistory
                            ?.daysToSell + 1 || "N/A"}
                        </td>
                      </tr>

                      {expandedRow === index && (
                        <tr className="border-b ">
                          <td colSpan="6" className="border-t ">
                            <div className="p-4">
                              <table className="bg-lightergray min-w-full table-auto border-collapse border">
                                <thead>
                                  <tr>
                                    <th className="px-3 py-2 border">Date</th>
                                    <th className="px-3 py-2 border">
                                      Listing Price
                                    </th>
                                    <th className="px-3 py-2 border">
                                      Agency Name
                                    </th>
                                    <th className="px-3 py-2 border">
                                      Agent Name
                                    </th>
                                    <th className="px-3 py-2 border">DOM</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {property?.listingHistory?.listings.map(
                                    (listing, index) => (
                                      <tr
                                        key={index}
                                        className="hover:bg-darkgray"
                                      >
                                        <td className="px-3 py-2 border">
                                          {listing.startDate?.display || "N/A"}
                                        </td>
                                        <td className="px-3 py-2 border">
                                          {listing.price?.display || "N/A"}
                                        </td>

                                        <td className="px-3 py-2 border">
                                          {listing.agencies?.[0]?.name || "N/A"}
                                        </td>
                                        <td className="px-3 py-2 border">
                                          {listing.agents?.[0]?.name || "N/A"}
                                        </td>
                                        <td className="px-3 py-2 border">
                                          {listing.listingHistory
                                            ?.daysOnMarket || "N/A"}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="text-start text-xs">
              *Last {filterMonths} months sales
            </p>
          </div>
        )}
    </>
  );
};

const QuickSearchShare = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading as true
  const [error, setError] = useState(false);

  const [isPropertyClicked, setIsPropertyClicked] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  // Extract the UUID from the URL
  const getUUIDFromURL = () => {
    const url = window.location.pathname;
    if (url.includes("/quick-search/share/")) {
      const uuid = url.split("/quick-search/share/")[1];
      return uuid ? uuid.split(/[?#]/)[0] : null;
    }
    return null;
  };

  const fetchPropertyByUUID = async (uuid) => {
    try {
      const response = await axios.get(`/share/quickShare/${uuid}`);
      setData(response.data.data);
    } catch (err) {
      console.error("Error fetching quick search data:", err);
      setError(true);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    const uuid = getUUIDFromURL();
    if (uuid) {
      fetchPropertyByUUID(uuid); // Fetch data by UUID
    }
  }, []);

  const formatCurrency = (value) =>
    value ? `$${new Intl.NumberFormat().format(value)}` : "N/A";

  const convertDate = (dateString) =>
    new Date(dateString).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <Error />;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  function getAverageValueInUnits(range) {
    // Remove dollar signs, commas, and split the range into two numbers
    const [low, high] = range
      .replace(/\$|,/g, "") // Remove dollar signs and commas
      .split("-") // Split by the hyphen into low and high values
      .map((str) => parseFloat(str.trim())); // Trim spaces and parse as numbers

    if (isNaN(low) || isNaN(high)) {
      return 0;
    }

    // Calculate the average
    const average = (low + high) / 2;

    // Return the average in units format
    return average;
  }

  const {
    property,
    saleProperties,
    soldProperties,
    areaDynamics,
    pieChartData,
    agent,
  } = data || {};
  const { logicalPrice, logicalReasoning, recentAreaSoldProcess } =
    property || {};
  const { name, email, picture } = agent || {};

  // Create dataPoints for GoogleChart
  const dataPoints = [
    [
      property.landArea || 0,
      logicalPrice ? getAverageValueInUnits(logicalPrice) : 0,
      property.address,
    ],
    ...(() => {
      // Ensure soldProperties is defined and is an array
      if (!Array.isArray(soldProperties)) return [];

      return soldProperties
        .filter(
          ({ property }) =>
            (property?.landArea || property?.propertyDetails?.landArea) && // Ensure landArea exists
            (property?.price || property?.priceDetails?.price) // Ensure price exists
        )
        .map(({ property }) => [
          property?.landArea || property?.propertyDetails?.landArea || 0, // Fallback to 0 if no landArea
          property?.price || property?.priceDetails?.price || 0, // Fallback to 0 if no price
          property?.address ||
            property?.propertyDetails?.displayableAddress ||
            "Unknown Address", // Fallback to "Unknown Address"
        ]);
    })(),
  ];

  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
    setIsPropertyClicked(true);
  };

  return (
    <>
      <header className="bg-black text-white mb-20 py-24 text-center flex items-center justify-center">
        <img src={ausrealtyLogo} alt="AUSREALTY" />
      </header>
      <div className="container">
        <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center text-center space-y-20">
          <h4>{property?.address}</h4>

          <div className="space-y-8">
            <h4>LOGICAL PRICE</h4>

            {/* Price and Info Icon */}
            <div className="flex items-center justify-center gap-4">
              <span className="font-bold">
                {logicalPrice ? logicalPrice : "N/A"}
              </span>
              <Tooltip
                className="w-[300px]"
                text={<i className="fa fa-info-circle text-xs"></i>}
                tooltip="This is just a logical estimated price, and is grounded on a comprehensive set of factors including recent local sales, property size, number of bedrooms, the topography of the land, street traffic, recent updates to the property, and various other determinants. The information is sourced from public datasets which, while extensive, might be incomplete or contain inaccuracies, so should not be solely relied upon. For a more precise and accurate estimation of price, we strongly recommend consulting with a licensed real estate agent or a registered valuer. Occasionally, we may send you updates on the property market"
              />
            </div>

            {/* Property Details */}
            <div className="max-w-md mx-auto space-y-6">
              {/* Property Image */}
              <img
                // className="w-full h-auto rounded-lg filter grayscale"
                className="w-full h-auto rounded-lg"
                src={property?.media?.[0]?.url}
                alt="property"
              />

              {/* Property Address */}
              <p className="text-center">{property.address}</p>

              {/* Property Features */}
              <div className="flex items-center justify-center gap-4">
                <div className="text-sm">
                  <i className="fa fa-bed me-2"></i>
                  {property.bedrooms || "N/A"}
                </div>
                <div className="text-sm">
                  <i className="fa fa-bath me-2"></i>
                  {property.bathrooms || "N/A"}
                </div>
                <div className="text-sm">
                  <i className="fa fa-car me-2"></i>
                  {property.carspaces || "N/A"}
                </div>
                <div className="text-sm">{property.propertyType}</div>
              </div>

              {/* Sale Details */}
              <div className="flex justify-between gap-4 text-left text-sm">
                <div className="flex flex-col">
                  <span className="font-semibold">Sale Price:</span>
                  <span>{formatCurrency(property.price)}</span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold">Sale Date:</span>
                  <span>
                    {property.dateListed
                      ? convertDate(property.dateListed)
                      : "N/A"}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold">Days to Sell:</span>
                  <span>{property.daysListed}</span>
                </div>
              </div>
            </div>
          </div>

          {soldProperties && soldProperties.length > 0 && (
            <div class="w-full space-y-8">
              <h4>SOLD MATCHES</h4>
              <p>
                Getting the first part of the process right helps to create the
                maximum outcome. This is a logical range derived from market
                insights and the final outcome may vary significantly
              </p>
              <Slider {...settings} className="w-full">
                {soldProperties.map(({ property }) => (
                  <div
                    key={property._id}
                    className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                    onClick={() => handlePropertyClick(property)}
                  >
                    <img
                      className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                      src={
                        property?.media?.[0]?.url || "/placeholder-image.jpg"
                      }
                      alt="property"
                    />
                    <div className="text-center mt-4 space-y-3">
                      <p className="font-semibold">
                        {formatCurrency(
                          property?.priceDetails?.price || property?.price
                        )}
                      </p>
                      <p className="text-sm">
                        {property?.propertyDetails?.displayableAddress ||
                          property?.address}
                      </p>
                      <div className="flex items-center justify-center gap-4">
                        <div className="text-sm">
                          <i className="fa fa-bed me-2"></i>
                          {property?.propertyDetails?.bedrooms ||
                            property?.bedrooms ||
                            "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-bath me-2"></i>
                          {property?.propertyDetails?.bathrooms ||
                            property?.bathrooms ||
                            "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-car me-2"></i>
                          {property?.propertyDetails?.carspaces ||
                            property?.carspaces ||
                            "N/A"}
                        </div>
                      </div>
                      {/* <div className="text-xs text-start space-y-1">
                            <p className="font-semibold">Key Matches</p>
                            <p className="italic">{keyMatches.join(", ")}</p>
                          </div> */}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}

          {saleProperties && saleProperties.length > 0 && (
            <div class="w-full space-y-8">
              <h4>ON MARKET MATCHES</h4>
              <Slider {...settings} className="w-full">
                {saleProperties.map(({ property }) => (
                  <div
                    key={property._id}
                    className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                    onClick={() => handlePropertyClick(property)}
                  >
                    <img
                      className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                      src={
                        property?.media?.[0]?.url || "/placeholder-image.jpg"
                      }
                      alt="property"
                    />
                    <div className="text-center mt-4 space-y-3">
                      <p className="font-semibold">
                        {formatCurrency(
                          property?.priceDetails?.price || property?.price
                        )}
                      </p>
                      <p className="text-sm">
                        {property?.propertyDetails?.displayableAddress ||
                          property?.address}
                      </p>
                      <div className="flex items-center justify-center gap-4">
                        <div className="text-sm">
                          <i className="fa fa-bed me-2"></i>
                          {property?.propertyDetails?.bedrooms ||
                            property?.bedrooms ||
                            "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-bath me-2"></i>
                          {property?.propertyDetails?.bathrooms ||
                            property?.bathrooms ||
                            "N/A"}
                        </div>
                        <div className="text-sm">
                          <i className="fa fa-car me-2"></i>
                          {property?.propertyDetails?.carspaces ||
                            property?.carspaces ||
                            "N/A"}
                        </div>
                      </div>
                      {/* <div className="text-xs text-start space-y-1">
                            <p className="font-semibold">Key Matches</p>
                            <p className="italic">{keyMatches.join(", ")}</p>
                          </div> */}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}

          {soldProperties && soldProperties.length > 0 && (
            <GoogleChart dataPoints={dataPoints} />
          )}

          <div className="space-y-8 w-full">
            <h4>AREA SALES PROCESS BREAKDOWN</h4>
            <GooglePieChart data={pieChartData} />
          </div>

          <div class="w-full max-w-lg mx-auto space-y-8">
            <h4>YOUR AREA DYNAMICS</h4>
            {areaDynamics ? (
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-start border border-mediumgray border-collapse">
                  <thead>
                    <tr className="bg-mediumgray border border-mediumgray">
                      <th className="py-2 px-3 border border-mediumgray"></th>
                      <th className="py-2 px-3 text-left border border-mediumgray">
                        <i className="fa-solid fa-house mr-2"></i> House
                      </th>
                      <th className="py-2 px-3 text-left border border-mediumgray">
                        <i className="fa-solid fa-building mr-2"></i> Unit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b border-mediumgray">
                      <td className="py-2 px-3 border border-mediumgray">
                        Median Sale Price
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.houseStats
                          ? `$${areaDynamics.houseStats.medianSalePrice?.toLocaleString()}`
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.unitStats
                          ? `$${areaDynamics.unitStats.medianSalePrice?.toLocaleString()}`
                          : "N/A"}
                      </td>
                    </tr>
                    <tr className="border-b border-mediumgray">
                      <td className="py-2 px-3 border border-mediumgray">
                        Annual Sales Volume
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.annualSalesVolume
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.annualSalesVolume
                          : "N/A"}
                      </td>
                    </tr>

                    <tr className="border-b border-mediumgray">
                      <td className="py-2 px-3 border border-mediumgray">
                        Suburb Growth
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.suburbGrowth
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.suburbGrowth
                          : "N/A"}
                      </td>
                    </tr>
                    <tr className="border-b border-mediumgray">
                      <td className="py-2 px-3 border border-mediumgray">
                        High Demand Area
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.houseStats
                          ? areaDynamics.houseStats.highDemandArea
                          : "N/A"}
                      </td>
                      <td className="py-2 px-3 border border-mediumgray">
                        {areaDynamics.unitStats
                          ? areaDynamics.unitStats.highDemandArea
                          : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <i className="fa-solid fa-spinner animate-spin mr-2"></i>{" "}
                Loading ...
              </div>
            )}
          </div>

          <GoogleMaps lat={property?.latitude} lon={property?.longitude} />

          <div class="space-y-4 flex flex-col">
            <img src={picture} alt="Agent" className="max-h-[400px]" />
            <div>
              <p>{name}</p>
              <p>{email}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickSearchShare;
