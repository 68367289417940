import axios from "utils/axios";
import React, { useState } from "react";
import "./PriceProcess.css";
import "./Postlist.css";

const ProcessChain = ({
    property
}) => {
  const [steps, setSteps] = useState(property.onMarketProcessChain || property.processChain || []);

  const toggleStepSelection = (index) => {
    const updatedSteps = steps.map((step, i) => {
      if (i === index) {
        return { ...step, selected: step.selected === true ? null : true };
      }
      return step;
    });
    setSteps(updatedSteps
    );
    try {
      axios({
        method: "PUT",
        url: `/userProperty/updateOnMarketPriceChain`,
        data: {
          address: property.address,
          processChain: updatedSteps,
        },
      })
    } catch (error) {
      console.log(error)
      
    }
    
  };

  return (
    <div className="w-full space-y-8">
      <h4>THE PROCESS CHAIN</h4>
      <div className="w-full flex overflow-x-auto box-scrollbar">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center">
            <div>
              <div
                className="flex items-center justify-center border-4 chain border-gray-300 bg-white text-gray-500 cursor-pointer"
                onClick={() => toggleStepSelection(index)}
              >
                {step.selected === true ? "✔️" : "Not Yet"}
              </div>
              <p className="mt-2 mb-0 min-h-[32px] max-w-[100px] text-xs">
                {step.name}
              </p>
            </div>
            {index < steps.length - 1 && (
              <div className="chain-stick bg-gray-300"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProcessChain;
