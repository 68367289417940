import GoogleMaps from "components/GoogleMaps";
import MarkdownEditor from "components/markdown/MarkdownEditor";
import { FaEllipsisV } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import customAxios from "utils/axios";
import React, { useState, useEffect, useRef } from "react";
import Lightbox from "react-image-lightbox"; // Import lightbox
import "react-image-lightbox/style.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaDownload } from "react-icons/fa"; // Import the download icon
import { FaPencil } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";
import CommentBox from "components/ui/CommentBox";
import { LiaDownloadSolid } from "react-icons/lia";
import CustomQuillEditor from "components/ui/CustomQuillEditor";
import { showToast } from "components/ui/toast";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { TouchSensor, MouseSensor } from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FaFilePdf } from "react-icons/fa"; // For a PDF icon
import EditableField from "components/ui/EditableField";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ausrealtyLogo from "assets/images/ausrealty-logo.jpg";
import "./Marketing.css";

const pdf =
  "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/adcopy/Kangaroo+Adcopy.pdf";

const photoImages = [
  {
    id: 1,
    url: `https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/high-end.jpeg?v=${Date.now()}`,
    comments: [],
  },
  {
    id: 2,
    url: `https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/updated.jpeg?v=${Date.now()}`,
    comments: [],
  },
  {
    id: 3,
    url: `https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/original.jpeg?v=${Date.now()}`,
    comments: [],
  },
];

const floorplanImages = [
  {
    id: 1,
    url: "https://drive.google.com/thumbnail?id=1ISi6Tm6rGgSt8yxdKlifRFxjPpUxDbeS&sz=w1000&time1732799608588",
    comments: [],
  },
];

const signboardImages = [
  {
    id: 1,
    url: "https://drive.google.com/thumbnail?id=1ISi6Tm6rGgSt8yxdKlifRFxjPpUxDbeS&sz=w1000&time1732799608588",
    comments: [],
  },
  {
    id: 2,
    url: "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/updated.png",
    comments: [],
  },
  {
    id: 3,
    url: "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/original.png",
    comments: [],
  },
];

const urlToBase64 = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting URL to Base64:", error);
    return null;
  }
};

// Function to replace URLs with Base64
const replaceUrlsWithBase64 = async (imagesArray) => {
  return await Promise.all(
    imagesArray.map(async (image) => {
      const base64 = await urlToBase64(image.url);
      return { ...image, url: base64 }; // Replace URL with Base64
    })
  );
};

// Replace URLs in all arrays
const convertAllImagesToBase64 = async () => {
  const updatedPhotoImages = await replaceUrlsWithBase64(photoImages);
  const updatedFloorplanImages = await replaceUrlsWithBase64(floorplanImages);
  const updatedSignboardImages = await replaceUrlsWithBase64(signboardImages);

  // Return updated arrays if needed
  return {
    updatedPhotoImages,
    updatedFloorplanImages,
    updatedSignboardImages,
  };
};

// Run the function
convertAllImagesToBase64();

const Template1 = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(photoImages[0]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showDots, setShowDots] = useState(true);

  // Generate QR Code on component mount
  useEffect(() => {
    const generateQrCode = async () => {
      try {
        const url = await QRCode.toDataURL("https://example.com");
        setQrCodeUrl(url);
      } catch (error) {
        console.error("Error generating QR Code:", error);
      }
    };

    generateQrCode();
  }, []);

  const generatePDF = async () => {
    const canvasElement = document.getElementById("canvas-container");

    try {
      // Hide dots temporarily
      setShowDots(false);

      // Small delay to ensure UI updates
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Set PDF generation state
      setIsGeneratingPDF(true);

      // Another small delay to ensure the UI reflects changes
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Capture the first page content as a canvas
      const canvas = await html2canvas(canvasElement, {
        scale: 5,
        useCORS: true,
        allowTaint: true,
        logging: false,
        removeContainer: true,
      });

      // Convert the canvas to an image (PNG)
      const imgSrc = canvas.toDataURL("image/png");

      // Create a new PDF document
      const pdfWidth = 1219.2; // 4 feet in mm
      const pdfHeight = 2438.4; // 8 feet in mm

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });

      // Scale image dimensions to fit PDF size
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      // Add the image to the first page of the PDF
      pdf.addImage(imgSrc, "PNG", 0, 0, imgWidth, imgHeight);

      // Add a new page
      pdf.addPage();

      // Add centered text on the second page
      const text = "Starts with belief.";
      pdf.setFont("times", "normal"); // Set the font to Times New Roman
      pdf.setFontSize(200);
      const textWidth = pdf.getTextWidth(text);
      const xOffset = (pdfWidth - textWidth) / 2;
      const yOffset = pdfHeight / 2;
      pdf.text(text, xOffset, yOffset);

      // Save the PDF
      pdf.save("canvas.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      // Reset states
      setIsGeneratingPDF(false);
      setShowDots(true);
    }
  };

  const settings = {
    dots: showDots, // Dynamically show/hide dots
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    afterChange: (current) => {
      setCurrentSlide(current);
      setSelectedImage(photoImages[current]);
    },
  };

  const containerStyle = {
    display: "block",
    width: isGeneratingPDF ? "400px" : "375px",
    height: "100%",
    background: "#fff",
    textAlign: "center",
    border: isGeneratingPDF ? "none" : "1px solid #e5e5e5",
    margin: "0 auto",
    padding: "48px 32px",
    fontFamily: "Arial, sans-serif",
    fontSize: "12px",
  };

  return (
    <div>
      {/* Combined Canvas and Display Container */}
      <div id="canvas-container" style={containerStyle}>
        {/* Content for Display */}
        {!isGeneratingPDF && (
          <Slider {...settings} className="w-full">
            {photoImages.map((photo) => (
              <div key={photo.id}>
                <img
                  src={photo.url}
                  alt={`Property`}
                  style={{
                    width: "100%",
                    height: "420px",
                    objectFit: "cover",
                  }}
                />
                {selectedImage.id === photo.id && (
                  <p style={{ marginTop: "4px" }}>Selected</p>
                )}
              </div>
            ))}
          </Slider>
        )}

        {/* Content for PDF */}
        {isGeneratingPDF && (
          <img
            src={selectedImage.url}
            alt={`Selected Property`}
            style={{
              width: "100%",
              height: "420px",
              objectFit: "cover",
            }}
          />
        )}

        <br />
        <br />
        <img
          src={ausrealtyLogo}
          alt="Ausrealty"
          className="max-w-40 mx-auto"
        ></img>
        <br />
        <br />
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <div>
            <p>Ibrahim Merjan</p>
            <p>0402 532 987</p>
          </div>
          <div>
            <p>Ralph Noorie</p>
            <p>0410145278</p>
          </div>
        </div>
        <br />
        <br />
        {qrCodeUrl && (
          <img
            src={qrCodeUrl}
            alt="QR Code"
            style={{ width: "32px", height: "32px", margin: "auto" }}
          />
        )}
      </div>

      {/* Button */}
      <Button onClick={generatePDF} className="mx-auto mt-4 black-button">
        Download as PDF
      </Button>
    </div>
  );
};

const Template2 = () => {
  // Add scoped styles for slick-dots
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
        .slick-dots {
          bottom: -16px !important;
        }
      `;
    style.setAttribute("data-template2-style", "true");
    document.head.appendChild(style);

    return () => {
      document.head.querySelector("[data-template2-style]")?.remove();
    };
  }, []);

  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState(photoImages[0]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showDots, setShowDots] = useState(true);

  // Generate QR Code on component mount
  useEffect(() => {
    const generateQrCode = async () => {
      try {
        const url = await QRCode.toDataURL("https://example.com");
        setQrCodeUrl(url);
      } catch (error) {
        console.error("Error generating QR Code:", error);
      }
    };

    generateQrCode();
  }, []);

  const generatePDF = async () => {
    const canvasElement = document.getElementById("canvas-container");

    try {
      // Hide dots temporarily
      setShowDots(false);

      // Small delay to ensure UI updates
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Set PDF generation state
      setIsGeneratingPDF(true);

      // Another small delay to ensure the UI reflects changes
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Capture the first page content as a canvas
      const canvas = await html2canvas(canvasElement, {
        scale: 5,
        useCORS: true,
        allowTaint: true,
        logging: false,
        removeContainer: true,
      });

      // Convert the canvas to an image (PNG)
      const imgSrc = canvas.toDataURL("image/png");

      // Create a new PDF document
      const pdfWidth = 1219.2; // 4 feet in mm
      const pdfHeight = 2438.4; // 8 feet in mm

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });

      // Scale image dimensions to fit PDF size
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      // Add the image to the first page of the PDF
      pdf.addImage(imgSrc, "PNG", 0, 0, imgWidth, imgHeight);

      // Add a new page
      pdf.addPage();

      // Add centered text on the second page
      const text = "Starts with belief.";
      pdf.setFont("times", "normal"); // Set the font to Times New Roman
      pdf.setFontSize(200);
      const textWidth = pdf.getTextWidth(text);
      const xOffset = (pdfWidth - textWidth) / 2;
      const yOffset = pdfHeight / 2;
      pdf.text(text, xOffset, yOffset);

      // Save the PDF
      pdf.save("canvas.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      // Reset states
      setIsGeneratingPDF(false);
      setShowDots(true);
    }
  };

  const settings = {
    dots: showDots, // Dynamically show/hide dots
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    afterChange: (current) => {
      setCurrentSlide(current);
      setSelectedImage(photoImages[current]);
    },
  };

  const containerStyle = {
    display: "block",
    width: isGeneratingPDF ? "400px" : "375px",
    height: "100%",
    background: "#fff",
    textAlign: "center",
    border: isGeneratingPDF ? "none" : "1px solid #e5e5e5",
    margin: "0 auto",
    padding: "48px 16px",
    fontFamily: "Arial, sans-serif",
    fontSize: "12px",
  };

  return (
    <div>
      {/* Combined Canvas and Display Container */}
      <div
        className="template2-container"
        id="canvas-container"
        style={containerStyle}
      >
        <br></br>
        <br></br>
        <p style={{ fontSize: "18px", fontFamily: "times" }}>
          Starts with belief.
        </p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <p style={{ textAlign: "start", marginBottom: "8px" }}>
          AUCTION: ON-SITE 23rd December at 12:30 pm
        </p>

        <Slider {...settings} className="w-full">
          {photoImages.map((photo) => (
            <div key={photo.id}>
              <img
                src={photo.url}
                alt={`Property`}
                style={{
                  width: "100%",
                  height: "420px",
                  objectFit: "cover",
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "6px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <img
                    src={ausrealtyLogo}
                    alt="Ausrealty"
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                    className="max-w-28 mx-auto"
                  ></img>
                  {qrCodeUrl && (
                    <img
                      src={qrCodeUrl}
                      alt="QR Code"
                      style={{
                        width: "14px",
                        height: "14px",
                        margin: 0,
                        padding: 0,
                      }}
                    />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <span>Ibrahim Merjan</span>
                  <span>Ralph Noorie</span>
                </div>
              </div>
              {selectedImage.id === photo.id && (
                <p style={{ marginTop: "16px" }}>Selected</p>
              )}
            </div>
          ))}
        </Slider>
      </div>

      {/* Button */}
      <Button onClick={generatePDF} className="mx-auto mt-4 black-button">
        Download as PDF
      </Button>
    </div>
  );
};

const MailCardTemplate1 = () => {
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
        .slick-dots {
          bottom: -16px !important;
        }
      `;
    style.setAttribute("data-mailcard-template-style", "true");
    document.head.appendChild(style);

    return () => {
      document.head.querySelector("[data-mailcard-template-style]")?.remove();
    };
  }, []);

  const [selectedImage, setSelectedImage] = useState(photoImages[0]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showDots, setShowDots] = useState(true);

  const generatePDF = async () => {
    const canvasElement = document.getElementById("mailcard-canvas-container");

    try {
      setIsGeneratingPDF(true);

      // Get the HTML content of the container
      const htmlContent = canvasElement.outerHTML;

      // Send the HTML content to the backend using Axios
      const response = await customAxios.post(
        "/authSchedule/demo",
        { htmlContent }, // Send the raw HTML content
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob", // Important for handling binary data
        }
      );

      // Check if the response is successful
      if (response.status === 200) {
        // Create a blob and trigger the download
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "property-card.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsGeneratingPDF(false);
    }
  };

  const settings = {
    dots: showDots, // Dynamically show/hide dots
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    afterChange: (current) => {
      setCurrentSlide(current);
      setSelectedImage(photoImages[current]);
    },
  };

  const containerStyle = {
    display: "block",
    width: "88vw",
    maxWidth: "672px",
    height: "100%",
    background: "#fff",
    textAlign: "center",
    border: "1px solid #e5e5e5",
    margin: "16px auto",
    padding: "48px 32px",
    fontFamily: "Arial, sans-serif",
    fontSize: "12px",
  };

  return (
    <div>
      {/* Combined Canvas and Display Container */}
      <div id="mailcard-canvas-container" style={containerStyle}>
        <Slider {...settings} className="w-full">
          {photoImages.map((photo) => (
            <div key={photo.id}>
              <img
                src={photo.url}
                alt={`Property`}
                style={{
                  width: "100%",
                  height: "420px",
                  objectFit: "cover",
                }}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "6px",
                }}
              >
                <p>133 LAYCOCK ROAD, HURSTVILLE GROVE</p>
                <p>JUST LISTED</p>
              </div>
              <br />
              <img
                src={ausrealtyLogo}
                alt="Ausrealty"
                className="max-w-40 mx-auto"
              ></img>
              {selectedImage.id === photo.id && (
                <p style={{ marginTop: "16px" }}>Selected</p>
              )}
            </div>
          ))}
        </Slider>


      </div>

      {/* Button */}
      <Button
        onClick={generatePDF}
        loading={isGeneratingPDF}
        disabled={isGeneratingPDF}
        className="mx-auto mt-4 black-button"
      >
        Download as PDF
      </Button>
    </div>
  );
};

const DraggableImage = ({ image }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="p-2 border border-lightgray rounded cursor-move w-auto h-24"
    >
      <img
        src={image.url}
        alt={image.name}
        className="w-full h-full object-cover"
      />
    </div>
  );
};

const CommentsSection = ({
  sectionId,
  comments,
  onUpdateComments,
  onNewComment, // Callback to handle new comments
  type,
}) => {
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(null);

  const handleAddComment = (text) => {
    const newComment = {
      id: comments.length + 1,
      name: "Owner",
      text,
      time: "Just now",
    };

    // Update comments in the parent component
    onUpdateComments(sectionId, [...comments, newComment]);

    // Trigger callback for ChatGPT
    if (onNewComment) {
      onNewComment(text); // Send the latest comment text
    }
  };

  const handleEditComment = (id, updatedText) => {
    const updatedComments = comments.map((comment) =>
      comment.id === id ? { ...comment, text: updatedText } : comment
    );
    onUpdateComments(sectionId, updatedComments);
    setEditingCommentId(null);
  };

  const handleDeleteComment = (id) => {
    const updatedComments = comments.filter((comment) => comment.id !== id);
    onUpdateComments(sectionId, updatedComments);
  };

  return (
    <div className="w-full mt-4">
      {/* Comments List */}
      {comments.map((comment) => (
        <div key={comment.id} className="flex items-start gap-2 mb-4 text-xs">
          {/* Profile Image */}
          <div className="w-6 h-6 rounded-full bg-darkergray flex items-center justify-center text-white font-semibold">
            {comment.name[0]}
          </div>

          {/* Comment Content */}
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <div>
                <span className="text-xs font-semibold">{comment.name}</span>
                <span className="ml-2 text-darkergray text-xs">
                  {comment.time}
                </span>
              </div>
            </div>
            {editingCommentId === comment.id ? (
              <CommentBox
                onSend={(updatedText) =>
                  handleEditComment(comment.id, updatedText)
                }
                onCancel={() => setEditingCommentId(null)}
                initialValue={comment.text}
                showActions={true}
                placeholder="Edit your comment..."
                className="mt-2"
              />
            ) : (
              <p className="text-xs mt-0.5">{comment.text}</p>
            )}
          </div>

          {/* Options Dropdown */}
          <div className="relative">
            <HiOutlineDotsVertical
              onClick={() =>
                setDropdownVisible((prev) =>
                  prev === comment.id ? null : comment.id
                )
              }
              className="cursor-pointer text-gray-500 text-sm"
            />
            {dropdownVisible === comment.id && (
              <div className="absolute top-6 right-0 bg-white border rounded w-20 z-10">
                <button
                  onClick={() => {
                    setEditingCommentId(comment.id);
                    setDropdownVisible(null);
                  }}
                  className="block w-full px-2 py-1.5 hover:bg-lightgray text-left"
                  style={{ fontSize: "14px" }}
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteComment(comment.id)}
                  className="block w-full px-2 py-1.5 hover:bg-lightgray text-left"
                  style={{ fontSize: "14px" }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      ))}

      {/* Add New Comment */}
      <div className="mt-4">
        <CommentBox
          onSend={handleAddComment}
          placeholder={type === "ai" ? "Improve with AI" : "Add a comment..."}
          clearAfterSend={true}
          className="mt-2"
        />
      </div>
    </div>
  );
};

const ImageSelectionContainer = ({
  image,
  isSelected,
  onSelect,
  onPreview,
  viewMode = "grid",
  selectionType = "checkbox", // 'checkbox' or 'radio'
}) => {
  return (
    <div
      className={`relative group cursor-move ${
        isSelected ? "border border-darkgray p-1" : ""
      }`}
    >
      {/* Image Preview */}
      <img
        src={image.url}
        alt={image.name}
        className={`${
          viewMode === "grid" ? "h-56" : "max-h-[320px]"
        } w-full object-cover cursor-pointer border border-lightgray p-2`}
        onClick={() => onPreview(image.id)} // Open lightbox on click
      />

      {/* Selection Input */}
      <div className="absolute top-2 right-2 flex space-x-2 items-center">
        <input
          type={selectionType} // Dynamic input type (checkbox or radio)
          checked={isSelected}
          onChange={() => {
            console.log(
              `${
                selectionType === "checkbox" ? "Checkbox" : "Radio"
              } clicked for image ID:`,
              image.id
            );
            onSelect(image.id); // Handle selection
          }}
          className=" bg-lightgray rounded hover:bg-mediumgray m-0 p-0 scale-125"
        />
      </div>
    </div>
  );
};

const GalleryComponent = ({ name, images: propImages, changeOrder = true }) => {
  const [images, setImages] = useState(propImages);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isRadioModalOpen, setIsRadioModalOpen] = useState(false);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);

  const sensors = useSensors(
    useSensor(MouseSensor), // For desktop drag-and-drop
    useSensor(TouchSensor) // For mobile drag-and-drop
  );

  const handleOpenLightbox = (index) => {
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setImages((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleUpdateComments = (imageId, updatedComments) => {
    setImages((prevImages) =>
      prevImages.map((image) =>
        image.id === imageId ? { ...image, comments: updatedComments } : image
      )
    );
  };

  return (
    <div className="flex flex-col items-center">
      {/* Title */}
      <div className="w-full flex flex-col items-center gap-2 mb-4">
        {name && <h4 className="text-center">{name}</h4>}
        <div className="w-full flex gap-2 justify-end">
          {changeOrder && (
            <Button
              onClick={() => setIsOrderModalOpen(true)}
              className="ml-auto gray-button px-2 py-1 text-xs"
            >
              Change Order
            </Button>
          )}
          {name === "Signboard" && (
            <Button
              onClick={() => setIsRadioModalOpen(true)}
              className="black-button px-2 py-1 text-xs"
            >
              Select another
            </Button>
          )}
          {name === "Mailcard" && (
            <Button
              onClick={() => setIsRadioModalOpen(true)}
              className="black-button px-2 py-1 text-xs"
            >
              Select more
            </Button>
          )}
        </div>
      </div>

      {/* Slider Container */}
      <div className="flex flex-col gap-8">
        {images.map((image, index) => (
          <div
            key={index}
            className="w-full flex flex-col items-center shadow rounded p-4"
          >
            <div>
              <img
                alt="property"
                src={image.url}
                className="w-full h-auto object-cover rounded-md cursor-pointer"
                onClick={() => handleOpenLightbox(index)}
              />

              <CommentsSection
                sectionId={image.id}
                comments={image.comments}
                onUpdateComments={handleUpdateComments}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Lightbox Component */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[lightboxIndex].url} // Access the URL of the current image
          nextSrc={images[(lightboxIndex + 1) % images.length].url} // Access the URL of the next image
          prevSrc={
            images[(lightboxIndex + images.length - 1) % images.length].url
          } // Access the URL of the previous image
          onCloseRequest={() => setIsLightboxOpen(false)} // Close lightbox
          onMoveNextRequest={() =>
            setLightboxIndex((lightboxIndex + 1) % images.length)
          } // Go to next image
          onMovePrevRequest={() =>
            setLightboxIndex(
              (lightboxIndex + images.length - 1) % images.length
            )
          } // Go to previous image
          toolbarButtons={[
            // Custom Toolbar Buttons
            <span
              key="image-counter"
              style={{ color: "#fff", margin: "0 10px", fontSize: "16px" }}
            >
              {lightboxIndex + 1}/{images.length} {/* Image counter */}
            </span>,
            <LiaDownloadSolid
              className="w-7 h-7 mx-2 mb-2 cursor-pointer"
              onClick={() => window.open(images[lightboxIndex].url, "_blank")}
            />,
          ]}
        />
      )}

      <Modal
        isOpen={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(false)}
        title="Change Order of Images"
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={images.map((img) => img.id)}
            strategy={rectSortingStrategy}
          >
            <div className="grid grid-cols-3 gap-4">
              {images.map((image, index) => (
                <div key={image.id} className="relative">
                  {/* Display the index */}
                  <span
                    className="absolute top-2 left-2 bg-black text-white text-xs px-2 py-1 rounded"
                    style={{ zIndex: 10 }}
                  >
                    {index + 1}
                  </span>
                  {/* Draggable Image Component */}
                  <DraggableImage image={image} />
                </div>
              ))}
            </div>
          </SortableContext>
        </DndContext>
      </Modal>

      <Modal
        isOpen={isRadioModalOpen}
        onClose={() => setIsRadioModalOpen(false)}
        title="Select one"
      >
        <div className="gap-4 mb-4 grid grid-cols-2">
          {images.map((image, index) => (
            <ImageSelectionContainer
              key={image.id}
              image={image}
              // isSelected={selectedImages.includes(image.id)} // Check by unique ID
              // onSelect={toggleImageSelection}
              // onDelete={deleteImage}
              onPreview={() => setLightboxIndex(index)}
              selectionType="radio"
            />
          ))}
        </div>
      </Modal>

      <Modal
        isOpen={isCheckModalOpen}
        onClose={() => setIsCheckModalOpen(false)}
        title="Select one"
      >
        <div className="gap-4 mb-4 grid grid-cols-2">
          {images.map((image, index) => (
            <ImageSelectionContainer
              key={image.id}
              image={image}
              // isSelected={selectedImages.includes(image.id)} // Check by unique ID
              // onSelect={toggleImageSelection}
              // onDelete={deleteImage}
              onPreview={() => setLightboxIndex(index)}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
};

const SignBoard = ({ name, images: propImages }) => {
  const [images, setImages] = useState(propImages);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("Template1");

  // List of templates with corresponding snapshot data
  const templates = [
    {
      id: "Template1",
      name: "Template 1",
      component: <Template1 />,
      snapshot: "https://via.placeholder.com/150?text=Template+1", // Replace with actual preview image
    },
    {
      id: "Template2",
      name: "Template 2",
      component: <Template2 />,
      snapshot: "https://via.placeholder.com/150?text=Template+2", // Replace with actual preview image
    },
  ];

  return (
    <div className="flex flex-col items-center">
      {/* Title */}
      <div className="w-full flex flex-col items-center gap-2 mb-4">
        {name && <h4 className="text-center">{name}</h4>}
        <div className="w-full flex gap-2 justify-end">
          <Button
            onClick={() => setIsTemplateModalOpen(true)}
            className="black-button px-2 py-1 text-xs"
          >
            Select template
          </Button>
        </div>
      </div>

      {/* Render Selected Template */}
      <div className="w-full flex justify-center">
        {templates.find((t) => t.id === selectedTemplate)?.component}
      </div>

      {/* Modal for Template Selection */}
      <Modal
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
        title="Select a Template"
      >
        <div className="grid grid-cols-2 gap-4">
          {templates.map((template) => (
            <div
              key={template.id}
              className={`flex flex-col items-center cursor-pointer ${
                selectedTemplate === template.id
                  ? "bg-darkergray"
                  : "border-mediumgray border"
              }`}
              onClick={() => {
                setSelectedTemplate(template.id);
                setIsTemplateModalOpen(false);
              }}
            >
              {/* <img
                src={template.snapshot}
                alt={template.name}
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
              /> */}
              <p className="text-sm text-center my-2">{template.name}</p>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

const MailCard = ({ name, images: propImages }) => {
  const [images, setImages] = useState(propImages);
  const [selectedTemplate, setSelectedTemplate] = useState("Template1");

  // List of templates with corresponding snapshot data
  const templates = [
    {
      id: "Template1",
      name: "Template 1",
      component: <MailCardTemplate1 />,
      snapshot: "https://via.placeholder.com/150?text=Template+1", // Replace with actual preview image
    },
    {
      id: "Template2",
      name: "Template 2",
      component: <MailCardTemplate1 />,
      snapshot: "https://via.placeholder.com/150?text=Template+2", // Replace with actual preview image
    },
  ];

  return (
    <div className="flex flex-col items-center">
      {/* Title */}
      <div className="w-full flex flex-col items-center gap-2 mb-4">
        {name && <h4 className="text-center">{name}</h4>}
      </div>

      {/* Render Selected Template */}
      <div className="w-full flex justify-center">
        {templates.find((t) => t.id === selectedTemplate)?.component}
      </div>
    </div>
  );
};

const Marketing = () => {
  useEffect(() => {
    // Create and append the <style> tag
    const style = document.createElement("style");
    style.innerHTML = `
      .slick-slide {
        padding: 0 !important;
      }
    `;
    style.setAttribute("data-marketing-style", "true");
    document.head.appendChild(style);

    // Cleanup: Remove the <style> tag on component unmount
    return () => {
      document.head.querySelector("[data-marketing-style]")?.remove();
    };
  }, []);

  const boxes = [
    { id: "gallery", title: "GALLERY" },
    { id: "map", title: "MAP" },
    { id: "description", title: "DESCRIPTION" },
    { id: "floorplan", title: "FLOORPLAN" },
    { id: "video", title: "VIDEO" },
    { id: "contact", title: "CONTACT" },
    { id: "signboard", title: "SIGNBOARD" },
    { id: "brochure", title: "BROCHURE" },
    { id: "mailcard", title: "MAILCARD" },
    { id: "social media", title: "SOCIAL MEDIA" },
    { id: "backend response message", title: "BACKEND RESPONSE MESSAGE" },
    { id: "privacy notice", title: "PRIVACY NOTICE" },
    { id: "expectations list", title: "EXPECTATIONS LIST" },
    { id: "approval", title: "APPROVAL" },
  ];

  const [selectedBox, setSelectedBox] = useState("video");
  const observerRef = useRef(null);

  useEffect(() => {
    // Create an Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSelectedBox(entry.target.id);
          }
        });
      },
      {
        root: null, // Use the viewport as the container
        threshold: 0.2, // Trigger when 50% of the section is visible
      }
    );

    // Observe each section
    boxes.forEach((box) => {
      const element = document.getElementById(box.id);
      if (element) {
        observer.observe(element);
      }
    });

    // Cleanup on component unmount
    return () => {
      if (observerRef.current) {
        boxes.forEach((box) => {
          const element = document.getElementById(box.id);
          if (element) {
            observer.unobserve(element);
          }
        });
      }
    };
  }, []);

  useEffect(() => {
    if (selectedBox) {
      const activeBox = document.querySelector(`[data-id="${selectedBox}"]`);
      const container = document.querySelector(".my-scroll-container");

      if (activeBox && container) {
        const containerWidth = container.offsetWidth;
        const boxRect = activeBox.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Calculate the center offset
        const offset =
          boxRect.left -
          containerRect.left +
          boxRect.width / 2 -
          containerWidth / 2;

        container.scrollBy({
          left: offset,
          behavior: "smooth",
        });
      }
    }
  }, [selectedBox]);

  const [configuration, setConfiguration] = useState("4B | 3B | 2C");
  const [description, setDescription] =
    useState(`<h5>MODERN ELEGANCE MEETS TIMELESS COMFORT</h5>
  <p>"Every corner of this home whispers stories of laughter and warmth, a place where every sunset feels like a gentle embrace."</p>
  
  <p>
    This exquisite residence offers a harmonious blend of contemporary design and homely charm, perfect for families seeking both style and comfort. 
    Its thoughtful layout and premium finishes cater to those who appreciate the finer things in life.
  </p>

  <ul class="list-disc">
    <li>
      <strong>Bedrooms:</strong> Four spacious bedrooms, each designed with comfort in mind. 
      The master suite boasts an ensuite and ample wardrobe space, providing a private retreat.
    </li>
    <li>
      <strong>Bathrooms:</strong> Three elegantly appointed bathrooms, featuring modern fixtures and a soothing palette, ensure convenience and luxury for all family members.
    </li>
    <li>
      <strong>Kitchen:</strong> The heart of the home, this kitchen is equipped with top-of-the-line appliances and a butler's pantry, making it a culinary enthusiast's delight.
    </li>
    <li>
      <strong>Living and Dining Areas:</strong> Expansive living and dining spaces are bathed in natural light, offering a seamless flow for entertaining and everyday living.
    </li>
    <li>
      <strong>Outdoor Spaces:</strong> Step outside to a covered entertaining area and a sparkling swimming pool, perfect for creating cherished memories with loved ones.
    </li>
    <li>
      <strong>Additional Features:</strong> Includes a lock-up garage for three cars, ample storage, and air conditioning for year-round comfort.
    </li>
    <li>
      <strong>Location:</strong> Nestled in a friendly neighbourhood, close to schools, shops, and parks, with easy access to public transport, making it ideal for families.
    </li>
  </ul>`);

  const [privacyNotice, setPrivacyNotice] = useState(`<div class="space-y-4">
  <h5>AUSREALTY</h5>
  <p>
    The Privacy Act 1998 regulates the collection and use, disclosure, and maintenance of personal information
    collected by Real Estate Agents from people attending Open for Inspections.
  </p>
  <p>
    It is a condition of entry that before entering this property you must give your name and current telephone
    number to the Agency’s representative. In addition, you also may give your email address.
  </p>
  <p>This information is collected for the following reasons:</p>
  <ol>
    <li>Security reasons</li>
    <li>
      To allow us to call or email you after the open for inspection to establish your interest in the property
      and to inform you of other properties we have listed for sale that may suit your needs.
    </li>
    <li>To send you information from time to time regarding the local property market.</li>
    <li>
      To enable us to give feedback to the vendor regarding interest in the property. In this case only your
      surname or given name will be disclosed.
    </li>
    <li>
      The information is used for internal purposes only and phone numbers and email addresses will NOT be
      disclosed to any other third party (except as in 3 above) without your written consent.
    </li>
  </ol>
  <p>
    All personal information we collect is held electronically and in hard copy in our office in a secure manner.
    It will only be used for the purposes noted above and will not be disclosed to any third party or recipients
    in other countries without your written permission, unless required by law. However, we are not liable for
    any unauthorized access of this information.
  </p>
  <p>
    If you do not wish to be contacted as part of our normal follow-up procedures, please advise the Sales
    Consultant before leaving.
  </p>
  <p>
    You have the right to view the information collected about you, the right to correct the information if it is
    not accurate, up to date or complete. Should you ask us to remove your information from our records, we will
    do so securely.
  </p>
</div>`);

  const [backendResponse, setBackendResponse] =
    useState(`<div class="space-y-4">
  <p>
    <strong>Price Guide:</strong> &lt;$$$&gt; - We will have a more accurate
    indication on price once we receive buyer feedback.
  </p>
  <p>
    <strong>Offers closing:</strong> &lt;DATE&gt;
  </p>
  <p>
    <strong>FAQs:</strong> <a href="https://l.ausrealty.com.au/t/5179d8" target="_blank" rel="noopener noreferrer">https://l.ausrealty.com.au/t/5179d8</a>
  </p>
  <p>
    We have attached some further information in the following link which includes the following:
  </p>
  <ul>
    <li>Contract of Sale</li>
    <li>Privacy Act</li>
    <li>Photos</li>
    <li>Floor Plan</li>
    <li>Information about the area</li>
    <li>Details about the property</li>
  </ul>
</div>
`);

  const [expectationsList, setExpectationsList] =
    useState(`<div class="space-y-4">
<h5>WE ARE NOW ON THE MARKET.</h5>
<h5>HERE IS WHAT YOU SHOULD EXPECT.</h5>
  <p><strong>OFFERS</strong></p>
  <p>Once we ascertain serious offers the next step will be to ensure the terms are favourable and more importantly, the buyer is prepared to sign a contract on a straight sale and be prepared to write a cheque. A verbal offer with no commitment is simply not an offer.</p>
  <p>Early offer, what does that mean? The buyer who pays the most, is generally the buyer that has been in the market the longest. We may get an offer immediately from a buyer that has been in the market within 3 months with a high emotional attachment. It’s not how long we have been on the market, it is how long the buyer has been in the market.</p>
  <p><strong>OTHER AGENTS</strong></p>
  <p>Some agents may still contact you to inform you about:</p>
  <ol>
    <li>Their 'own' buyers that will pay more.</li>
    <li>That the marketing campaign is ‘wrong’.</li>
    <li>And how your property is worth substantially more now.</li>
  </ol>
  <p>They will usually pick a figure that is unlikely to eventuate, to stop you from making a sound judgment and ultimately hold out from selling, in order to use their services at a later date. This is evident with all listings. However, it is rare in the market as most agents are ethical, but the practice is not uncommon.</p>

  <p><strong>FRIENDS / FAMILY PRICE OPINIONS &amp; COMPARISONS</strong></p>
  <p>We love family and friends; we take advice and seek opinion which is normal. When selling your property however, the most important opinion comes from the person actually making an offer and looking to buy the home. Our agency will have direct contact to these people and will pass on all interest to you. Our job is to represent you in the open market place and ensure the highest possible price is extracted from these buyers. Opinions on price are cheap and easy to give - presenting a cheque and having a willingness to purchase is a different matter. To keep energy focused on the sale, we always recommend that we work closely together - focus on buyer feedback without external influences that may detract from the outcome. We have exhausted the marketing, received feedback and offers. Waiting for the right buyer after that can become a detriment to the result.</p>

  <p><strong>MARKETS PRICE / YOUR PRICE ?</strong></p>
  <p>Selling a property is emotional. We understand that and work to minimise stress throughout the process. On some occasions a vendor may feel that even after they have received the best possible price in the market and feedback is clear and consistent - that there may be another buyer in the market place that will pay a premium above the market premium that has already been generated. This is a normal feeling to have if the market has not aligned with the vendors expectation, however once buyer engagement has passed, generally the first 30 days maximum, re-engaging the market becomes difficult. The longer the property sits on the market, then the chances of replicating the initial highest offer, let alone eclipsing it, becomes incredibly low, based on statistical evidence and data. Our job is to get the highest possible price - we have studied, refined and closely monitored negotiation and property sales to ensure the best price is ascertained.</p>

  <p><strong>CRITICAL STAGE</strong></p>
  <p>It is likely that in the first week, keeping in mind we had one week pre-marketing, that our strongest interest will occur in this time. It will be our job to manage this enquiry and capitalise on the competition we will have.</p>

  <p><strong>RELATIONSHIP</strong></p>
  <p>Our company will never force you or engage in pressure tactics, with the view to have you enter into a contract. We will ascertain the highest possible price, whilst working closely with you.</p>

  <p><strong>THE RUBBER BAND</strong></p>
  <p>With the amount of leverage the buyer has, ascertaining a premium relevant to the market takes a consistent build up. As the buyer moves from logical to emotional the 'tension space', which is space between commitment and buyer remorse is extremely short. The 'rubber band' reverts back quickly, usually within 24 hours of submitting an offer.</p>

  <p><strong>BUBBLE OF HOPE</strong></p>
  <p>The GAP between market premium and vendor realisation.</p>
  <p>The fall back is usually a long way between the offer and the next offer thus time on market is extended if the offer is not closed. We will need to be braced to consider and decide quickly to ensure the sale can be finalised at it’s highest price.</p>

  <p><strong>NEGOTIATION</strong></p>
  <p>We will ask you for permission to dismiss offers on your behalf, if we feel the offer is:</p>
  <ol>
    <li>Not serious; and</li>
    <li>We can get more from the market.</li>
  </ol>
  <p>Once we have exhausted our efforts, at that point we will inform you, that we believe the highest price has been achieved and why, after which the decision will be yours to make.</p>
  <p>We will extract the best possible result. It is obviously in all our best interest to ensure this happens.</p>
  <p>Please leave it all to us, relax and let us do the work.</p>
</div>
`);

  const [comments, setComments] = useState([
    { id: 1, name: "Owner", text: "Initial comment", time: "5m ago" },
  ]);
  const handleUpdateComments = (sectionId, updatedComments) => {
    setComments(updatedComments);
  };

  const [descriptionEdit, setDescriptionEdit] = useState(false);
  const [aiLoading, setAILoading] = useState(false);

  const [tempDescription, setTempDescription] = useState(description);

  const handleSave = () => {
    setDescription(tempDescription);
    setDescriptionEdit(false);
  };

  const handleCancel = () => {
    setTempDescription(description);
    setDescriptionEdit(false);
  };

  const chatgptAPICall = async (input) => {
    try {
      setAILoading(true);
      const response = await customAxios.post(`/openai/text`, {
        systemPrompt: "MARKETING_ADCOPY_EDIT",
        userMessage: input + tempDescription,
      });

      if (response.data.success) {
        setDescription(response.data.data);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      showToast("error", errorMessage);
    } finally {
      setAILoading(false);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor), // For desktop drag-and-drop
    useSensor(TouchSensor) // For mobile drag-and-drop
  );

  return (
    <div>
      <div className="fixed top-[86px] bg-white px-6 py-6 w-full flex justify-center z-10">
        <div className="max-w-4xl mx-auto flex overflow-x-auto whitespace-nowrap box-scrollbar scroll-smooth my-scroll-container">
          {boxes.map((box, index) => (
            <div
              key={index}
              data-id={box.id} // Attach data-id for easy selection
              className={`cursor-pointer rounded flex-shrink-0 inline-flex flex-col items-center mr-2 py-2 px-4 relative
              ${box.id === selectedBox ? "bg-mediumgray" : "bg-lightgray"}
            `}
              onClick={() => {
                const element = document.getElementById(box.id);
                if (element) {
                  const offset = 120; // Adjust this value to match the height of your tabs
                  const bodyRect = document.body.getBoundingClientRect().top;
                  const elementRect = element.getBoundingClientRect().top;
                  const elementPosition = elementRect - bodyRect;
                  const offsetPosition = elementPosition - offset - 50;

                  window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                  });

                  setSelectedBox(box.id);
                }
              }}
            >
              <div className="relative text-center">
                <div className="text-xs m-0">
                  <div className="flex items-center">
                    <h6 className="text-capitalize">
                      {box.title.toLowerCase()}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="p-6 max-w-2xl mx-auto flex flex-col items-center justify-center space-y-16">
        <div id="gallery">
          <GalleryComponent images={photoImages} name="Gallery" />
        </div>

        <div
          id="map"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <h4>Map</h4>
          <GoogleMaps lat={-33.975671} lon={151.046778} view="roadmap" />
        </div>

        <div
          id="description"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <div className="w-full flex items-center gap-4 relative">
            <h4 className="absolute left-1/2 transform -translate-x-1/2 m-0 text-center">
              Description
            </h4>
            {!descriptionEdit && (
              <div
                className="ml-auto flex items-center gap-1.5 cursor-pointer"
                onClick={() => {
                  setTempDescription(description);
                  setDescriptionEdit(true);
                }}
              >
                <FaPencil className="w-3 h-3" /> <span>Edit</span>
              </div>
            )}
          </div>

          {!descriptionEdit && (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: description }}
                className="space-y-4"
              ></div>
              {aiLoading && (
                <div className="flex items-center gap-2 mt-4">
                  <i className="fa-solid fa-spinner animate-spin"></i>
                  <span>Generating...</span>
                </div>
              )}
              {!descriptionEdit && (
                <div
                  className="ml-auto flex items-center gap-1.5 cursor-pointer"
                  onClick={() => {
                    setTempDescription(description);
                    setDescriptionEdit(true);
                  }}
                >
                  <FaPencil className="w-3 h-3" /> <span>Edit</span>
                </div>
              )}

              <CommentsSection
                sectionId="description"
                comments={comments}
                onUpdateComments={handleUpdateComments}
                onNewComment={(latestComment) => chatgptAPICall(latestComment)}
                type={"ai"}
              />
            </>
          )}

          {descriptionEdit && (
            <CustomQuillEditor
              value={tempDescription}
              setValue={setTempDescription}
              onSave={handleSave}
              onCancel={handleCancel}
            />
          )}
        </div>

        <div id="floorplan" className="space-y-4">
          <h4 className="text-center">Floorplan</h4>
          <p className="font-bold mt-4">
            <EditableField
              value={configuration}
              onSave={(newValue) => {
                setConfiguration(newValue); // Update postList in state
              }}
            />
          </p>
          <GalleryComponent
            images={floorplanImages}
            name=""
            changeOrder={false}
          />
        </div>

        <div id="video" className="w-full space-y-4">
          <h4 className="text-center">Video</h4>
          <div
            className="relative w-full max-w-[800px] mx-auto"
            style={{
              paddingTop: "56.25%", // Enforces a 16:9 aspect ratio
              backgroundColor: "black", // Background for better visibility
            }}
          >
            <video
              src="https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-standard/1.mp4"
              className="absolute top-0 left-0 w-full h-full object-cover"
              controls={true}
            ></video>
          </div>
        </div>

        <div
          id="contact"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <h4>Contact</h4>

          <div class="space-y-4 flex flex-col text-center">
            <img
              src="https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/66e8b83f30459b73aef0daba.jpeg"
              alt="Agent"
              className="max-h-[400px]"
            />
            <div>
              <p>Imam Syed</p>
              <p>imam.syed@ausrealty.com.au</p>
            </div>
          </div>
        </div>

        <div
          id="signboard"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          {/* <GalleryComponent images={signboardImages} name="Signboard" /> */}
          <SignBoard images={signboardImages} name="Signboard" />
        </div>

        <div
          id="brochure"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <GalleryComponent images={signboardImages} name="Brochure" />
        </div>

        <div
          id="mailcard"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <MailCard images={signboardImages} name="Mailcard" />
        </div>

        <div
          id="social media"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <GalleryComponent images={signboardImages} name="Social Media" />
        </div>

        <div
          id="documents"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <h4>Contract of Sale</h4>
          <div
            className="cursor-pointer flex flex-col items-center justify-center p-8 shadow rounded-lg"
            onClick={() => window.open(pdf, "_blank", "noopener,noreferrer")}
          >
            {/* PDF Icon */}
            <FaFilePdf size={80} />
            <p className="mt-2">View</p>
          </div>
        </div>

        <div
          id="backend response message"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <h4>Backend Response Message</h4>
          <div dangerouslySetInnerHTML={{ __html: backendResponse }}></div>
        </div>

        <div
          id="privacy notice"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <h4>Privacy Notice</h4>
          <div dangerouslySetInnerHTML={{ __html: privacyNotice }}></div>
        </div>

        <div
          id="expectations list"
          className="space-y-4 w-full flex flex-col items-center justify-center"
        >
          <h4>Expectations List</h4>
          <div dangerouslySetInnerHTML={{ __html: expectationsList }}></div>
        </div>
      </div>

      {/* <Modal
        isOpen={aiWrite}
        onClose={() => setAIWrite(false)}
        title="Write with AI"
      >
        <div className="space-y-4 text-start">
          <div>
            <label className="form-label">Message</label>
            <textarea
              id="message"
              value={""}
              className="form-textarea border border-mediumgray"
              placeholder="Write here..."
              rows="3"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={() => setAIWrite(false)}
              className="gray-button"
            >
              Cancel
            </Button>
            <Button onClick={""} className="black-button">
              Send
            </Button>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default Marketing;
