import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "utils/axios";
import PageLoader from "components/ui/PageLoader";

const FileUpload = () => {
  const [authData, setAuthData] = useState(null);
  const [complianceData, setComplianceData] = useState(null);
  const { path, propertyId } = useParams();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("idle"); // idle, uploading, successful, failed

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch both APIs concurrently
        const [authResponse, complianceResponse] = await Promise.all([
          axios.get(`/authSchedule/${propertyId}`),
          axios.get(`/compliance/${propertyId}`)
        ]);
  
        // Extract data and update state
        const authData = authResponse.data.data; // Adjust according to your API response structure
        const complianceData = complianceResponse.data.data; // Adjust according to your API response structure
  
        if (authData) {
          setAuthData(authData);
        }
  
        if (complianceData) {
          setComplianceData(complianceData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsFetchingData(false);
      }
    };
  
    fetchData();
  }, [propertyId]);
  

  // Handles both input file change and dropped files
  const handleFileChange = (e) => {
    const newFile = e.target.files
      ? e.target.files[0]
      : e.dataTransfer.files[0];
    setFile(newFile);
    setUploadStatus("idle"); // Reset upload status on new file select
    setProgress(0); // Reset progress
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFileChange(e);
  };

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    setUploadStatus("uploading");

    try {
      // Step 1: Get Presigned URL from the backend
      const response = await axios.post("/authSchedule/fileUpload", {
        fileName: file.name,
        fileType: file.type,
        path,
        propertyId,
      });

      const { url } = response.data;

      // Step 2: Upload file to S3 using the presigned URL
      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentage);
        },
      });

      setProgress(100);
      setUploadStatus("successful");
    } catch (error) {
      console.error("Error uploading file", error);
      setUploadStatus("failed");
    } finally {
      setUploading(false);
    }
  };

  if (isFetchingData || !authData) {
    return <PageLoader />;
  }

  // If the upload is successful or if there's already an existing contract, show the success message
  if (
    path === "solicitor" &&
    (complianceData|| uploadStatus === "successful")
  ) {
    return (
      <div className="w-full min-h-screen max-w-4xl mx-auto flex flex-col items-center justify-center px-4">
        Thank you for uploading the contract of sale, the agent has been
        notified
      </div>
    );
  }

  if (
    path === "serviceBuilding" &&
    (authData.services[0].report || uploadStatus === "successful")
  ) {
    return (
      <div className="w-full min-h-screen max-w-4xl mx-auto flex flex-col items-center justify-center px-4">
        Thank you for uploading the Building and Pest report, the agent has been
        notified
      </div>
    );
  }

  if (
    path === "serviceStrata" &&
    (authData.services[1].report || uploadStatus === "successful")
  ) {
    return (
      <div className="w-full min-h-screen max-w-4xl mx-auto flex flex-col items-center justify-center px-4">
        Thank you for uploading the Strata report, the agent has been
        notified
      </div>
    );
  }

  return (
    <div className="container flex flex-col items-center space-y-8">
      <p className="mb-4 text-darkgray text-center">{authData.address}</p>

      <div className="w-full max-w-md mx-auto p-6 border border-mediumgray rounded-lg">
        <div
          className="p-6 border-2 border-dashed border-mediumgray rounded-lg cursor-pointer"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <label htmlFor="file-input" className="block text-center">
            <i className="fas fa-file-upload text-darkergray text-6xl mb-4"></i>
            <div className="text-darkergray">
              Drag and drop or{" "}
              <span className="underline">browse your files</span>
            </div>
          </label>
          <input
            type="file"
            id="file-input"
            onChange={handleFileChange}
            className="hidden"
            accept=".pdf,.doc,.docx"
          />
        </div>

        {file && (
          <div className="mt-6">
            <div className="flex items-center justify-between mb-2">
              <strong>{file.name}</strong>{" "}
              <span>({(file.size / (1024 * 1024)).toFixed(2)} MB)</span>
            </div>
            <div className="relative w-full h-3 bg-lightgray overflow-hidden mb-2">
              <div
                className="absolute left-0 top-0 h-full bg-black"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className="text-sm text-gray-600 flex items-center">
              {uploadStatus === "uploading" &&
                `${(((progress / 100) * file.size) / (1024 * 1024)).toFixed(
                  2
                )} MB of ${(file.size / (1024 * 1024)).toFixed(
                  2
                )} MB uploaded... (${progress}%)`}
              {uploadStatus === "successful" && (
                <div className="flex items-center text-green-500">
                  <i className="fas fa-check-circle mr-2"></i> Uploaded
                  Successfully!
                </div>
              )}
              {uploadStatus === "failed" && (
                <div className="flex items-center text-red-500">
                  <i className="fas fa-times-circle mr-2"></i> Upload Failed.
                  Try Again.
                </div>
              )}
            </div>
          </div>
        )}

        <button
          className={`mt-6 w-full py-2 text-white rounded ${
            !file || uploading ? "bg-mediumgray cursor-not-allowed" : "bg-black"
          }`}
          onClick={handleUpload}
          disabled={!file || uploading}
        >
          {uploading ? "Uploading..." : "Upload"}
        </button>
      </div>
    </div>
  );
};

export default FileUpload;
