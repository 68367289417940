import React, { useState, useRef, useEffect } from "react";
import { FiMinus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import { useForm, useFieldArray } from "react-hook-form";
import axios from "utils/axios";
import { Autocomplete } from "@react-google-maps/api"; // Removed useJsApiLoader
import SignatureCanvas from "react-signature-canvas";
import {
  fetchSignatureUrl,
  formatCurrency,
  formatDateToAEDT,
} from "utils/helperFunctions";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import "./AuthoriseSchedule.css";
import AuthoriseScheduleEditor from "./AuthoriseScheduleEditor";
import DeleteField from "components/ui/DeleteField";
import EditableField from "components/ui/EditableField";
import PageLoader from "components/ui/PageLoader";
import AuthoriseScheduleLock from "./AuthoriseScheduleLock";
import { showToast } from "components/ui/toast";
import Tooltip from "components/ui/Tooltip";
import MarketingPrice from "components/ui/MarketingPrice";
import AuthoriseScheduleComplete from "./AuthoriseScheduleComplete";
import EventsCalendar from "./EventsCalendar";

const SignaturePad = ({
  onSave,
  onReset,
  vendor,
  vendors,
  agentSignature,
  trigger,
  getValues,
  register,
  errors,
  propertyId,
  isValid,
  setValue,
  recommendedSales,
  recommendedSold,
}) => {
  const [loading, setLoading] = useState(false);
  const [sendToSignLoading, setSendToSignLoading] = useState(false);
  const { id: vendorId } = vendor;
  const sigCanvas = useRef(null); // Correctly initialize with null
  const [signConfirm, setSignConfirm] = useState(false);
  const [resetConfirm, setResetConfirm] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [termsOpen, setTermsOpen] = useState(false);
  const [sendToSignAllModal, setSendToSignAllModal] = useState(false);

  const getFormattedData = () => {
    const data = getValues();

    const formattedVendors = vendors.map((vendor) => {
      const vendorKey = `${vendor.id}`; // Key to access vendor data in 'data'

      // Fetch vendor data from form values
      const vendorData = {
        firstName: data[vendorKey]?.firstName || "",
        lastName: data[vendorKey]?.lastName || "",
        email: data[vendorKey]?.email || "",
        mobile: data[vendorKey]?.mobile || "",
        address: data[vendorKey]?.address || "",
        licence: data[vendorKey]?.licence || "",
        signature: data[vendorKey]?.signature || "",
        isCompany: data[vendorKey]?.isCompany || false,
        company: data[vendorKey]?.company || "",
        abn: data[vendorKey]?.abn || "",
        gst: data[vendorKey]?.gst || false,
        agreeTerms: data[vendorKey]?.agreeTerms || false,
        sentDate: data[vendorKey]?.sentDate || null,
        viewedDate: data[vendorKey]?.viewedDate || null,
      };

      // Check if current vendor matches the vendorId
      if (vendor.id === vendorId && signatureDataURL) {
        // Set signedDate for the specific vendor
        return {
          ...vendorData,
          signedDate: formatDateToAEDT(null),
          isSigned: true,
        };
      } else {
        // For other vendors, keep their existing signedDate if available
        const existingSignedDate =
          data[vendorKey]?.signedDate || vendor.signedDate || null;
        return {
          ...vendorData,
          signedDate: existingSignedDate,
        };
      }
    });

    // Assemble the final formatted data
    return {
      propertyId,
      propertyAddress: data.propertyAddress,
      vendors: formattedVendors,
      vendorFollowers: data.vendorFollowers,
      followers: data.followers,
      solicitor: {
        name: data.solicitorName,
        email: data.solicitorEmail,
        mobile: data.solicitorMobile,
        address: data.solicitorAddress,
      },
      events: data.events,
      status: data.status,
      fraudPrevention: data.fraudPrevention,
      terms: data.terms,
      prepareMarketing: data.prepareMarketing,
      saleProcess: data.saleProcess,
      startPrice: data.startPrice,
      endPrice: data.endPrice,
      commissionFee: data.commissionFee,
      commissionRange: data.commissionRange,
      commissionNotes:data.commissionNotes,
      marketing: data.marketing,
      recommendedSold: data.recommendedSold,
      recommendedSales: data.recommendedSales,
      agentSignature,
      privateTerms: data.privateTerms,
      auctionTerms: data.auctionTerms,
      access:
        data.access === "Tenanted" && data.accessOther
          ? `Tenanted - ${data.accessOther}`
          : data.access !== "Other"
          ? data.access
          : data.accessOther,
      services: [
        {
          name: "serviceBuilding",
          price: "Upfront $55",
          value: data.serviceBuilding,
        },
        {
          name: "serviceStrata",
          price: "Upfront $50 (homesafe)",
          value: data.serviceStrata,
        },
        {
          name: "serviceStyling",
          price: "",
          value: data.serviceStyling,
        },
      ],
      termsCondition:
        data.saleProcess === "Auction" ? data.auctionTerms : data.privateTerms,
      conclusionDate: data.conclusionDate,
    };
  };
  const formattedData = getFormattedData();

  const generateAndOpenPDF = async () => {
    try {
      // Open a blank tab immediately in response to user action
      const newWindow = window.open("", "_blank");

      if (!newWindow) {
        console.error("Popup was blocked or could not be opened");
        return; // Exit if the window couldn't be opened
      }

      newWindow.document.write(`
        <div style="display: flex; justify-content: center; align-items: center; height: 100vh; font-size: 32px; font-family: Arial, sans-serif;">
          <p style="font-size: 32px;">Loading PDF, please wait...</p>
        </div>
      `);

      // Make sure to set responseType to 'blob' to handle binary data
      const response = await axios.post(
        "/authSchedule/generatePdf",
        { content: getFormattedData() }, // This is the request body
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob", // Important for handling binary data
        }
      );

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Generate a URL for the Blob
      const pdfUrl = window.URL.createObjectURL(blob);

      // Redirect the new tab to the generated PDF
      newWindow.location.href = pdfUrl;

      // (Optional) Clean up the URL object after some time to release memory
      setTimeout(() => {
        window.URL.revokeObjectURL(pdfUrl);
      }, 1000 * 60); // 1 minute
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const clear = () => {
    sigCanvas.current?.clear(); // Optional chaining to prevent errors
  };

  // const handleSaveClick = async () => {
  //   if (!sigCanvas.current?.isEmpty()) {
  //     const trimmedCanvas = sigCanvas.current
  //       .getTrimmedCanvas()
  //       .toDataURL("image/png");
  //     setSignatureDataURL(trimmedCanvas);
  //     onSave(trimmedCanvas, vendorId);
  //   }

  //   await trigger();
  //   if (!isValid) {
  //     console.log(isValid)
  //     return;
  //   }

  //   setSignConfirm(true);
  // };

  const handleSaveClick = async () => {
    if (!sigCanvas.current?.isEmpty()) {
      const trimmedCanvas = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignatureDataURL(trimmedCanvas);
      onSave(trimmedCanvas, vendorId);
    }

    // Get all field names except 'signature' and 'licence'
    const data = getValues();

    const getFieldNames = (obj, prefix = "") => {
      let fieldNames = [];
      for (const key in obj) {
        const value = obj[key];
        const fieldName = prefix ? `${prefix}.${key}` : key;
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          fieldNames = fieldNames.concat(getFieldNames(value, fieldName));
        } else {
          fieldNames.push(fieldName);
        }
      }
      return fieldNames;
    };

    const allFieldNames = getFieldNames(data);
    const fieldsToValidate = allFieldNames.filter(
      (fieldName) => !fieldName.includes(".agreeTerms")
    );

    // Trigger validation only on specified fields
    const isValid = await trigger(fieldsToValidate);
    await trigger("vendorFollowers");
    if (!isValid || sigCanvas.current?.isEmpty()) {
      return;
    }

    setSignConfirm(true);
  };

  const handleSendToSign = async (signAll = false) => {
    const data = getValues();

    const getFieldNames = (obj, prefix = "") => {
      let fieldNames = [];
      for (const key in obj) {
        const value = obj[key];
        const fieldName = prefix ? `${prefix}.${key}` : key;
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          fieldNames = fieldNames.concat(getFieldNames(value, fieldName));
        } else {
          fieldNames.push(fieldName);
        }
      }
      return fieldNames;
    };

    const allFieldNames = getFieldNames(data);

    // const fieldsToValidate = allFieldNames.filter(
    //   (fieldName) => !fieldName.includes(".agreeTerms")
    // );
    const fieldsToValidate = allFieldNames.filter(
      (fieldName) =>
        !fieldName.includes(".agreeTerms") &&
        !fieldName.match(/^\d+\.(firstName|lastName|email|address|licence)$/)
    );

    // Trigger validation only on specified fields
    const isValid = await trigger(fieldsToValidate);

    if (!isValid) {
      return;
    }

    const updatedFormattedData = {
      ...formattedData,
      vendors: formattedData.vendors.map((vendor) => ({
        ...vendor,
        signedDate: null,
        isSigned: false,
        sentDate: null,
        viewedDate: null,
      })),
    };
    try {
      setSendToSignLoading(true);

      if (signAll) {
        const vendorIndexes = formattedData.vendors.map((_, index) => index);

        const res = await axios.post("/authSchedule/sendToSign", {
          formattedData: updatedFormattedData,
          vendorIndex: vendorIndexes,
        });

        if (res.data.success) {
          showToast("success", `Sent successfully`);
        }
      } else {
        const res = await axios.post("/authSchedule/sendToSign", {
          formattedData: updatedFormattedData,
          vendorIndex: vendorId - 1,
        });

        if (res.data.success) {
          showToast("success", "Sent successfully");
        }
      }

      window.location.reload();
    } catch (error) {
      console.log(error.message);
    } finally {
      setSendToSignLoading(false);
    }
  };

  const handleSendToSignAll = async () => {
    const data = getValues();

    const getFieldNames = (obj, prefix = "") => {
      let fieldNames = [];
      for (const key in obj) {
        const value = obj[key];
        const fieldName = prefix ? `${prefix}.${key}` : key;
        if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          fieldNames = fieldNames.concat(getFieldNames(value, fieldName));
        } else {
          fieldNames.push(fieldName);
        }
      }
      return fieldNames;
    };

    const allFieldNames = getFieldNames(data);

    // const fieldsToValidate = allFieldNames.filter(
    //   (fieldName) => !fieldName.includes(".agreeTerms")
    // );
    const fieldsToValidate = allFieldNames.filter(
      (fieldName) =>
        !fieldName.includes(".agreeTerms") &&
        !fieldName.match(/^\d+\.(firstName|lastName|email|address|licence)$/)
    );

    // Trigger validation only on specified fields
    const isValid = await trigger(fieldsToValidate);

    if (!isValid) {
      return;
    }

    setSendToSignAllModal(true);
  };

  const handleConfirmSign = async () => {
    const isTermsValid = await trigger(`${vendorId}.agreeTerms`);
    if (!isTermsValid) {
      return;
    }
    const formattedData = getFormattedData();
    try {
      setLoading(true);
      const res = await axios.post(
        "/authSchedule", // Ensure this is the correct endpoint
        { formattedData, propertyId }
      );
      if (res.data.success) {
        setSignConfirm(false);
        setIsSigned(true);
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmReset = () => {
    setResetConfirm(false);
    handleResetSignature();
    onReset(vendorId);
  };

  const handleResetSignature = () => {
    setIsSigned(false);
    setSignatureDataURL(null);
    sigCanvas.current?.clear();
  };

  const handleSaveTerms = (updatedTerms, type) => {
    setValue(type, updatedTerms);
  };

  return (
    <div className="w-full text-start relative">
      {!isSigned ? (
        <>
          <p>
            {formattedData.vendors[vendorId - 1]?.firstName}{" "}
            {formattedData.vendors[vendorId - 1]?.lastName}, please sign below
          </p>
          <div className="border border-mediumgray overflow-hidden">
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{
                className:
                  "w-full max-w-[400px] h-[120px] signature-canvas bg-white",
              }}
              clearOnResize={false}
            />
          </div>
          <div className="absolute top-28 right-2 flex space-x-2">
            <Button onClick={clear} className="text-xs">
              Clear
            </Button>
          </div>
          {/* Buttons Moved Inside SignaturePad */}
          <div className="flex justify-between my-2">
            <Button
              onClick={
                formattedData.vendors.length <= 1
                  ? handleSendToSign
                  : handleSendToSignAll
              }
              className="gray-button"
              disabled={sendToSignLoading}
              loading={sendToSignLoading}
            >
              Send to Sign
            </Button>

            <div className="flex items-center gap-2">
              <Button
                onClick={generateAndOpenPDF}
                className="underline text-xs"
              >
                View Agreement
              </Button>
              <Button
                className="black-button"
                onClick={handleSaveClick}
                loading={loading}
              >
                Finish
              </Button>
            </div>
          </div>

          <label
            onClick={() => setTermsOpen(true)}
            className="underline cursor-pointer"
          >
            TERMS
          </label>
        </>
      ) : (
        <>
          <div className="flex items-center gap-2">
            <p>Signed Successfully</p>
            <i
              className="fas fa-sync-alt cursor-pointer"
              onClick={() => setResetConfirm(true)}
              title="Reset Signature"
            ></i>
          </div>
          {signatureDataURL && (
            <div className="mt-2">
              <img
                src={signatureDataURL}
                alt="Signature"
                className="w-auto p-2 h-16 object-fill border border-mediumgray"
              />
            </div>
          )}
        </>
      )}

      {/* Modal for Sign Confirmation */}
      <Modal
        isOpen={signConfirm}
        onClose={() => setSignConfirm(false)}
        title="Sign Confirmation"
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <p>
            I, {formattedData.vendors[vendorId - 1]?.firstName}{" "}
            {formattedData.vendors[vendorId - 1]?.lastName}, confirm I am{" "}
            {formattedData.vendors[vendorId - 1]?.firstName}{" "}
            {formattedData.vendors[vendorId - 1]?.lastName} as identified by
            documents provided and/or sighted by Ausrealty. This signature is my
            own and I also confirm I agree to this electronic signing format and
            the agency agreement terms and conditions.
          </p>

          <div className="flex justify-between items-center w-full">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="p-0"
                {...register(`${vendorId}.agreeTerms`, {
                  required:
                    "You must agree to the terms before submitting your signature.",
                })}
              />
              <label
                onClick={() => setTermsOpen(true)}
                className="underline cursor-pointer"
              >
                TERMS
              </label>
            </div>
          </div>
          {errors?.[vendorId]?.agreeTerms && (
            <span className="form-error-message pt-1 text-red-500">
              {errors[vendorId].agreeTerms.message}
            </span>
          )}

          <div className="w-full flex gap-2 justify-end py-4">
            <Button
              onClick={() => setSignConfirm(false)}
              className="gray-button"
            >
              No
            </Button>
            <Button
              onClick={handleConfirmSign}
              className="black-button"
              loading={loading}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal for Reset Confirmation */}
      <Modal
        isOpen={resetConfirm}
        onClose={() => setResetConfirm(false)}
        title="Reset Confirmation"
      >
        <div className="flex flex-col items-center justify-center">
          <p>Are you sure you want to reset the signature?</p>
          <div className="w-full flex gap-2 justify-end py-4">
            <Button
              onClick={() => setResetConfirm(false)}
              className="gray-button"
            >
              No
            </Button>
            <Button
              onClick={handleConfirmReset}
              className="black-button"
              loading={loading}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal for Agreed Terms */}
      <Modal
        isOpen={termsOpen}
        onClose={() => {
          setTermsOpen(false);
          // setSignConfirm(true);
        }}
        title=""
        className="max-w-4xl max-h-[94vh] px-2"
      >
        <AuthoriseScheduleEditor
          formData={formattedData}
          onSaveTerms={handleSaveTerms}
          recommendedSales={recommendedSales}
          recommendedSold={recommendedSold}
        />
      </Modal>

      <Modal
        isOpen={sendToSignAllModal}
        onClose={() => {
          setSendToSignAllModal(false);
        }}
        title=""
      >
        <div className="flex flex-col items-center justify-center">
          <p>Do you want to send the email to all vendors?</p>
          <div className="w-full flex gap-2 justify-end py-4">
            <Button
              onClick={() => {
                setSendToSignAllModal(false);
                // setSendToSignAll(false);
                handleSendToSign();
              }}
              className="gray-button"
            >
              No
            </Button>
            <Button
              onClick={() => {
                setSendToSignAllModal(false);
                handleSendToSign(true);
              }}
              className="black-button"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const SolicitorSearch = ({ register, watch, setValue, trigger, errors }) => {
  const [address, setAddress] = useState("");
  const autocompleteRef = useRef(null);

  // Destructure register to get ref and onChange handler
  const {
    ref: addressRef,
    onChange: addressOnChange,
    ...rest
  } = register("solicitorAddress", {
    required: "Solicitor address is required",
  });

  const handleLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const handlePlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();

      const name = place.name || "";
      const formattedAddress = place.formatted_address
        ? place.formatted_address.replace(", Australia", "")
        : "";
      const phoneNumber = place.formatted_phone_number || "";

      setAddress(formattedAddress);

      // Update form values
      setValue("solicitorName", name, { shouldValidate: true });
      setValue("solicitorAddress", formattedAddress, { shouldValidate: true });
      setValue("solicitorMobile", phoneNumber, { shouldValidate: true });

      // Trigger validation
      trigger(["solicitorName", "solicitorAddress", "solicitorMobile"]);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setAddress(value);
    addressOnChange(e); // Update form state
    trigger("solicitorAddress"); // Trigger validation
  };

  const solicitorAddress = watch("solicitorAddress");

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    // Check if the 'tab' parameter is set to 'authorise-and-schedule'
    if (searchParams.get("tab") === "authorise-and-schedule") {
      const styleSheets = document.styleSheets;
      for (let i = 0; i < styleSheets.length; i++) {
        try {
          const rules = styleSheets[i].cssRules;
          for (let j = 0; j < rules.length; j++) {
            // Find the rule that applies to .pac-container.pac-logo and remove it
            if (rules[j].selectorText === ".pac-container.pac-logo") {
              styleSheets[i].deleteRule(j);
              break;
            }
          }
        } catch (e) {
          // Ignore cross-origin or inaccessible stylesheets
          if (e.name !== "SecurityError") {
            console.error(e);
          }
        }
      }
    }
  }, []);

  return (
    <Autocomplete
      onLoad={handleLoad}
      onPlaceChanged={handlePlaceChanged}
      options={{
        componentRestrictions: { country: ["au"] },
        fields: [
          "name",
          "formatted_address",
          "formatted_phone_number",
          "address_components",
          "geometry",
        ],
        types: ["establishment"],
      }}
    >
      <div className="relative">
        <input
          type="text"
          value={solicitorAddress || address}
          onChange={handleInputChange}
          placeholder="Search solicitor on Google"
          className={`search-input w-full relative z-10 flex-grow min-h-[39px] p-2.5 bg-lightgray rounded outline-none focus:outline-none resize-none overflow-y-hidden ${
            errors?.solicitorAddress ? "border border-red-500" : ""
          }`}
          ref={addressRef} // Attach ref from register
          {...rest} // Include other props from register
        />
        {/* Display Validation Error */}
        {errors.solicitorAddress && (
          <span className="form-error-message text-start pt-1">
            {errors.solicitorAddress.message}
          </span>
        )}
      </div>
    </Autocomplete>
  );
};

const Followers = ({ onTagsChange }) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  // Fetch users from /user endpoint on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/user"); // Adjust the endpoint as necessary
        setAllUsers(response.data.data); // Assuming response.data.data is the array of user objects
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Filter suggestions based on input value
    if (value) {
      const filteredSuggestions = allUsers.filter(
        (user) =>
          user.name && user.name.toLowerCase().includes(value.toLowerCase()) // Filter by name
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleTagAdd = (user) => {
    // Check if the user has already been added to tags
    if (!tags.some((tag) => tag._id === user._id)) {
      const updatedTags = [...tags, user]; // Add the full user object
      setTags(updatedTags); // Update local state
      onTagsChange(updatedTags); // Notify parent component with full user details
      setInputValue(""); // Clear input
      setSuggestions([]); // Clear suggestions
    }
  };

  const removeTag = (indexToRemove) => {
    const updatedTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(updatedTags);
    onTagsChange(updatedTags); // Notify parent component
  };

  return (
    <div className="w-full max-w-lg">
      <label className="form-label">Add Co-Agent</label>
      <input
        className="form-input border border-mediumgray"
        type="text"
        placeholder="Type to search agents"
        value={inputValue}
        onChange={handleInputChange}
      />

      {/* Show suggestions dropdown */}
      {suggestions.length > 0 && (
        <ul className="form-input border border-mediumgray bg-white w-full p-0 py-1 m-0 mt-2 list-none max-h-[120px] overflow-y-auto">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion._id}
              onClick={() => handleTagAdd(suggestion)} // Pass full user object
              className="px-2 py-1 cursor-pointer hover:bg-lightgray m-0"
            >
              {suggestion.name} {/* Display user name */}
            </li>
          ))}
        </ul>
      )}

      {/* Display tags (multiple selected users) underneath the input */}
      <div className="mt-2">
        {tags.length > 0 &&
          tags.map((tag, index) => (
            <div
              key={tag._id}
              className="flex items-center justify-between bg-lightgray text-darkergray p-2 mb-2"
            >
              <div className="flex items-center">
                <img
                  src={tag.picture}
                  alt={tag.name}
                  className="w-8 h-8 rounded-full mr-2"
                />
                <span>{tag.name}</span> {/* Show name */}
              </div>
              <button
                onClick={() => removeTag(index)} // Remove tag when clicking the button
                className="text-darkergray hover:lightgray px-2"
              >
                ×
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

// const PriceInput = ({ register, errors, setValue, watch }) => {
//   const startPrice = watch("startPrice") || "";
//   const endPrice = watch("endPrice") || "";

//   const formatWithCommas = (value) => {
//     return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   };

//   const handleStartPriceChange = (e) => {
//     let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
//     let formattedValue = formatWithCommas(value);

//     // Update start price with a dollar sign
//     const newStartPrice = `$${formattedValue}`;
//     setValue("startPrice", newStartPrice);

//     // Calculate and set end price (10% higher for example)
//     if (value) {
//       const highEndPrice = Math.round(parseInt(value, 10) * 1.1); // Add 10%
//       const formattedHighEndPrice = `$${formatWithCommas(highEndPrice.toString())}`;
//       setValue("endPrice", formattedHighEndPrice);
//     }
//   };

//   return (
//     <div className="flex">
//       <input
//         type="text"
//         className={`form-input rounded-none border ${
//           errors.startPrice ? "border-red-500" : "border-mediumgray"
//         }`}
//         {...register("startPrice", {
//           required: "Price is required",
//         })}
//         value={startPrice}
//         onChange={handleStartPriceChange}
//         placeholder="$0"
//       />

//       <input
//         type="text"
//         className={`form-input rounded-none border ${
//           errors.endPrice ? "border-red-500" : "border-mediumgray"
//         }`}
//         {...register("endPrice")}
//         value={endPrice}
//         readOnly
//         placeholder="$0"
//       />
//     </div>
//   );
// };

const PriceInput = ({ register, errors, setValue, watch }) => {
  const startPrice = watch("startPrice") || ""; // Watch start price
  const endPrice = watch("endPrice") || ""; // Watch end price

  const formatWithCommas = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (e, key) => {
    const input = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const selectionStart = e.target.selectionStart; // Get the cursor position

    if (key === "startPrice") {
      setValue(key, `$${formatWithCommas(input)}`);
    } else if (key === "endPrice") {
      setValue(key, `$${formatWithCommas(input)}`);
    }

    // Preserve cursor position
    setTimeout(() => {
      e.target.selectionStart = e.target.selectionEnd = selectionStart;
    });
  };

  const handleStartPriceBlur = () => {
    const startValue = startPrice.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (!startValue) {
      setValue("startPrice", ""); // Clear start price
      setValue("endPrice", ""); // Clear end price
      return;
    }

    const highEndPrice = Math.round(parseInt(startValue, 10) * 1.1); // Add 10%
    const formattedHighEndPrice = `$${formatWithCommas(
      highEndPrice.toString()
    )}`;

    const currentEndPrice = endPrice.replace(/[^0-9]/g, ""); // Remove non-numeric characters from end price
    if (
      !currentEndPrice ||
      parseInt(currentEndPrice, 10) < parseInt(startValue, 10)
    ) {
      setValue("endPrice", formattedHighEndPrice); // Autofill end price if less than start price
    } else if (parseInt(currentEndPrice, 10) > highEndPrice) {
      setValue("endPrice", formattedHighEndPrice); // Clamp end price to 10% if exceeded
    }
  };

  const handleEndPriceBlur = () => {
    const endValue = endPrice.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    const startValue = startPrice.replace(/[^0-9]/g, ""); // Remove non-numeric characters from start price

    if (!endValue || !startValue) {
      return; // Skip validation if either field is empty
    }

    const minEndPrice = parseInt(startValue, 10); // Start price as minimum
    const maxEndPrice = Math.round(minEndPrice * 1.1); // 10% higher as maximum

    if (parseInt(endValue, 10) < minEndPrice) {
      setValue("endPrice", `$${formatWithCommas(minEndPrice.toString())}`); // Reset to minimum
    } else if (parseInt(endValue, 10) > maxEndPrice) {
      setValue("endPrice", `$${formatWithCommas(maxEndPrice.toString())}`); // Reset to maximum
    } else {
      setValue("endPrice", `$${formatWithCommas(endValue)}`); // Update with valid value
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex">
        {/* Start Price Input */}
        <input
          type="text"
          className={`form-input rounded-none border ${
            errors.startPrice ? "border-red-500" : "border-mediumgray"
          }`}
          {...register("startPrice", {
            required: "Start price is required",
          })}
          value={startPrice}
          onChange={(e) => handleInputChange(e, "startPrice")} // Allow editing with preserved cursor position
          onBlur={handleStartPriceBlur} // Validate on blur
          placeholder="$0"
        />

        {/* End Price Input */}
        <input
          type="text"
          className={`form-input rounded-none border ${
            errors.endPrice ? "border-red-500" : "border-mediumgray"
          }`}
          {...register("endPrice", {
            required: "End price is required",
          })}
          value={endPrice}
          onChange={(e) => handleInputChange(e, "endPrice")} // Allow editing with preserved cursor position
          onBlur={handleEndPriceBlur} // Validate on blur
          placeholder="$0"
        />
      </div>

      {/* Validation Messages */}
      {errors.startPrice && (
        <p className="text-red-500 text-xs mt-1">{errors.startPrice.message}</p>
      )}
      {errors.endPrice && (
        <p className="text-red-500 text-xs mt-1">{errors.endPrice.message}</p>
      )}
    </div>
  );
};

const AuthoriseSchedule = ({
  property, // Use 'property' directly
  updateBoxLoading,
  updateBoxStatus,
}) => {
  const [pageLoading, setPageLoading] = useState(false);
  const propertyId = property._id;
  const [fetchedData, setFetchedData] = useState(null);
  const [isLock, setIsLock] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [eventsVisible, setEventsVisible] = useState(false);
  const [addFollowers, setAddFollowers] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPageLoading(true);
        const response = await axios.get(`/authSchedule/${propertyId}`);
        const data = response.data.data; // Adjust according to your API response structure

        if (data) {
          setFetchedData(data);
          setIsLock(data.isLock);
          setIsCompleted(data.isCompleted);
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      } finally {
        setPageLoading(false);
      }
    };

    fetchData();
  }, [propertyId]);

  const [agentSignature, setAgentSignature] = useState(null);
  useEffect(() => {
    const fetchSignature = async () => {
      try {
        const signatureUrl = await fetchSignatureUrl();
        if (signatureUrl) {
          setAgentSignature(signatureUrl);
        }
      } catch (error) {
        console.error("Error fetching signature URL:", error);
      }
    };

    fetchSignature();
  }, []);

  const parseLogicalPrice = (logicalPrice) => {
    if (!logicalPrice) return null;

    // Remove "$" and any spaces, and split by "-" to take the first part
    let firstPart = logicalPrice
      .split("-")[0]
      .replace(/\$/g, "")
      .replace(/,/g, "")
      .trim();

    // Check if the input contains "M" or "K" (case-insensitive)
    let unit = "";
    if (firstPart.toUpperCase().endsWith("M")) {
      unit = "M";
      firstPart = firstPart.slice(0, -1).trim(); // Remove the "M"
    } else if (firstPart.toUpperCase().endsWith("K")) {
      unit = "K";
      firstPart = firstPart.slice(0, -1).trim(); // Remove the "K"
    }

    // Parse the numerical part
    let number = parseFloat(firstPart);
    if (isNaN(number)) return null;

    // Multiply by the appropriate factor
    let value = number;
    if (unit === "M") {
      value = number * 1_000_000;
    } else if (unit === "K") {
      value = number * 1_000;
    }

    // Return formatted value, e.g., "$1,900,000"
    return `$${value.toLocaleString()}`;
  };

  // Prepare vendorsData from property.vendorDetails
  const vendorsData =
    fetchedData?.vendors?.length > 0
      ? fetchedData.vendors.map((vendor, index) => ({
          id: index + 1, // Assign unique ID for each vendor
          firstName: vendor.firstName || "",
          lastName: vendor.lastName || "",
          email: vendor.email || "",
          mobile: vendor.mobile || "",
          address: vendor.address || "",
          licence: vendor.licence || "",
          signature: vendor.signature || "",
          isCompany: vendor.isCompany || false,
          company: vendor.company || "",
          abn: vendor.abn || "",
          gst: vendor.gst ? "Yes" : "No",
          agreeTerms: vendor.agreeTerms || false,
          sentDate: vendor.sentDate || null,
          viewedDate: vendor.viewedDate || null,
        }))
      : property?.vendorDetails?.length > 0
      ? property.vendorDetails.map((vendor, index) => ({
          id: index + 1, // Assign unique ID for each vendor
          firstName: vendor.firstName || "",
          lastName: vendor.lastName || "",
          email: vendor.email || "",
          mobile: vendor.mobile || "",
          address: vendor.address || "",
          licence: vendor.licence || "",
          signature: vendor.signature || "",
          isCompany: vendor.isCompany || false,
          company: vendor.company || "",
          abn: vendor.abn || "",
          gst: vendor.gst ? "Yes" : "No",
          agreeTerms: vendor.agreeTerms || false,
          sentDate: formatDateToAEDT(vendor.sentDate || null),
          viewedDate: formatDateToAEDT(vendor.viewedDate || null),
        }))
      : [
          {
            id: 1,
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            address: "",
            licence: "",
            signature: "",
            isCompany: false,
            company: "",
            abn: "",
            gst: "No",
            agreeTerms: false,
            sentDate: formatDateToAEDT(null),
            viewedDate: formatDateToAEDT(null),
          },
        ];

  // Initialize defaultValues including vendor data
  const defaultValues = {
    terms: "30",
    prepareMarketing: "ASAP",
    status: "Vacant possession",
    fraudPrevention: "Yes",
    saleProcess: property?.recommendedSaleProcess?.includes("Auction")
      ? "Auction"
      : "Private Treaty",
    startPrice: parseLogicalPrice(property.logicalPrice) || "",
    endPrice: "",
    commissionFee: "",
    commissionRange: "",
    commissionNotes:"",
    vendors: vendorsData,
    marketing: property?.marketing || "",
    vendorFollowers: [],
    followers: property?.followers || [], // Initialize followers
    propertyAddress: property?.address,
    recommendedSold: (property?.recommendedSold || []).map(
      ({ property: nestedProperty, score }) => ({
        address:
          nestedProperty?.address ||
          nestedProperty?.propertyDetails?.displayableAddress,
        price: nestedProperty?.price || nestedProperty?.priceDetails?.price,
        score,
      })
    ),
    recommendedSales: (property?.recommendedSales || []).map(
      ({ property: nestedProperty, score }) => ({
        address:
          nestedProperty?.address ||
          nestedProperty?.propertyDetails?.displayableAddress,
        price: nestedProperty?.price || nestedProperty?.priceDetails?.price,
        score,
      })
    ),
    solicitorAddress: "",
    agentSignature,
    privateTerms: `
    <div class="terms-condition">
    <div id="editable">  
        <h2>
            PART 3 | TERMS AND CONDITIONS OF EXCLUSIVE SELLING AGENCY AGREEMENT AND CONTINUING AGENCY AGREEMENT
        </h2>
    
        <div class="terms">
            <ol type="1">
                <li>
                    DEFINITIONS
                    <ol>
                        <li>
                            "the Act" means the Property and Stock Agents Act 2002
                            (NSW).
                        </li>
                        <li>
                            "the ETA" means the Electronic Transactions Act 2000 (NSW).
                        </li>
                        <li>
                            "Agreement" means this Sales Inspection Report and Exclusive Selling Agency
                            Agreement and Continuing Agency Agreement, including the
                            terms and conditions set out in this Part 3.
                        </li>
                        <li>
                            "Government and other imposts" includes State and Federal
                            Taxes and any tax in the nature of a goods or services tax
                            and any other taxes or charges debited by banks or financial
                            institutions against the account of the Licensee in relation
                            to sale of the Property.
                        </li>
                        <li>
                            "Introduced" – A Purchaser shall be taken to have been
                            "Introduced" to the Property or the Vendor by the Licensee,
                            by any other agent, or by any other person (including the
                            Vendor):
                            <ol type="a">
                                <li>
                                    if the Purchaser becomes aware that the Property is
                                    available for sale as a result of reading any
                                    advertisement, notice or placard referring to the
                                    availability of the Property for sale, published or
                                    erected by, or in the name of, the Licensee or that
                                    other agent or that other person (including the Vendor);
                                    or
                                </li>
                                <li>
                                    if the fact that the Property is available for sale is
                                    otherwise made known to the Purchaser by or through the
                                    Licensee or that other agent or that other person
                                    (including the Vendor); or
                                </li>
                                <li>
                                    if the Licensee or that other agent or that other person
                                    (including the Vendor) is an effective cause of the
                                    Sale, whether or not the Sale agreement is entered into
                                    whilst this Agreement is on foot; and
                                </li>
                                <li>
                                    regardless of the fact that more than one person could
                                    be said to have Introduced the Purchaser to the Property
                                    or the Vendor, within the meaning of this clause; and
                                </li>
                                <li>
                                    regardless of whether the Purchaser is merely related to
                                    or known by the person who had the actual relevant
                                    dealings with the Licensee or that other agent or that
                                    other person (including the Vendor); and
                                </li>
                                <li>
                                    regardless of when the Purchaser came into existence or
                                    acquired legal personality or capacity.
                                </li>
                            </ol>
                        </li>
                        <li>
                            "Material Fact" is a fact that would be important to a
                            reasonable person in deciding whether or not to proceed with
                            a particular transaction or a matter prescribed by the Act
                            or clause 54 Property and Stock Agents Regulation.
                        </li>
                        <li>
                            "Person" includes a corporation or representative of a
                            corporation.
                        </li>
                        <li>
                            "Purchaser" includes a person who enters into a binding
                            agreement to purchase the Property (which includes the
                            exercising of an option, or entering into any agreement that
                            is in effect, if not by name or strict legal construction, a
                            purchase of the Property), whether or not it completes.
                        </li>
                        <li>
                            "Sale" includes a binding agreement to purchase the Property
                            (which includes the exercising of an option, or entering
                            into any agreement that is in effect, if not by name or
                            strict legal construction, a purchase of the Property),
                            whether or not it completes.
                        </li>
                        <li>
                            Words importing one gender include the other and singular
                            includes the plural and vice versa (e.g. “Vendor”, if
                            applicable, refers to the vendors, when two or more people
                            own the Property).
                        </li>
                        <li>
                            A reference to includes means includes but without
                            limitation.
                        </li>
                    </ol>
                </li>
                <li>
                    AGENCY
                    <ol>
                        <li>
                            The Vendor appoints the Licensee to sell the Property on
                            behalf of the Vendor as:
                            <ol type="a">
                                <li>
                                    exclusive selling agent for the sale of the Property,
                                    for the Exclusive Agency Period set out in Part 2, Item
                                    A; and (b)at the expiry of the Exclusive Agency Period,
                                    as non-exclusive selling agent for the sale of the
                                    Property for the <br />
                                    <span class="ml-1">
                                        Continuing Agency Period set out in Part 2, Item A.
                                    </span>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    PRICE
                    <ol>
                        <li>
                            If the Property is sold by private treaty, the Vendor
                            authorises the Licensee to sell the Property at the price
                            set out in Item C of the Particulars or such other price
                            the Vendor approves.
                        </li>
                        }
                    </ol>
                </li>
                <li>
                    LICENSEE’S REMUNERATION
                    <ol>
                        <li>
                            Remuneration - The Licensee is entitled to the Remuneration
                            set out in Item D of the Particulars ("the Remuneration") as
                            follows:-
                            <ol type="a">
                                <li>
                                    The Licensee will be entitled to the Commission set out
                                    in Item D of the Particulars ("the Commission") if,
                                    during the Exclusive Agency Period:
                                    <ol type="i">
                                        <li>
                                            the Purchaser is Introduced to the Property or the
                                            Vendor by the Licensee, by any other agent, or by
                                            any other person (including the Vendor), whether or
                                            not a Sale of the Property occurs whilst this
                                            Agreement is on foot; or
                                        </li>
                                        <li>
                                            there is a Sale of the Property, even if the Sale
                                            does not complete (and regardless of the cause of
                                            the Sale not completing).
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    The Licensee will be entitled to the Commission if,
                                    during the Continuing Agency Period:
                                    <ol type="i">
                                        <li>
                                            the Purchaser is Introduced to the Property or the
                                            Vendor by the Licensee, by an agent who is not
                                            lawfully appointed pursuant to the Act, or by any
                                            other person (including the Vendor), whether or not
                                            a Sale of the Property occurs whilst this Agreement
                                            is on foot; or
                                        </li>
                                        <li>
                                            there is a Sale of the Property other than a Sale
                                            resulting from the Purchaser being Introduced to the
                                            Property or the Vendor during the Continuing Agency
                                            Period by another agent lawfully appointed pursuant
                                            to the Act, even if the Sale does not complete (and
                                            regardless of the cause of the Sale not completing).
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    The Commission is due and payable by the Vendor to the
                                    Licensee immediately and in full when the Sale of the
                                    Property completes.
                                </li>
                                <li>
                                    If, after the Vendor and the Purchaser have entered into
                                    a binding agreement for the Sale of the Property:
                                    <ol type="i">
                                        <li>
                                            the Vendor and the Purchaser enter into a mutual
                                            agreement (whether written or verbal) to rescind the
                                            agreement or otherwise not proceed with the Sale; or
                                        </li>
                                        <li>
                                            the agreement is terminated as a result of the
                                            default of the Vendor; or
                                        </li>
                                        <li>
                                            the agreement is terminated as a result of the
                                            default of the Purchaser (regardless of the amount
                                            of the deposit which has been paid at the date of
                                            termination, and regardless of the amount of the
                                            deposit which is forfeited to or recoverable by the
                                            Vendor); or
                                        </li>
                                        <li>
                                            the Vendor does not proceed with the Sale for any
                                            other reason (including a postponement of the
                                            completion of the Sale for more than 30 days after
                                            the original completion date),
                                            <br />
                                            <span class="ml-2">
                                                the Commission will become due and payable by the
                                                Vendor to the Licensee immediately.
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    The Commission is calculated (as set out in Item D of
                                    the Particulars) on the selling price, and is inclusive
                                    of GST.
                                    <p class="ml-2">
                                        WARNING: The term immediately above provides that a
                                        commission is payable under this agreement even if the
                                        sale of the property is not completed.
                                    </p>
                                    <p class="ml-2">
                                        IMPORTANT: This is an exclusive agency agreement. This
                                        means you may have to pay the agent commission even if
                                        another agent (or you) sells the property or
                                        introduces a buyer who later buys the property.
                                    </p>
                                    <p class="ml-2">
                                        WARNING: Have you signed an agency agreement for the
                                        sale of this Property with another agent? If you have
                                        you may have to pay 2 commissions (if this agreement
                                        or the other agreement you have signed is a sole or
                                        exclusive agency agreement).
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Authority to Deduct – The Licensee may, upon receipt of a
                            direction from the Purchaser or their Legal Representative
                            authorising the Licensee to account to the Vendor for the
                            deposit, deduct from the deposit all or part of the
                            Remuneration, and all or part of the Expenses and Charges
                            that are payable to the Licensee pursuant to this Agreement,
                            up to the entire amount of the deposit.
                        </li>
                        <li>
                            Variation - The Remuneration provided for in this Agreement
                            (that is, both the Commission and the Other Services) cannot
                            be varied except as agreed in writing by the Vendor.
                        </li>
                    </ol>
                </li>
                <li>
                    EXPENSES AND CHARGES
                    <ol>
                        <li>
                            The Vendor must reimburse the Licensee for the expenses and
                            charges incurred and described in Item E of the Particulars.
                            Those services and amounts cannot be varied except with the
                            agreement in writing of the Vendor.
                        </li>
                        <li>
                            The reimbursement is due and payable as and when the Vendor
                            is notified by the Licensee that the expenses or charges
                            have been incurred.
                        </li>
                        <li>
                            The actual amount incurred is to be reimbursed (including
                            any additional GST which the Licensee is or becomes liable
                            to pay to the Commonwealth), even if it exceeds the estimate
                            (if any) that is given in Item E.
                        </li>
                        <li>
                            Variation - The Expenses and Charges provided for in this
                            Agreement cannot be varied except as agreed in writing by
                            the Vendor.
                        </li>
                    </ol>
                </li>
                <li>
                    ADVERTISING AND PROMOTION
                    <ol>
                        <li>
                            The Licensee will advertise or otherwise promote the
                            Property as set out in Item F of the Particulars.
                        </li>
                        <li>
                            If the Licensee is to pay any advertising or promotion
                            costs, they are to be included in Item E as “Expenses and
                            Charges”.
                        </li>
                        <li>
                            The Licensee is authorised to erect a For Sale sign at the
                            Property unless instructed differently.
                        </li>
                        <li>
                            The Vendor acknowledges that the Licensee is not responsible
                            for any liability, injury or damage incurred as a result of
                            the sign being erected at the Property.
                        </li>
                    </ol>
                </li>
                <li>
                    CONJUNCTION AGENTS
                    <ol>
                        <li>
                            The Vendor acknowledges that the Licensee is authorised to
                            act in conjunction with another licensed real estate agent
                            to market and sell the Property, however the Licensee is not
                            authorised to offer any payment to the conjunction agent
                            other than a payment that is made by, or from monies owing
                            to, the Licensee.
                        </li>
                        <li>
                            For the avoidance of doubt, and notwithstanding any other
                            provision in this Agreement:
                            <ol>
                                <li>
                                    the Licensee is forbidden to pay any conjunction fee or
                                    any other payment in the nature of a referral fee to a
                                    person who is not appropriately licensed under the Act
                                    or under any other legislation applicable to them;
                                </li>
                                <li>
                                    the use of a conjunction agent does not increase the
                                    amount of, or vary in any way, the Remuneration or the
                                    Expenses and Charges, unless expressly agreed in
                                    writing; and
                                </li>
                                <li>
                                    if during the Continuing Agency Period, a Sale of the
                                    Property results from the Licensee acting as a
                                    conjunction agent (as opposed to the Licensee using a
                                    conjunction agent), the Licensee is not entitled to be
                                    paid any of the Commission, but is permitted to accept
                                    payment from monies that are held by or owing to that
                                    other licensed agent.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    MATERIAL FACTS
                    <ol>
                        <li>
                            The Vendor acknowledges that pursuant to the Act and clause
                            54 Property and Stock Agents Regulation, the Licensee is
                            required to disclose all “Material Facts” relating to the
                            Property to any prospective or actual purchaser.
                        </li>
                        <li>
                            The Vendor warrants that they have provided to the Licensee
                            all information which may be considered a “Material Fact” in
                            relation to the Property and they have completed the
                            Material Fact disclosure document which forms part of this
                            Agreement.
                        </li>
                        <li>
                            The Vendor warrants that if they become aware of any further
                            information that may be considered a Material Fact after
                            entering into this Agreement they will immediately provide
                            that information to the Licensee.
                        </li>
                        <li>
                            The Vendor authorises and directs the Licensee to disclose
                            anything which may be a “Material Fact” in relation to the
                            Property to any actual or prospective purchaser of the
                            Property.
                        </li>
                        <li>
                            The Vendor indemnifies the Licensee against all actions,
                            claims and demands brought against, and all costs, losses
                            and liabilities incurred by the Licensee arising from or
                            connected with a failure on the part of the Vendor to
                            disclose a “Material Fact” or as a result, howsoever caused,
                            of the Vendor providing false, misleading or deceptive
                            information to the Licensee.
                        </li>
                    </ol>
                </li>
                <li>
                    DEPOSIT
                    <ol>
                        <li>
                            The Vendor agrees that any deposit paid in accordance with
                            an agreement for the sale of the Property will be held in
                            the Trust Account of the Licensee as stakeholder, as
                            directed by the parties, pending completion of the sale.
                        </li>
                    </ol>
                </li>
                <li>
                    FINANCIAL INSTITUTION TAXES OR DEDUCTIONS
                    <ol>
                        <li>
                            If the Licensee incurs any taxes or deductions debited by
                            banks or other financial institutions against the Licensee’s
                            account, that relate to the affairs of the Vendor, the
                            Licensee is entitled to be reimbursed for the charges it
                            incurs.
                        </li>
                    </ol>
                </li>
                <li>
                    PAYMENT TO THE VENDOR
                    <ol>
                        <li>
                            If any money that is held by the Licensee in respect of this
                            Agreement becomes due and payable to the Vendor, the Vendor
                            directs the Licensee to pay the money by cheque or
                            electronic funds transfer to the Vendor's bank account.
                        </li>
                    </ol>
                </li>
                <li>
                    APPROVED GUIDE
                    <ol>
                        <li>
                            The Vendor confirms that prior to (but no more than 1 month
                            prior to) the Vendor signing this Agreement, the Licensee
                            has provided the Vendor with a copy of the approved guide
                            entitled ‘Agency Agreements for the Sale of Residential
                            Property’.
                        </li>
                    </ol>
                </li>
                <li>
                    CONTRACT FOR SALE
                    <ol>
                        <li>
                            The Licensee must not offer the Property (if the Property is
                            a residential property), for sale unless a copy of the
                            proposed contract for the sale of the Property (including
                            all mandatory disclosure documents required by section 52A
                            of the Conveyancing Act 1919) is available for inspection by
                            prospective purchasers at the Licensee’s registered office.
                        </li>
                        <li>
                            The Vendor must provide to the Licensee a copy of the
                            contract for sale as soon as it is practicable.
                        </li>
                        <li>
                            The Licensee is not authorised to sign a contract for sale
                            on behalf of the Vendor.
                        </li>
                    </ol>
                </li>
                <li>
                    INDEMNITY
                    <ol>
                        <li>
                            The Agent having complied with its obligations under this
                            Agreement and not having been negligent, the Vendor
                            indemnifies the Agent, its officers and employees, from and
                            against all actions, claims, demands, losses, costs damages
                            and expenses arising out of this Agreement in respect of:
                            <br />
                            <ol type="i">
                                <li> authorised sales advertising and signage; or</li>
                                <li>
    
                                    the Vendor's failure to comply with this Agreement; or
                                </li>
                                <li>
    
                                    the Vendor's failure to give the Agent prompt and
                                    appropriate authority or instruction, or sufficient
                                    funds to carry out an instruction or authority; or
                                </li>
                                <li>
    
                                    the Agent acting on behalf of the Vendor under this
                                    Agreement; or
                                </li>
                                <li>
    
                                    the Agent incurring legal costs of employing the
                                    services of a credit collection agency to recover unpaid
                                    debts; or
                                </li>
                                <li>
    
                                    any claim for compensation in respect of damage or loss
                                    to the Vendor's goods; or
                                </li>
                                <li>
    
                                    a warning label or safety instructions having been
                                    removed, damaged or defaced where a product or fitting
                                    has been supplied to the Property with such a label or
                                    instruction attached.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    WARRANTY BY THE VENDOR
                    <ol>
                        <li>
                            The Vendor warrants to the Licensee that the Vendor has any
                            necessary authority to enter into this Agreement with the
                            Licensee.
                        </li>
                    </ol>
                </li>
                <li>
                    GST
                    <ol>
                        <li>
                            In this clause, GST Law has the meaning given in the A New
                            Tax System (Goods and Services Tax) Act 1999 (Cth), and
                            terms used which are not defined in this Agreement but which
                            are defined in the GST Law, have the meanings given in the
                            GST Law.
                        </li>
                        <li>
                            Unless stated otherwise, all consideration provided under or
                            referred to in this Agreement is stated as an amount that is
                            inclusive of GST, at the rate of 10%. If the rate of GST is
                            increased or decreased, the Vendor and the Licensee agree
                            that these GST inclusive amounts will be varied to reflect
                            that increase or decrease. The time of supply for the
                            purposes of the GST Law is the time when the consideration
                            is payable pursuant to this Agreement.
                        </li>
                        <li>
                            The Vendor must pay to the Licensee any GST payable in
                            respect of any taxable supply made by the Licensee to the
                            Vendor. Upon request by the Vendor, the Licensee will
                            provide a tax invoice in respect of any such taxable supply.
                        </li>
                    </ol>
                </li>
                <li>
                    PRIVACY NOTICE
                    <ol>
                        <li>
                            The Privacy Act 1988 (Cth) regulates the collection, use,
                            storage and disclosure of personal information of the Vendor
                            by the Licensee.
                        </li>
                        <li>
                            The information is collected by and pursuant to this
                            Agreement.
                        </li>
                        <li>
                            The information collected enables the Licensee to act for
                            and on behalf of the Vendor and to carry out effectively the
                            Licensee’s obligations under and pursuant to the terms of
                            this Agreement and to perform and promote the real estate
                            agency services of the Licensee. Some of the information is
                            required by law and without it the Licensee may not be able
                            to act for and on behalf of the Vendor.
                        </li>
                        <li>
                            The intended recipients of the information are any person to
                            whom, and any body or agency to which, it is usual to
                            disclose the information to enable the Licensee to perform
                            the services under or pursuant to this Agreement, real
                            estate agency services, or to otherwise act as permitted by
                            the Privacy Act 1988, including potential tenants, actual or
                            potential landlords, contractors (tradespeople), print and
                            electronic media, internet, State or Federal authorities, or
                            organisations (as well as owners’ corporations and community
                            associations).
                        </li>
                        <li>
                            The Vendor has the right to access the information and may
                            do so by contacting the Licensee. The Vendor has the right
                            to require correction of the information if it is not
                            accurate, up-to-date and complete.
                        </li>
                    </ol>
                </li>
                <li>
                    FINANCIAL AND INVESTMENT ADVICE
                    <ol>
                        <li>
                            WARNING: Any financial or investment advice provided to the
                            Vendor by the Licensee is general advice and does not take
                            into account the individual circumstances of the Vendor or
                            the Vendor’s objectives, financial situation or needs. The
                            Vendor must seek and rely on their own independent financial
                            and investment advice from an appropriately licensed
                            financial adviser.
                        </li>
                    </ol>
                </li>
                <li>
                    REBATES, DISCOUNTS, COMMISSIONS AND OTHER BENEFITS
                    <ol>
                        <li>
                            The Licensee has made a reasonable attempt to set out in
                            Item H of the Particulars any rebates, discounts,
                            commissions, or other benefits that the Licensee will or may
                            receive in respect of the expenses charged under this
                            Agreement, and the estimated amount of those rebates,
                            discounts, commissions, or other benefits (to the extent
                            that the amount can reasonably be estimated). The Vendor
                            agrees that the Licensee is entitled to retain all such
                            rebates, discounts, commissions, or other benefits.
                        </li>
                    </ol>
                </li>
                <li>
                    LIMIT OF LICENSEE’S SERVICES
                    <ol>
                        <li>
                            The Licensee will not undertake any other services in
                            connection with the sale of the Property, other than the
                            services listed in this Agreement.
                        </li>
                    </ol>
                </li>
                <li>
                    CONSTRUCTION OF THIS AGREEMENT, INCLUDING ADDITIONAL CLAUSES
                    <ol>
                        <li>
                            If a provision of this Agreement (including any amendments
                            to it, or any additional clauses or special conditions
                            inserted in it) is illegal or unenforceable (including as a
                            result of being found either to be uncertain, or to give
                            rise to uncertainty when read in conjunction with the
                            original text of this Agreement, or to not give rise to a
                            legally binding agreement), that provision may be severed
                            and the remainder of this Agreement will continue in force.
                        </li>
                    </ol>
                </li>
                <li>
                    ELECTRONIC SIGNATURES
                    <ol>
                        <li>
                            The Licensee and the Vendor agree that, by typing or
                            entering the text of their names where and when requested to
                            do so:
                            <ol type="a">
                                <li>
                                    they are acknowledging and warranting that by doing so,
                                    they are identifying themselves to each other (including
                                    identifying themselves, as applicable, as either offeror
                                    or offeree), for the purposes of the ETA or any other
                                    applicable law;
                                </li>
                                <li>
                                    they will have signed this Agreement or affixed their
                                    signature to it, for the purposes of the ETA or any
                                    other applicable law that requires this Agreement to be
                                    signed by the Licensee or the Vendor;
                                </li>
                                <li>
                                    this Agreement will thereby contain their electronic
                                    signature, for the purposes of the ETA or any other
                                    applicable law; (d)they will be expressing and
                                    confirming their immediate intention to be legally bound
                                    by this Agreement, which they acknowledge contains all
                                    of the terms of the agreement between them, and is the
                                    finalised form of the agreement between them;
                                </li>
                                <li>
                                    they are consenting to each other identifying
                                    themselves, signing this Agreement, and expressing their
                                    intentions as referred to in this clause, in this way;
                                </li>
                                <li>
                                    they are agreeing that this Agreement is in writing,
                                    that this Agreement has been signed by them, that their
                                    signature and other information contained in this
                                    Agreement has been given or provided in writing, and
                                    that nothing in the electronic format of this Agreement
                                    (including the method of signing it) affects the legally
                                    binding and enforceable nature of this Agreement; and
                                </li>
                                <li>
                                    they will be representing the matters in the previous
                                    sub-clause to one another, and in turn will be acting in
                                    reliance on each other’s representations to that same
                                    effect.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>
      </div>`,
    auctionTerms: `
    <div class="terms-condition">
    <div id="editable">  
        <h2>
            PART 3 | TERMS AND CONDITIONS OF EXCLUSIVE AUCTION AGENCY AGREEMENT AND CONTINUING AGENCY AGREEMENT
        </h2>
    
        <div class="terms">
            <ol type="1">
                <li>
                    DEFINITIONS
                    <ol>
                        <li>
                            "the Act" means the Property and Stock Agents Act 2002
                            (NSW).
                        </li>
                        <li>
                            "the ETA" means the Electronic Transactions Act 2000 (NSW).
                        </li>
                        <li>
                            "Agreement" means this Sales Inspection Report and Exclusive Auction Agency
                            Agreement and Continuing Agency Agreement, including the
                            terms and conditions set out in this Part 3.
                        </li>
                        <li>
                            "Government and other imposts" includes State and Federal
                            Taxes and any tax in the nature of a goods or services tax
                            and any other taxes or charges debited by banks or financial
                            institutions against the account of the Licensee in relation
                            to sale of the Property.
                        </li>
                        <li>
                            "Introduced" – A Purchaser shall be taken to have been
                            "Introduced" to the Property or the Vendor by the Licensee,
                            by any other agent, or by any other person (including the
                            Vendor):
                            <ol type="a">
                                <li>
                                    if the Purchaser becomes aware that the Property is
                                    available for sale as a result of reading any
                                    advertisement, notice or placard referring to the
                                    availability of the Property for sale, published or
                                    erected by, or in the name of, the Licensee or that
                                    other agent or that other person (including the Vendor);
                                    or
                                </li>
                                <li>
                                    if the fact that the Property is available for sale is
                                    otherwise made known to the Purchaser by or through the
                                    Licensee or that other agent or that other person
                                    (including the Vendor); or
                                </li>
                                <li>
                                    if the Licensee or that other agent or that other person
                                    (including the Vendor) is an effective cause of the
                                    Sale, whether or not the Sale agreement is entered into
                                    whilst this Agreement is on foot; and
                                </li>
                                <li>
                                    regardless of the fact that more than one person could
                                    be said to have Introduced the Purchaser to the Property
                                    or the Vendor, within the meaning of this clause; and
                                </li>
                                <li>
                                    regardless of whether the Purchaser is merely related to
                                    or known by the person who had the actual relevant
                                    dealings with the Licensee or that other agent or that
                                    other person (including the Vendor); and
                                </li>
                                <li>
                                    regardless of when the Purchaser came into existence or
                                    acquired legal personality or capacity.
                                </li>
                            </ol>
                        </li>
                        <li>
                            "Material Fact" is a fact that would be important to a
                            reasonable person in deciding whether or not to proceed with
                            a particular transaction or a matter prescribed by the Act
                            or clause 54 Property and Stock Agents Regulation.
                        </li>
                        <li>
                            "Person" includes a corporation or representative of a
                            corporation.
                        </li>
                        <li>
                            "Purchaser" includes a person who enters into a binding
                            agreement to purchase the Property (which includes the
                            exercising of an option, or entering into any agreement that
                            is in effect, if not by name or strict legal construction, a
                            purchase of the Property), whether or not it completes.
                        </li>
                        <li>
                            "Sale" includes a binding agreement to purchase the Property
                            (which includes the exercising of an option, or entering
                            into any agreement that is in effect, if not by name or
                            strict legal construction, a purchase of the Property),
                            whether or not it completes.
                        </li>
                        <li>
                            Words importing one gender include the other and singular
                            includes the plural and vice versa (e.g. “Vendor”, if
                            applicable, refers to the vendors, when two or more people
                            own the Property).
                        </li>
                        <li>
                            A reference to includes means includes but without
                            limitation.
                        </li>
                    </ol>
                </li>
                <li>
                    AGENCY
                    <ol>
                        <li>
                            The Vendor appoints the Licensee to sell the Property on
                            behalf of the Vendor as:
                            <ol type="a">
                                <li>
                                    exclusive selling agent for the sale of the Property,
                                    for the Exclusive Agency Period set out in Part 2, Item
                                    A; and (b)at the expiry of the Exclusive Agency Period,
                                    as non-exclusive selling agent for the sale of the
                                    Property for the <br />
                                    <span class="ml-1">
                                        Continuing Agency Period set out in Part 2, Item A.
                                    </span>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    RESERVE PRICE
                    <ol>
                        <li>
                            If the method of sale specified in Item B of the
                            Particulars is “Auction”, the Vendor authorises the
                            Licensee to:
                            <br />
                            (a) submit the Property for sale by public auction on a
                            date agreed with the Vendor, and to appoint an
                            auctioneer as the Vendor’s auctioneer; and <br />
                            (b) sell the Property at or above the reserve price
                            stated in Item C of the Particulars, or such other
                            reserve price that the Vendor approves.
                        </li>
                        <li>
                            If no reserve price is stated in Item C of the
                            Particulars, the Vendor must give the reserve price to
                            the auctioneer in writing prior to the commencement of
                            the auction (and by doing so is taken to have given
                            their approval for the purposes of clause 3.1(b)).
                        </li>
                        <li>
                            The Vendor may at any time authorise the Licensee to
                            sell the Property by private treaty
                        </li>
                    </ol>
                </li>
                <li>
                    LICENSEE’S REMUNERATION
                    <ol>
                        <li>
                            Remuneration - The Licensee is entitled to the Remuneration
                            set out in Item D of the Particulars ("the Remuneration") as
                            follows:-
                            <ol type="a">
                                <li>
                                    The Licensee will be entitled to the Commission set out
                                    in Item D of the Particulars ("the Commission") if,
                                    during the Exclusive Agency Period:
                                    <ol type="i">
                                        <li>
                                            the Purchaser is Introduced to the Property or the
                                            Vendor by the Licensee, by any other agent, or by
                                            any other person (including the Vendor), whether or
                                            not a Sale of the Property occurs whilst this
                                            Agreement is on foot; or
                                        </li>
                                        <li>
                                            there is a Sale of the Property, even if the Sale
                                            does not complete (and regardless of the cause of
                                            the Sale not completing).
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    The Licensee will be entitled to the Commission if,
                                    during the Continuing Agency Period:
                                    <ol type="i">
                                        <li>
                                            the Purchaser is Introduced to the Property or the
                                            Vendor by the Licensee, by an agent who is not
                                            lawfully appointed pursuant to the Act, or by any
                                            other person (including the Vendor), whether or not
                                            a Sale of the Property occurs whilst this Agreement
                                            is on foot; or
                                        </li>
                                        <li>
                                            there is a Sale of the Property other than a Sale
                                            resulting from the Purchaser being Introduced to the
                                            Property or the Vendor during the Continuing Agency
                                            Period by another agent lawfully appointed pursuant
                                            to the Act, even if the Sale does not complete (and
                                            regardless of the cause of the Sale not completing).
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    The Commission is due and payable by the Vendor to the
                                    Licensee immediately and in full when the Sale of the
                                    Property completes.
                                </li>
                                <li>
                                    If, after the Vendor and the Purchaser have entered into
                                    a binding agreement for the Sale of the Property:
                                    <ol type="i">
                                        <li>
                                            the Vendor and the Purchaser enter into a mutual
                                            agreement (whether written or verbal) to rescind the
                                            agreement or otherwise not proceed with the Sale; or
                                        </li>
                                        <li>
                                            the agreement is terminated as a result of the
                                            default of the Vendor; or
                                        </li>
                                        <li>
                                            the agreement is terminated as a result of the
                                            default of the Purchaser (regardless of the amount
                                            of the deposit which has been paid at the date of
                                            termination, and regardless of the amount of the
                                            deposit which is forfeited to or recoverable by the
                                            Vendor); or
                                        </li>
                                        <li>
                                            the Vendor does not proceed with the Sale for any
                                            other reason (including a postponement of the
                                            completion of the Sale for more than 30 days after
                                            the original completion date),
                                            <br />
                                            <span class="ml-2">
                                                the Commission will become due and payable by the
                                                Vendor to the Licensee immediately.
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    The Commission is calculated (as set out in Item D of
                                    the Particulars) on the selling price, and is inclusive
                                    of GST.
                                    <p class="ml-2">
                                        WARNING: The term immediately above provides that a
                                        commission is payable under this agreement even if the
                                        sale of the property is not completed.
                                    </p>
                                    <p class="ml-2">
                                        IMPORTANT: This is an exclusive agency agreement. This
                                        means you may have to pay the agent commission even if
                                        another agent (or you) sells the property or
                                        introduces a buyer who later buys the property.
                                    </p>
                                    <p class="ml-2">
                                        WARNING: Have you signed an agency agreement for the
                                        sale of this Property with another agent? If you have
                                        you may have to pay 2 commissions (if this agreement
                                        or the other agreement you have signed is a sole or
                                        exclusive agency agreement).
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Authority to Deduct – The Licensee may, upon receipt of a
                            direction from the Purchaser or their Legal Representative
                            authorising the Licensee to account to the Vendor for the
                            deposit, deduct from the deposit all or part of the
                            Remuneration, and all or part of the Expenses and Charges
                            that are payable to the Licensee pursuant to this Agreement,
                            up to the entire amount of the deposit.
                        </li>
                        <li>
                            Variation - The Remuneration provided for in this Agreement
                            (that is, both the Commission and the Other Services) cannot
                            be varied except as agreed in writing by the Vendor.
                        </li>
                    </ol>
                </li>
                <li>
                    EXPENSES AND CHARGES
                    <ol>
                        <li>
                            The Vendor must reimburse the Licensee for the expenses and
                            charges incurred and described in Item E of the Particulars.
                            Those services and amounts cannot be varied except with the
                            agreement in writing of the Vendor.
                        </li>
                        <li>
                            The reimbursement is due and payable as and when the Vendor
                            is notified by the Licensee that the expenses or charges
                            have been incurred.
                        </li>
                        <li>
                            The actual amount incurred is to be reimbursed (including
                            any additional GST which the Licensee is or becomes liable
                            to pay to the Commonwealth), even if it exceeds the estimate
                            (if any) that is given in Item E.
                        </li>
                        <li>
                            Variation - The Expenses and Charges provided for in this
                            Agreement cannot be varied except as agreed in writing by
                            the Vendor.
                        </li>
                    </ol>
                </li>
                <li>
                    ADVERTISING AND PROMOTION
                    <ol>
                        <li>
                            The Licensee will advertise or otherwise promote the
                            Property as set out in Item F of the Particulars.
                        </li>
                        <li>
                            If the Licensee is to pay any advertising or promotion
                            costs, they are to be included in Item E as “Expenses and
                            Charges”.
                        </li>
                        <li>
                            The Licensee is authorised to erect a For Sale sign at the
                            Property unless instructed differently.
                        </li>
                        <li>
                            The Vendor acknowledges that the Licensee is not responsible
                            for any liability, injury or damage incurred as a result of
                            the sign being erected at the Property.
                        </li>
                    </ol>
                </li>
                <li>
                    CONJUNCTION AGENTS
                    <ol>
                        <li>
                            The Vendor acknowledges that the Licensee is authorised to
                            act in conjunction with another licensed real estate agent
                            to market and sell the Property, however the Licensee is not
                            authorised to offer any payment to the conjunction agent
                            other than a payment that is made by, or from monies owing
                            to, the Licensee.
                        </li>
                        <li>
                            For the avoidance of doubt, and notwithstanding any other
                            provision in this Agreement:
                            <ol>
                                <li>
                                    the Licensee is forbidden to pay any conjunction fee or
                                    any other payment in the nature of a referral fee to a
                                    person who is not appropriately licensed under the Act
                                    or under any other legislation applicable to them;
                                </li>
                                <li>
                                    the use of a conjunction agent does not increase the
                                    amount of, or vary in any way, the Remuneration or the
                                    Expenses and Charges, unless expressly agreed in
                                    writing; and
                                </li>
                                <li>
                                    if during the Continuing Agency Period, a Sale of the
                                    Property results from the Licensee acting as a
                                    conjunction agent (as opposed to the Licensee using a
                                    conjunction agent), the Licensee is not entitled to be
                                    paid any of the Commission, but is permitted to accept
                                    payment from monies that are held by or owing to that
                                    other licensed agent.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    MATERIAL FACTS
                    <ol>
                        <li>
                            The Vendor acknowledges that pursuant to the Act and clause
                            54 Property and Stock Agents Regulation, the Licensee is
                            required to disclose all “Material Facts” relating to the
                            Property to any prospective or actual purchaser.
                        </li>
                        <li>
                            The Vendor warrants that they have provided to the Licensee
                            all information which may be considered a “Material Fact” in
                            relation to the Property and they have completed the
                            Material Fact disclosure document which forms part of this
                            Agreement.
                        </li>
                        <li>
                            The Vendor warrants that if they become aware of any further
                            information that may be considered a Material Fact after
                            entering into this Agreement they will immediately provide
                            that information to the Licensee.
                        </li>
                        <li>
                            The Vendor authorises and directs the Licensee to disclose
                            anything which may be a “Material Fact” in relation to the
                            Property to any actual or prospective purchaser of the
                            Property.
                        </li>
                        <li>
                            The Vendor indemnifies the Licensee against all actions,
                            claims and demands brought against, and all costs, losses
                            and liabilities incurred by the Licensee arising from or
                            connected with a failure on the part of the Vendor to
                            disclose a “Material Fact” or as a result, howsoever caused,
                            of the Vendor providing false, misleading or deceptive
                            information to the Licensee.
                        </li>
                    </ol>
                </li>
                <li>
                    DEPOSIT
                    <ol>
                        <li>
                            The Vendor agrees that any deposit paid in accordance with
                            an agreement for the sale of the Property will be held in
                            the Trust Account of the Licensee as stakeholder, as
                            directed by the parties, pending completion of the sale.
                        </li>
                    </ol>
                </li>
                <li>
                    FINANCIAL INSTITUTION TAXES OR DEDUCTIONS
                    <ol>
                        <li>
                            If the Licensee incurs any taxes or deductions debited by
                            banks or other financial institutions against the Licensee’s
                            account, that relate to the affairs of the Vendor, the
                            Licensee is entitled to be reimbursed for the charges it
                            incurs.
                        </li>
                    </ol>
                </li>
                <li>
                    PAYMENT TO THE VENDOR
                    <ol>
                        <li>
                            If any money that is held by the Licensee in respect of this
                            Agreement becomes due and payable to the Vendor, the Vendor
                            directs the Licensee to pay the money by cheque or
                            electronic funds transfer to the Vendor's bank account.
                        </li>
                    </ol>
                </li>
                <li>
                    APPROVED GUIDE
                    <ol>
                        <li>
                            The Vendor confirms that prior to (but no more than 1 month
                            prior to) the Vendor signing this Agreement, the Licensee
                            has provided the Vendor with a copy of the approved guide
                            entitled ‘Agency Agreements for the Sale of Residential
                            Property’.
                        </li>
                    </ol>
                </li>
                <li>
                    CONTRACT FOR SALE
                    <ol>
                        <li>
                            The Licensee must not offer the Property (if the Property is
                            a residential property), for sale unless a copy of the
                            proposed contract for the sale of the Property (including
                            all mandatory disclosure documents required by section 52A
                            of the Conveyancing Act 1919) is available for inspection by
                            prospective purchasers at the Licensee’s registered office.
                        </li>
                        <li>
                            The Vendor must provide to the Licensee a copy of the
                            contract for sale as soon as it is practicable.
                        </li>
                        <li>
                            The Licensee is not authorised to sign a contract for sale
                            on behalf of the Vendor.
                        </li>
                    </ol>
                </li>
                <li>
                    INDEMNITY
                    <ol>
                        <li>
                            The Agent having complied with its obligations under this
                            Agreement and not having been negligent, the Vendor
                            indemnifies the Agent, its officers and employees, from and
                            against all actions, claims, demands, losses, costs damages
                            and expenses arising out of this Agreement in respect of:
                            <br />
                            <ol type="i">
                                <li> authorised sales advertising and signage; or</li>
                                <li>
    
                                    the Vendor's failure to comply with this Agreement; or
                                </li>
                                <li>
    
                                    the Vendor's failure to give the Agent prompt and
                                    appropriate authority or instruction, or sufficient
                                    funds to carry out an instruction or authority; or
                                </li>
                                <li>
    
                                    the Agent acting on behalf of the Vendor under this
                                    Agreement; or
                                </li>
                                <li>
    
                                    the Agent incurring legal costs of employing the
                                    services of a credit collection agency to recover unpaid
                                    debts; or
                                </li>
                                <li>
    
                                    any claim for compensation in respect of damage or loss
                                    to the Vendor's goods; or
                                </li>
                                <li>
    
                                    a warning label or safety instructions having been
                                    removed, damaged or defaced where a product or fitting
                                    has been supplied to the Property with such a label or
                                    instruction attached.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    WARRANTY BY THE VENDOR
                    <ol>
                        <li>
                            The Vendor warrants to the Licensee that the Vendor has any
                            necessary authority to enter into this Agreement with the
                            Licensee.
                        </li>
                    </ol>
                </li>
                <li>
                    GST
                    <ol>
                        <li>
                            In this clause, GST Law has the meaning given in the A New
                            Tax System (Goods and Services Tax) Act 1999 (Cth), and
                            terms used which are not defined in this Agreement but which
                            are defined in the GST Law, have the meanings given in the
                            GST Law.
                        </li>
                        <li>
                            Unless stated otherwise, all consideration provided under or
                            referred to in this Agreement is stated as an amount that is
                            inclusive of GST, at the rate of 10%. If the rate of GST is
                            increased or decreased, the Vendor and the Licensee agree
                            that these GST inclusive amounts will be varied to reflect
                            that increase or decrease. The time of supply for the
                            purposes of the GST Law is the time when the consideration
                            is payable pursuant to this Agreement.
                        </li>
                        <li>
                            The Vendor must pay to the Licensee any GST payable in
                            respect of any taxable supply made by the Licensee to the
                            Vendor. Upon request by the Vendor, the Licensee will
                            provide a tax invoice in respect of any such taxable supply.
                        </li>
                    </ol>
                </li>
                <li>
                    PRIVACY NOTICE
                    <ol>
                        <li>
                            The Privacy Act 1988 (Cth) regulates the collection, use,
                            storage and disclosure of personal information of the Vendor
                            by the Licensee.
                        </li>
                        <li>
                            The information is collected by and pursuant to this
                            Agreement.
                        </li>
                        <li>
                            The information collected enables the Licensee to act for
                            and on behalf of the Vendor and to carry out effectively the
                            Licensee’s obligations under and pursuant to the terms of
                            this Agreement and to perform and promote the real estate
                            agency services of the Licensee. Some of the information is
                            required by law and without it the Licensee may not be able
                            to act for and on behalf of the Vendor.
                        </li>
                        <li>
                            The intended recipients of the information are any person to
                            whom, and any body or agency to which, it is usual to
                            disclose the information to enable the Licensee to perform
                            the services under or pursuant to this Agreement, real
                            estate agency services, or to otherwise act as permitted by
                            the Privacy Act 1988, including potential tenants, actual or
                            potential landlords, contractors (tradespeople), print and
                            electronic media, internet, State or Federal authorities, or
                            organisations (as well as owners’ corporations and community
                            associations).
                        </li>
                        <li>
                            The Vendor has the right to access the information and may
                            do so by contacting the Licensee. The Vendor has the right
                            to require correction of the information if it is not
                            accurate, up-to-date and complete.
                        </li>
                    </ol>
                </li>
                <li>
                    FINANCIAL AND INVESTMENT ADVICE
                    <ol>
                        <li>
                            WARNING: Any financial or investment advice provided to the
                            Vendor by the Licensee is general advice and does not take
                            into account the individual circumstances of the Vendor or
                            the Vendor’s objectives, financial situation or needs. The
                            Vendor must seek and rely on their own independent financial
                            and investment advice from an appropriately licensed
                            financial adviser.
                        </li>
                    </ol>
                </li>
                <li>
                    REBATES, DISCOUNTS, COMMISSIONS AND OTHER BENEFITS
                    <ol>
                        <li>
                            The Licensee has made a reasonable attempt to set out in
                            Item H of the Particulars any rebates, discounts,
                            commissions, or other benefits that the Licensee will or may
                            receive in respect of the expenses charged under this
                            Agreement, and the estimated amount of those rebates,
                            discounts, commissions, or other benefits (to the extent
                            that the amount can reasonably be estimated). The Vendor
                            agrees that the Licensee is entitled to retain all such
                            rebates, discounts, commissions, or other benefits.
                        </li>
                    </ol>
                </li>
                <li>
                    LIMIT OF LICENSEE’S SERVICES
                    <ol>
                        <li>
                            The Licensee will not undertake any other services in
                            connection with the sale of the Property, other than the
                            services listed in this Agreement.
                        </li>
                    </ol>
                </li>
                <li>
                    CONSTRUCTION OF THIS AGREEMENT, INCLUDING ADDITIONAL CLAUSES
                    <ol>
                        <li>
                            If a provision of this Agreement (including any amendments
                            to it, or any additional clauses or special conditions
                            inserted in it) is illegal or unenforceable (including as a
                            result of being found either to be uncertain, or to give
                            rise to uncertainty when read in conjunction with the
                            original text of this Agreement, or to not give rise to a
                            legally binding agreement), that provision may be severed
                            and the remainder of this Agreement will continue in force.
                        </li>
                    </ol>
                </li>
                <li>
                    ELECTRONIC SIGNATURES
                    <ol>
                        <li>
                            The Licensee and the Vendor agree that, by typing or
                            entering the text of their names where and when requested to
                            do so:
                            <ol type="a">
                                <li>
                                    they are acknowledging and warranting that by doing so,
                                    they are identifying themselves to each other (including
                                    identifying themselves, as applicable, as either offeror
                                    or offeree), for the purposes of the ETA or any other
                                    applicable law;
                                </li>
                                <li>
                                    they will have signed this Agreement or affixed their
                                    signature to it, for the purposes of the ETA or any
                                    other applicable law that requires this Agreement to be
                                    signed by the Licensee or the Vendor;
                                </li>
                                <li>
                                    this Agreement will thereby contain their electronic
                                    signature, for the purposes of the ETA or any other
                                    applicable law; (d)they will be expressing and
                                    confirming their immediate intention to be legally bound
                                    by this Agreement, which they acknowledge contains all
                                    of the terms of the agreement between them, and is the
                                    finalised form of the agreement between them;
                                </li>
                                <li>
                                    they are consenting to each other identifying
                                    themselves, signing this Agreement, and expressing their
                                    intentions as referred to in this clause, in this way;
                                </li>
                                <li>
                                    they are agreeing that this Agreement is in writing,
                                    that this Agreement has been signed by them, that their
                                    signature and other information contained in this
                                    Agreement has been given or provided in writing, and
                                    that nothing in the electronic format of this Agreement
                                    (including the method of signing it) affects the legally
                                    binding and enforceable nature of this Agreement; and
                                </li>
                                <li>
                                    they will be representing the matters in the previous
                                    sub-clause to one another, and in turn will be acting in
                                    reliance on each other’s representations to that same
                                    effect.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>
      </div>`,
  };

  const handleSaveMarketingData = async (selectedItems) => {
    setValue("marketing", selectedItems); // Save selected items to form
    await axios.put("/userProperty", {
      address: property.address,
      marketing: selectedItems,
    });
    // setMarketingTable(false); // Close the modal
  };

  vendorsData.forEach((vendor) => {
    // Log vendor details being added to defaultValues
    console.log(`Processing Vendor ID: ${vendor.id}`, vendor);

    defaultValues[`${vendor.id}`] = {
      firstName: vendor.firstName,
      lastName: vendor.lastName,
      email: vendor.email,
      mobile: vendor.mobile,
      address: vendor.address,
      licence: vendor.licence,
      signature: vendor.signature,
      isCompany: vendor.isCompany || false,
      company: vendor.company || "",
      abn: vendor.abn || "",
      gst: vendor.gst || "",
      agreeTerms: vendor.agreeTerms || false,
      sentDate: formatDateToAEDT(null),
      viewedDate: formatDateToAEDT(null),
    };
  });

  // Initialize useForm with defaultValues
  const {
    register,
    handleSubmit,
    control, // Required for useFieldArray
    formState: { errors, isValid },
    setValue,
    watch,
    trigger,
    reset,
    unregister,
    getValues,
  } = useForm({
    defaultValues,
    mode: "onChange", // Enable real-time validation
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "vendorFollowers",
  });

  // Update the form values when fetchedData changes
  useEffect(() => {
    if (fetchedData) {
      reset({
        ...defaultValues, // Preserve other default values
        terms: fetchedData?.terms || "30",
        prepareMarketing: fetchedData?.prepareMarketing || "ASAP",
        status: fetchedData?.status || "Vacant possession",
        fraudPrevention: fetchedData?.fraudPrevention || "Yes",
        saleProcess:
          fetchedData?.saleProcess ||
          (property?.recommendedSaleProcess?.includes("Auction")
            ? "Auction"
            : "Private Treaty"),
        startPrice:
          fetchedData?.startPrice ||
          parseLogicalPrice(property.logicalPrice) ||
          "",
        endPrice: fetchedData?.endPrice || "",
        commissionFee: fetchedData?.commissionFee || "",
        commissionRange: fetchedData?.commissionRange || "",
        commissionNotes:fetchedData?.commissionNotes || "",
        vendors: vendorsData,
        marketing: fetchedData?.marketing || property?.marketing || "",
        vendorFollowers: fetchedData?.vendorFollowers || [],
        followers: fetchedData?.followers || property?.followers || [], // Initialize followers
        propertyAddress: property?.address,
        solicitorAddress: fetchedData?.solicitor?.address || "",
        solicitorName: fetchedData?.solicitor?.name || "",
        solicitorEmail: fetchedData?.solicitor?.email || "",
        solicitorMobile: fetchedData?.solicitor?.mobile || "",
        conclusionDate: fetchedData?.conclusionDate || "",
        access: fetchedData?.access || "",
        serviceBuilding: fetchedData?.services?.[0]?.value || "",
        serviceStrata: fetchedData?.services?.[1]?.value || "",
        serviceStyling: fetchedData?.services?.[2]?.value || "",
      });
      setVendors(vendorsData);
    }
  }, [fetchedData, reset]);

  // Initialize useFieldArray for marketingItems
  // const { fields, append, remove, update } = useFieldArray({
  //   control,
  //   name: "marketing.marketingItems",
  // });

  // Initialize useFieldArray for recommendedSales
  const {
    fields: recommendedSales,
    append: appendRecommendedSales,
    remove: removeRecommendedSales,
    update: updateRecommendedSales,
  } = useFieldArray({
    control,
    name: "recommendedSales",
  });

  // Initialize useFieldArray for recommendedSold
  const {
    fields: recommendedSold,
    append: appendRecommendedSold,
    remove: removeRecommendedSold,
    update: updateRecommendedSold,
  } = useFieldArray({
    control,
    name: "recommendedSold",
  });

  // State to store events
  const [events, setEvents] = useState([]);
  // Register events field
  register("events");

  // Sync events with React Hook Form
  useEffect(() => {
    setValue("events", events); // Update the form's events field whenever events state changes
  }, [events, setValue]);

  const [eventsLoading, setEventsLoading] = useState(false);

  // Watch the specific fields
  const prepareMarketing = watch("prepareMarketing");
  const conclusionDate = watch("conclusionDate");
  const marketing = watch("marketing");
  const saleProcess = watch("saleProcess");

  useEffect(() => {
    if (conclusionDate && !isCompleted) {
      const fetchEvents = async () => {
        try {
          setEventsLoading(true);
          const response = await axios.post(`/events/calEventst`, {
            prepareMarketing,
            conclusionDate,
            marketing,
            saleProcess,
            address: property.address,
            waterViews: property.waterViews,
            finishes: property.finishes,
            lat: property.latitude,
            lon: property.longitude,
          });

          if (response.data.success) {
            const fetchedEvents = response.data.data;

            // Sort fetched events by start time
            fetchedEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

            // Filter out existing manual events
            setEvents((prevEvents) => {
              const manualEvents = prevEvents.filter(
                (event) => event.type === "manual"
              );
              return [...manualEvents, ...fetchedEvents];
            });
          }
        } catch (error) {
          console.error("Error fetching events:", error);
        } finally {
          setEventsLoading(false);
        }
      };

      fetchEvents();
    }
  }, [prepareMarketing, conclusionDate, marketing, saleProcess]);

  // Set vendors state
  const [vendors, setVendors] = useState(vendorsData);
  const [marketingTable, setMarketingTable] = useState(false);

  // If property.vendorDetails may change or load asynchronously, reset the form when it changes
  useEffect(() => {
    if (property.vendorDetails) {
      reset(defaultValues); // Reset the form with new default values
      setVendors(vendorsData); // Update vendors state
    }
  }, [property.vendorDetails]);

  // Initialize vendorStatus with the first vendor
  const [vendorStatus, setVendorStatus] = useState({
    1: { isScanning: false, isValidLicence: null },
  });

  const fileInputRefs = useRef({}); // References to file inputs for each vendor

  // **New State to Track Current Vendor ID for Upload**
  const [currentVendorId, setCurrentVendorId] = useState(null);

  // Watch price and commission fields
  const startPrice = watch("startPrice") || "";
  const endPrice = watch("endPrice") || "";
  const commissionFee = watch("commissionFee") || "";
  const commissionRange = watch("commissionRange") || "";

  // Function to format number with commas
  const formatWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Function to calculate commission
  const calculateCommission = (price, feePercentage) => {
    const priceValue = parseFloat(price.replace(/[\$,]/g, ""));
    const feeValue = parseFloat(feePercentage);
    if (isNaN(priceValue) || isNaN(feeValue)) {
      return null;
    }
    return (priceValue * feeValue) / 100;
  };

  // useEffect to recalculate commission range whenever startPrice or commissionFee changes
  useEffect(() => {
    const commissionStart = calculateCommission(startPrice, commissionFee);
    const commissionEnd = calculateCommission(endPrice, commissionFee);

    let newCommissionRange = "";
    if (commissionStart !== null) {
      if (commissionEnd !== null) {
        newCommissionRange = `$${formatWithCommas(
          commissionStart.toFixed(0)
        )} - $${formatWithCommas(commissionEnd.toFixed(0))}`;
      } else {
        newCommissionRange = `$${formatWithCommas(commissionStart.toFixed(0))}`;
      }
    } else {
      newCommissionRange = "";
    }

    // Update form state
    setValue("commissionRange", newCommissionRange);
    // setValue("commissionRange", newCommissionRange, { shouldValidate: true });
  }, [startPrice, endPrice, commissionFee, setValue]);

  useEffect(() => {
    // Ensure startPrice is defined and not empty
    if (startPrice && startPrice.trim() !== "") {
      let value = startPrice.replace(/[^0-9]/g, ""); // Remove non-numeric characters
      if (value) {
        const originalPrice = parseFloat(value);
        const newPrice = originalPrice * 1.1; // Add 10%
        const formattedEndPrice = `$${formatWithCommas(newPrice.toFixed(0))}`;

        // Update endPrice only if it has changed
        const currentEndPrice = getValues("endPrice"); // Retrieve current endPrice value
        if (currentEndPrice !== formattedEndPrice) {
          setValue("endPrice", formattedEndPrice);
        }
      } else {
        setValue("endPrice", ""); // Clear the value if startPrice is invalid
      }
    }
  }, [startPrice, setValue, getValues]);

  // Add a new vendor
  const addVendor = () => {
    const newVendorId =
      vendors.length > 0 ? vendors[vendors.length - 1].id + 1 : 1;
    setVendors((prevVendors) => [
      ...prevVendors,
      {
        id: newVendorId,
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        address: "",
        licence: "",
        signature: "",
        isCompany: false,
        company: "",
        abn: "",
        gst: false,
        agreeTerms: false,
        sentDate: formatDateToAEDT(null),
        viewedDate: formatDateToAEDT(null),
      },
    ]);
    setVendorStatus((prevStatus) => ({
      ...prevStatus,
      [newVendorId]: { isScanning: false, isValidLicence: null },
    }));
  };

  // Delete a vendor by id
  const deleteVendor = (id) => {
    if (vendors.length > 1) {
      unregister(`${id}`);
      setVendors((prevVendors) =>
        prevVendors.filter((vendor) => vendor.id !== id)
      );
      setVendorStatus((prevStatus) => {
        const newStatus = { ...prevStatus };
        delete newStatus[id];
        return newStatus;
      });
    }
  };

  // **Modified handleLicenceUpload to Associate with Current Vendor**
  const handleLicenceUpload = (e) => {
    const file = e.target.files[0];
    if (file && currentVendorId !== null) {
      // Validate file type
      if (!file.type.startsWith("image/")) {
        alert("Please upload a valid image file.");
        e.target.value = null;
        return;
      }

      // Validate file size (e.g., max 20MB)
      const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
      if (file.size > maxSizeInBytes) {
        alert("File size exceeds 20MB.");
        e.target.value = null;
        return;
      }

      // Set scanning status to true and reset validation
      setVendorStatus((prevStatus) => ({
        ...prevStatus,
        [currentVendorId]: { isScanning: true, isValidLicence: null },
      }));

      const reader = new FileReader();
      reader.onloadend = async () => {
        const formData = new FormData();
        formData.append("systemPrompt", "AUTH_SCHEDULE_LICENCE_SCAN");
        formData.append(
          "userMessage",
          "Uploaded Image. Check valid licence image or not. If valid extract details as mentioned."
        );
        formData.append("files", file);

        try {
          const response = await axios.post("/openai/image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          const data = response.data.data;

          if (data.isLicence === true) {
            if (data.firstName) {
              setValue(`${currentVendorId}.firstName`, data.firstName);
            }
            if (data.lastName) {
              setValue(`${currentVendorId}.lastName`, data.lastName);
            }
            if (data.address) {
              setValue(`${currentVendorId}.address`, data.address);
            }

            // setValue(`${currentVendorId}.licence`, reader.result);
            setValue(`${currentVendorId}.licence`, reader.result, {
              shouldValidate: true,
            });
            trigger(`${currentVendorId}.licence`);

            // Update vendorStatus
            setVendorStatus((prevStatus) => ({
              ...prevStatus,
              [currentVendorId]: { isScanning: false, isValidLicence: true },
            }));
            // Reset the file input's value to allow uploading the same file again
            e.target.value = null;
          } else {
            // Update vendorStatus to reflect invalid licence
            setVendorStatus((prevStatus) => ({
              ...prevStatus,
              [currentVendorId]: { isScanning: false, isValidLicence: false },
            }));
            // Reset the file input's value to allow uploading the same file again
            e.target.value = null;
          }
        } catch (error) {
          console.error("Error uploading image:", error);
          alert("There was an error uploading the image. Please try again.");
          // Update vendorStatus to reflect error (could add an error field if needed)
          setVendorStatus((prevStatus) => ({
            ...prevStatus,
            [currentVendorId]: { isScanning: false, isValidLicence: null },
          }));
          e.target.value = null;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // **Modified triggerFileInput to Accept Vendor ID**
  const triggerFileInput = (vendorId) => {
    setCurrentVendorId(vendorId);
    if (!fileInputRefs.current[vendorId]) {
      fileInputRefs.current[vendorId] = React.createRef();
    }
    fileInputRefs.current[vendorId].click();
  };

  const handleSaveSignature = (signature, vendorId) => {
    setValue(`${vendorId}.signature`, signature, { shouldValidate: true });
    trigger(`${vendorId}.signature`);
  };

  // const handleResetSignature = (vendorId) => {
  //   setValue(`${vendorId}.signature`, null, { shouldValidate: true });
  //   trigger(`${vendorId}.signature`);
  // };

  const [showAccessOtherInput, setShowAccessOtherInput] = useState(false);

  const handleAccessChange = async (e) => {
    const value = e.target.value;
    setValue("access", value, { shouldValidate: true }); // Set value in form with validation

    if (value === "Tenanted" || value === "Other") {
      setShowAccessOtherInput(true);
    } else {
      setShowAccessOtherInput(false);
      setValue("accessOther", ""); // Reset accessOther if the field is hidden
    }

    // Trigger validation to update any error messages
    trigger("access");
  };

  // Callback functions to add, edit, or delete recommended sales/sold items
  const handleAddRecommendedSales = (item) => {
    appendRecommendedSales(item);
  };

  const handleUpdateRecommendedSales = (index, updatedItem) => {
    updateRecommendedSales(index, updatedItem);
  };

  const handleDeleteRecommendedSales = (index) => {
    removeRecommendedSales(index);
  };

  const handleAddRecommendedSold = (item) => {
    appendRecommendedSold(item);
  };

  const handleUpdateRecommendedSold = (index, updatedItem) => {
    updateRecommendedSold(index, updatedItem);
  };

  const handleDeleteRecommendedSold = (index) => {
    removeRecommendedSold(index);
  };

  // Automatically set and lock conclusionDate to "Off market" if prepareMarketing is "Off market"
  useEffect(() => {
    if (prepareMarketing === "Off market") {
      setValue("conclusionDate", "Off market");
    }
  }, [prepareMarketing, setValue]);

  const onTagsChange = (tags) => {
    setValue("followers", tags); // Update the followers field in the form
  };

  const [saveLoading, setSaveLoading] = useState(false);
  const saveForLater = async () => {
    const getFormattedData = () => {
      const data = getValues();

      const formattedVendors = vendors.map((vendor) => {
        const vendorKey = `${vendor.id}`; // Key to access vendor data in 'data'

        // Fetch vendor data from form values
        const vendorData = {
          firstName: data[vendorKey]?.firstName || "",
          lastName: data[vendorKey]?.lastName || "",
          email: data[vendorKey]?.email || "",
          mobile: data[vendorKey]?.mobile || "",
          address: data[vendorKey]?.address || "",
          isCompany: data[vendorKey]?.isCompany || false,
          company: data[vendorKey]?.company || "",
          abn: data[vendorKey]?.abn || "",
          gst: data[vendorKey]?.gst || false,
        };
        return vendorData;
      });

      // Assemble the final formatted data
      return {
        propertyId,
        propertyAddress: data.propertyAddress,
        vendors: formattedVendors,
        vendorFollowers: data.vendorFollowers,
        followers: data.followers,
        solicitor: {
          name: data.solicitorName,
          email: data.solicitorEmail,
          mobile: data.solicitorMobile,
          address: data.solicitorAddress,
        },
        status: data.status,
        fraudPrevention: data.fraudPrevention,
        terms: data.terms,
        prepareMarketing: data.prepareMarketing,
        saleProcess: data.saleProcess,
        startPrice: data.startPrice,
        endPrice: data.endPrice,
        commissionFee: data.commissionFee,
        commissionRange: data.commissionRange,
        commissionNotes: data.commissionNotes,
        marketing: data.marketing,
        recommendedSold: data.recommendedSold,
        recommendedSales: data.recommendedSales,
        agentSignature,
        privateTerms: data.privateTerms,
        auctionTerms: data.auctionTerms,
        access:
          data.access === "Tenanted" && data.accessOther
            ? `Tenanted - ${data.accessOther}`
            : data.access !== "Other"
            ? data.access
            : data.accessOther,
        services: [
          {
            name: "serviceBuilding",
            price: "Upfront $55",
            value: data.serviceBuilding,
          },
          {
            name: "serviceStrata",
            price: "Upfront $50 (homesafe)",
            value: data.serviceStrata,
          },
          {
            name: "serviceStyling",
            price: "",
            value: data.serviceStyling,
          },
        ],
        termsCondition:
          data.saleProcess === "Auction"
            ? data.auctionTerms
            : data.privateTerms,
        conclusionDate: data.conclusionDate,
      };
    };
    const formattedData = getFormattedData();

    try {
      setSaveLoading(true);
      const response = await axios.post(
        "/authSchedule/saveForLater",
        formattedData
      );
      if (response.data.success) {
        showToast("success", "Saved successfully");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      showToast("error", "Error saving");
    } finally {
      setSaveLoading(false);
    }
  };

  if (pageLoading) {
    return <PageLoader />;
  }

  if (isLock && !isCompleted) {
    return (
      <AuthoriseScheduleLock
        data={{ ...fetchedData, suburb: property.suburb }}
      />
    );
  }

  if (isCompleted) {
    return <AuthoriseScheduleComplete fetchedData={fetchedData} />;
  }

  return (
    <div className="w-full h-full max-w-4xl mx-auto flex flex-col justify-between authorise-form px-4 pb-8">
      <p className="mb-4 text-darkgray text-center">{property.address}</p>

      <div className="flex justify-end mb-4">
        <Button
          className="black-button"
          onClick={saveForLater}
          loading={saveLoading}
          disabled={saveLoading}
        >
          Save for later
        </Button>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          {/* Sale Process Selection */}
          <select
            className={`w-1/2 form-select border ${
              errors.saleProcess ? "border-red-500" : "border-mediumgray"
            }`}
            {...register("saleProcess", {
              required: "Sale process selection is required",
            })}
          >
            {["Private Treaty", "Auction"].map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors.saleProcess && (
            <span className="form-error-message">
              {errors.saleProcess.message}
            </span>
          )}

          <div className="flex flex-col">
            <Button
              className="gray-button"
              onClick={() => setAddFollowers(true)}
            >
              Add Followers
            </Button>

            {/* Display error message if there are validation errors for vendorFollowers */}
            {errors?.vendorFollowers && (
              <span className="form-error-message text-end">
                Followers required
              </span>
            )}
          </div>
        </div>

        {/* Vendor Information */}
        {vendors.map((vendor, index) => {
          const licence = watch(`${vendor.id}.licence`);
          const status = vendorStatus[vendor.id] || {
            isScanning: false,
            isValidLicence: null,
          };
          const isCompany = watch(`${vendor.id}.isCompany`);

          return (
            <div key={vendor.id} className="mb-4">
              <div
                id="vendor-info"
                className="col-span-12 grid grid-cols-12 gap-4"
              >
                <div className="col-span-12 flex justify-between">
                  <div className="flex items-center gap-4">
                    <label className="form-label m-0">Vendor</label>
                    <div className="flex cursor-pointer gap-4">
                      {index === 0 && (
                        <div className="flex justify-end">
                          <FaPlus onClick={addVendor} />
                        </div>
                      )}
                      {vendors.length > 1 && (
                        <FiMinus onClick={() => deleteVendor(vendor.id)} />
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={isCompany}
                      onChange={(e) =>
                        setValue(`${vendor.id}.isCompany`, e.target.checked)
                      }
                      className="p-0 cursor-pointer"
                    />
                    Company
                  </div>
                </div>

                {/* Licence Upload Section */}
                <div className="col-span-12 flex justify-between items-center">
                  <div className="flex items-center gap-4">
                    <label>Upload Driver Licence</label>
                    <FaPlus
                      onClick={() => triggerFileInput(vendor.id)}
                      className="cursor-pointer"
                    />
                    {/* Hidden File Input */}
                    <input
                      type="file"
                      ref={(el) => (fileInputRefs.current[vendor.id] = el)}
                      onChange={handleLicenceUpload}
                      className="hidden"
                      accept="image/*"
                    />

                    <input
                      type="hidden"
                      {...register(`${vendor.id}.licence`, {
                        required: "Licence is required",
                      })}
                    />
                    {errors?.[`${vendor.id}`]?.licence && (
                      <span className="form-error-message text-start pt-1">
                        {errors?.[`${vendor.id}`]?.licence.message}
                      </span>
                    )}
                  </div>

                  {/* Conditionally Render Scanning or Validation Messages */}
                  <div className="flex items-center gap-2">
                    {status.isScanning && (
                      <>
                        <i className="fa-solid fa-spinner animate-spin"></i>
                        <span>Scanning image...</span>
                      </>
                    )}
                    {!status.isScanning && status.isValidLicence === false && (
                      <span className="text-red-500">Not a valid licence</span>
                    )}
                    {licence && status.isValidLicence === true && (
                      <div className="col-span-12 flex items-center justify-between gap-2">
                        <span>Licence Uploaded!</span>

                        <i
                          className="fa-solid fa-trash cursor-pointer"
                          onClick={() => {
                            unregister(`${vendor.id}.licence`);
                            setValue(`${vendor.id}.licence`, null);
                            setVendorStatus((prevStatus) => ({
                              ...prevStatus,
                              [vendor.id]: {
                                ...prevStatus[vendor.id],
                                isValidLicence: null,
                              },
                            }));
                          }}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-6">
                  <input
                    type="text"
                    className={`form-input border ${
                      errors?.[`${vendor.id}`]?.firstName
                        ? "border-red-500"
                        : "border-mediumgray"
                    }`}
                    {...register(`${vendor.id}.firstName`, {
                      required: "First Name is required",
                    })}
                    placeholder="FIRST NAME"
                  />
                  {errors?.[`${vendor.id}`]?.firstName && (
                    <span className="form-error-message text-start pt-1">
                      {errors?.[`${vendor.id}`]?.firstName.message}
                    </span>
                  )}
                </div>

                <div className="col-span-6">
                  <input
                    type="text"
                    className={`form-input border ${
                      errors?.[`${vendor.id}`]?.lastName
                        ? "border-red-500"
                        : "border-mediumgray"
                    }`}
                    {...register(`${vendor.id}.lastName`, {
                      required: "Last Name is required",
                    })}
                    placeholder="LAST NAME"
                  />
                  {errors?.[`${vendor.id}`]?.lastName && (
                    <span className="form-error-message text-start pt-1">
                      {errors?.[`${vendor.id}`]?.lastName.message}
                    </span>
                  )}
                </div>

                <div className="col-span-12">
                  <input
                    type="email"
                    className={`form-input border ${
                      errors?.[`${vendor.id}`]?.email
                        ? "border-red-500"
                        : "border-mediumgray"
                    }`}
                    {...register(`${vendor.id}.email`, {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Email is not valid",
                      },
                    })}
                    placeholder="EMAIL"
                  />
                  {errors?.[`${vendor.id}`]?.email && (
                    <span className="form-error-message text-start pt-1">
                      {errors?.[`${vendor.id}`]?.email.message}
                    </span>
                  )}
                </div>

                <div className="col-span-12 ">
                  <input
                    type="text"
                    className={`form-input border ${
                      errors?.[`${vendor.id}`]?.mobile
                        ? "border-red-500"
                        : "border-mediumgray"
                    }`}
                    {...register(`${vendor.id}.mobile`, {
                      required: "Mobile is required",
                      pattern: {
                        value:
                          /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
                        message: "Mobile number is not valid",
                      },
                    })}
                    placeholder="MOBILE"
                  />
                  {errors?.[`${vendor.id}`]?.mobile && (
                    <span className="form-error-message text-start pt-1">
                      {errors?.[`${vendor.id}`]?.mobile.message}
                    </span>
                  )}
                </div>

                <div className="col-span-12">
                  <input
                    type="text"
                    className={`form-input border ${
                      errors?.[`${vendor.id}`]?.address
                        ? "border-red-500"
                        : "border-mediumgray"
                    }`}
                    {...register(`${vendor.id}.address`, {
                      required: "Address is required",
                    })}
                    placeholder="ADDRESS"
                  />
                  {errors?.[`${vendor.id}`]?.address && (
                    <span className="form-error-message text-start pt-1">
                      {errors?.[`${vendor.id}`]?.address.message}
                    </span>
                  )}
                </div>

                {isCompany && (
                  <>
                    {/* Company Name Input */}
                    <div className="col-span-12">
                      <input
                        type="text"
                        className={`form-input border ${
                          errors?.[`${vendor.id}`]?.company
                            ? "border-red-500"
                            : "border-mediumgray"
                        }`}
                        {...register(`${vendor.id}.company`, {
                          required: "Company Name is required",
                        })}
                        placeholder="COMPANY"
                      />
                      {errors?.[`${vendor.id}`]?.company && (
                        <span className="form-error-message text-start pt-1">
                          {errors?.[`${vendor.id}`]?.company.message}
                        </span>
                      )}
                    </div>

                    {/* ABN Input */}
                    <div className="col-span-12">
                      <input
                        type="text"
                        className={`form-input border ${
                          errors?.[`${vendor.id}`]?.abn
                            ? "border-red-500"
                            : "border-mediumgray"
                        }`}
                        {...register(`${vendor.id}.abn`, {
                          required: "ABN is required",
                        })}
                        placeholder="ABN"
                      />
                      {errors?.[`${vendor.id}`]?.abn && (
                        <span className="form-error-message text-start pt-1">
                          {errors?.[`${vendor.id}`]?.abn.message}
                        </span>
                      )}
                    </div>

                    {/* GST Checkbox */}
                    <div className="col-span-12 flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={watch(`${vendor.id}.gst`)}
                        onChange={(e) =>
                          setValue(`${vendor.id}.gst`, e.target.checked)
                        }
                        className="p-0 cursor-pointer"
                      />
                      Registered for GST
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}

        {/* Solicitor Information */}
        <div className="col-span-12 grid grid-cols-12 gap-3">
          <label className="form-label m-0">Solicitor</label>
          <div className="col-span-12">
            <SolicitorSearch
              register={register}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
            />
          </div>

          <div className="col-span-12 ">
            <input
              type="text"
              className={`form-input border ${
                errors.solicitorName ? "border-red-500" : "border-mediumgray"
              }`}
              {...register(`solicitorName`, {
                required: "Name is required",
              })}
              placeholder="NAME"
            />
            {errors.solicitorName && (
              <span className="form-error-message text-start pt-1">
                {errors.solicitorName.message}
              </span>
            )}
          </div>
          <div className="col-span-12">
            <input
              type="email"
              className={`form-input border ${
                errors.solicitorEmail ? "border-red-500" : "border-mediumgray"
              }`}
              {...register(`solicitorEmail`, {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Email is not valid",
                },
              })}
              placeholder="EMAIL"
            />
            {errors.solicitorEmail && (
              <span className="form-error-message text-start pt-1">
                {errors.solicitorEmail.message}
              </span>
            )}
          </div>

          <div className="col-span-12 ">
            <input
              type="text"
              className={`form-input border ${
                errors.solicitorMobile ? "border-red-500" : "border-mediumgray"
              }`}
              {...register(`solicitorMobile`, {
                required: "Mobile is required",
              })}
              placeholder="MOBILE"
            />
            {errors.solicitorMobile && (
              <span className="form-error-message text-start pt-1">
                {errors.solicitorMobile.message}
              </span>
            )}
          </div>
        </div>

        <div className="col-span-12 grid grid-cols-12 gap-3">
          <div className="col-span-6 text-start">
            <label className="form-label">Status</label>

            <select
              className={`form-select border ${
                errors.status ? "border-red-500" : "border-mediumgray"
              }`}
              {...register("status", {
                required: "Status is required",
              })}
              defaultValue="Vacant possession"
            >
              <option value="">Select Status</option>

              {["Vacant possession", "Subject to existing tenancies"].map(
                (option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                )
              )}
            </select>
            {errors.status && (
              <span className="form-error-message">
                {errors.status.message}
              </span>
            )}
          </div>
          <div className="col-span-6 text-start">
            <label className="form-label">Terms</label>

            <div className="flex items-center gap-2">
              <select
                className={`form-select border ${
                  errors.terms ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("terms", {
                  required: "Terms is required",
                })}
                defaultValue="30"
              >
                <option value="">Select Terms</option>
                {["10", "20", "30", "40", "50", "60", "70", "80", "90"].map(
                  (option, idx) => (
                    <option key={idx} value={option}>
                      {option}
                    </option>
                  )
                )}
              </select>
              <span>days</span>
            </div>
            {errors.terms && (
              <span className="form-error-message">{errors.terms.message}</span>
            )}
            <div className="flex items-center gap-2 relative">
              <p className="italic">*1 day termination</p>{" "}
              <Tooltip
                className="w-[150px]"
                text={<i className="fa fa-info-circle text-xs"></i>}
                tooltip="Termination clause: The vendor may terminate this agreement at any time with one (1) days notice."
              />
            </div>
          </div>
        </div>

        <div className="col-span-12 grid grid-cols-12 gap-3">
          <div className="col-span-6 text-start">
            <div className="flex items-start gap-2">
              <label className="form-label">Price & Fee</label>
              <Tooltip
                className="w-[200px]"
                text={<i className="fa fa-info-circle text-xs"></i>}
                tooltip="This price is the agent’s estimated selling price, determined through a logical assessment of recent nearby sales. The vendor has no obligation to sell the property."
              />
            </div>

            <PriceInput
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
            />

            <div className="flex items-center gap-2 justify-start relative">
              <input
                type="text"
                className={`form-input rounded-none border border-t-0 border-b-0 border-mediumgray`}
                {...register("commissionFee", {
                  required: "Commission fee is required",
                  validate: {
                    validNumber: (value) => {
                      const num = parseFloat(value);
                      return !isNaN(num) && num >= 0 && num <= 99
                        ? true
                        : "Please enter a valid number between 0 and 99";
                    },
                  },
                })}
                placeholder="FEE"
                onInput={(e) => {
                  const value = e.target.value;
                  const validValue = value
                    .replace(/[^0-9.]/g, "") // Only allow numbers and decimal points
                    .replace(/^(\d*\.\d{2})\d*$/, "$1"); // Limit to two decimal places

                  // Ensure the value is within 0 and 99
                  if (parseFloat(validValue) > 99) {
                    e.target.value = "99";
                  } else if (validValue) {
                    e.target.value = validValue;
                  }
                }}
              />
              <span className="absolute right-2">%</span>
            </div>
            {errors.commissionFee && (
              <span className="form-error-message text-start pt-1">
                {errors.commissionFee.message}
              </span>
            )}

            <input
              value={commissionRange}
              className="form-input rounded-none border border-mediumgray"
              {...register("commissionRange", {
                required: "Commission range is required",
              })}
            />
            {errors.commissionRange && (
              <span className="form-error-message text-start pt-1">
                {errors.commissionRange.message}
              </span>
            )}

            <input
              className="form-input rounded-none border border-t-0 border-mediumgray"
              {...register("commissionNotes", {
                required: false,
              })}
              placeholder="Commission Notes"
            />
          </div>

          <div className="col-span-6 text-start">
            <div>
              <label className="form-label">Marketing</label>
              <div className="border border-mediumgray py-1 px-2">
                <p>As per shopping cart</p>
                <p
                  className="underline cursor-pointer"
                  onClick={() => setMarketingTable(true)}
                >
                  View
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <label className="form-label">Schedule Campaign</label>
          <div className="col-span-12 grid grid-cols-12 gap-3">
            <div className="col-span-4 flex flex-col justify-between">
              <label
                className="form-label font-normal"
                style={{ fontSize: "10px" }}
              >
                Prepare Marketing{" "}
                {watch("prepareMarketing") !== "" &&
                  watch("conclusionDate") !== "" &&
                  // [
                  //   "43 Rona Street, Peakhurst",
                  //   "93 Clarke Street, Peakhurst",
                  //   "22A Park Street, Peakhurst",
                  // ].includes(property.address) &&
                  (eventsLoading ? (
                    <i className="fa-solid fa-spinner animate-spin"></i>
                  ) : (
                    <span
                      className="underline cursor-pointer capitalize"
                      onClick={() => {
                        console.log(getValues());
                        setEventsVisible(true);
                      }}
                      style={{ fontSize: "10px" }}
                    >
                      View
                    </span>
                  ))}
              </label>
              <input
                type="checkbox"
                style={{ display: "none" }}
                {...register("confirmEvents", {
                  required: "You must confirm the events.",
                })}
              />
              {errors?.confirmEvents && (
                <span className="form-error-message text-red-500">
                  {errors?.confirmEvents.message}
                </span>
              )}

              <div className="flex flex-col mt-auto">
                <select
                  className={`form-select border ${
                    errors.prepareMarketing
                      ? "border-red-500"
                      : "border-mediumgray"
                  }`}
                  {...register("prepareMarketing", {
                    required: "Prepare Marketing is required",
                  })}
                  defaultValue="ASAP"
                >
                  {[
                    "Off market",
                    "ASAP",
                    "1 week",
                    "2 weeks",
                    "3 weeks",
                    "4 weeks",
                    "5 weeks",
                    "6 weeks",
                    "7 weeks",
                    "8 weeks",
                  ]
                    .filter(
                      (option) =>
                        watch("access") !== "Tenanted" ||
                        (option !== "ASAP" && option !== "1 week")
                    )
                    .map((option, idx) => (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    ))}
                </select>
                {errors.prepareMarketing && (
                  <span className="form-error-message">
                    {errors.prepareMarketing.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-span-4 flex flex-col justify-between">
              <label
                className="form-label font-normal"
                style={{ fontSize: "10px" }}
              >
                {watch("saleProcess") === "Auction"
                  ? "Auction Date"
                  : "Closing Date"}
              </label>
              <div className="flex flex-col mt-auto">
                <select
                  className={`form-select border ${
                    errors.conclusionDate
                      ? "border-red-500"
                      : "border-mediumgray"
                  }`}
                  {...register("conclusionDate", {
                    required: `${
                      watch("saleProcess") === "Auction"
                        ? "Auction Date"
                        : "Closing Date"
                    } is required`,
                  })}
                  disabled={prepareMarketing === "Off market"}
                >
                  <option value="">Select</option>
                  {["Off market", "1 week", "2.5 weeks", "3 weeks", "4 weeks"]
                    .filter((option) =>
                      watch("saleProcess") === "Auction"
                        ? option !== "1 week"
                        : true
                    )
                    .map((option, idx) => (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    ))}
                </select>
                {errors.conclusionDate && (
                  <span className="form-error-message">
                    {errors.conclusionDate.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-span-4 flex flex-col justify-between">
              <label
                className="form-label font-normal"
                style={{ fontSize: "10px" }}
              >
                Access
              </label>
              <div className="flex flex-col mt-auto">
                <select
                  className={`form-select border ${
                    errors.access ? "border-red-500" : "border-mediumgray"
                  }`}
                  {...register("access", {
                    required: "Access is required",
                    onChange: handleAccessChange, // Use the onChange handler from the register function
                  })}
                >
                  <option value="">Select</option>
                  {["Vacant", "Owner occupied", "Tenanted", "Other"].map(
                    (option, idx) => (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    )
                  )}
                </select>
                {errors.access && (
                  <span className="form-error-message">
                    {errors.access.message}
                  </span>
                )}
                {showAccessOtherInput && (
                  <div>
                    <input
                      type="text"
                      placeholder="Please specify access"
                      className={`form-input mt-1 border ${
                        errors.accessOther
                          ? "border-red-500"
                          : "border-mediumgray"
                      }`}
                      {...register("accessOther", {
                        required: "Other field is required",
                      })}
                    />
                    {errors.accessOther && (
                      <span className="form-error-message">
                        {errors.accessOther.message}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <label className="form-label">Services</label>
          <div className="col-span-12 grid grid-cols-12 gap-3">
            <div className="col-span-4 flex flex-col justify-between">
              <label
                className="form-label m-0 font-normal"
                style={{ fontSize: "10px" }}
              >
                Building & Pest
              </label>
              <div className="flex flex-col mt-auto">
                <p className="italic" style={{ fontSize: "10px" }}>
                  *Upfront $55
                </p>
                <select
                  className={`form-select border ${
                    errors.serviceBuilding
                      ? "border-red-500"
                      : "border-mediumgray"
                  }`}
                  {...register("serviceBuilding", {
                    required: "Field is required",
                  })}
                >
                  <option value="">Select</option>
                  {["Yes", "No"].map((option, idx) => (
                    <option key={idx} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {errors.serviceBuilding && (
                  <span className="form-error-message">
                    {errors.serviceBuilding.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-span-4 flex flex-col justify-between">
              <label
                className="form-label m-0 font-normal"
                style={{ fontSize: "10px" }}
              >
                Strata Report
              </label>
              <div className="flex flex-col mt-auto">
                <p className="italic" style={{ fontSize: "10px" }}>
                  *Upfront $50 (homesafe)
                </p>
                <select
                  className={`form-select border ${
                    errors.serviceStrata
                      ? "border-red-500"
                      : "border-mediumgray"
                  }`}
                  {...register("serviceStrata", {
                    required: "Field is required",
                  })}
                >
                  <option value="">Select</option>
                  {["Yes", "No"].map((option, idx) => (
                    <option key={idx} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {errors.serviceStrata && (
                  <span className="form-error-message">
                    {errors.serviceStrata.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-span-4 flex flex-col justify-between">
              <label
                className="form-label m-0 font-normal"
                style={{ fontSize: "10px" }}
              >
                Styling
              </label>
              <div className="flex flex-col mt-auto">
                <select
                  className={`form-select border ${
                    errors.serviceStyling
                      ? "border-red-500"
                      : "border-mediumgray"
                  }`}
                  {...register("serviceStyling", {
                    required: "Field is required",
                  })}
                >
                  <option value="">Select</option>
                  {[
                    "Urban chic",
                    "Style to sell",
                    "Little styling co",
                    "No",
                  ].map((option, idx) => (
                    <option key={idx} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {errors.serviceStyling && (
                  <span className="form-error-message">
                    {errors.serviceStyling.message}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 text-start">
          <label className="form-label">ID Check</label>
          <p className="italic">
            *Sight ID, credit card, water rates, council rates
          </p>
          <select
            className={`form-select border ${
              errors.fraudPrevention ? "border-red-500" : "border-mediumgray"
            }`}
            {...register("fraudPrevention", {
              required: "Fraud Prevention is required",
            })}
            defaultValue="Yes"
          >
            <option value="">Select</option>

            {["Yes", "No"].map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors.fraudPrevention && (
            <span className="form-error-message">
              {errors.fraudPrevention.message}
            </span>
          )}
        </div>

        <Followers onTagsChange={onTagsChange} />

        {/* Signature Pads for Each Vendor at the End */}
        <div className="w-full flex flex-col sm:flex-row sm:gap-4">
          {vendors.map((vendor) => (
            <div key={vendor.id} className="mb-4 w-full sm:w-1/2">
              <SignaturePad
                key={vendor.id}
                onSave={(signature) =>
                  handleSaveSignature(signature, vendor.id)
                }
                // onReset={() => handleResetSignature(vendor.id)} // Pass vendor ID
                vendor={vendor}
                vendors={vendors}
                agentSignature={agentSignature}
                trigger={trigger}
                getValues={getValues}
                register={register} // Pass register
                errors={errors} // Pass errors
                propertyId={property._id}
                isValid={isValid}
                setValue={setValue}
                recommendedSales={{
                  recommendedSales,
                  onAdd: handleAddRecommendedSales,
                  onUpdate: handleUpdateRecommendedSales,
                  onDelete: handleDeleteRecommendedSales,
                }}
                recommendedSold={{
                  recommendedSold,
                  onAdd: handleAddRecommendedSold,
                  onUpdate: handleUpdateRecommendedSold,
                  onDelete: handleDeleteRecommendedSold,
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={addFollowers}
        onClose={() => setAddFollowers(false)}
        title="Add Followers"
      >
        <div className="space-y-4">
          <div className="flex justify-end">
            <Button
              type="button"
              onClick={() => append({ name: "", email: "", mobile: "" })}
              className="black-button flex items-center justify-center gap-2"
            >
              <FaPlus />
              Add
            </Button>
          </div>

          {fields.map((field, index) => (
            <div
              key={field.id}
              className="p-4 border rounded-lg bg-white shadow-sm"
            >
              <div className="flex justify-between items-center mb-4">
                <p className="font-semibold">Follower {index + 1}</p>
                {fields.length && (
                  <i
                    className="fa-solid fa-trash cursor-pointer"
                    onClick={() => remove(index)}
                  ></i>
                )}
              </div>

              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <label className="w-16">Name</label>
                  <div className="flex-1">
                    <input
                      type="name"
                      className={`form-input border ${
                        errors?.vendorFollowers?.[index]?.name
                          ? "border-red-500"
                          : "border-gray-200"
                      }`}
                      {...register(`vendorFollowers.${index}.name`, {
                        required: "Name is required",
                      })}
                      placeholder="Name"
                    />
                    {errors?.vendorFollowers?.[index]?.name && (
                      <span className="form-error-message">
                        {errors.vendorFollowers[index].name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <label className="w-16">Email</label>
                  <div className="flex-1">
                    <input
                      type="email"
                      className={`form-input border ${
                        errors?.vendorFollowers?.[index]?.email
                          ? "border-red-500"
                          : "border-gray-200"
                      }`}
                      {...register(`vendorFollowers.${index}.email`, {
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Email is not valid",
                        },
                      })}
                      placeholder="Email address"
                    />
                    {errors?.vendorFollowers?.[index]?.email && (
                      <span className="form-error-message">
                        {errors.vendorFollowers[index].email.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <label className="w-16">Mobile</label>
                  <div className="flex-1">
                    <input
                      type="text"
                      className={`form-input border ${
                        errors?.vendorFollowers?.[index]?.mobile
                          ? "border-red-500"
                          : "border-gray-200"
                      }`}
                      {...register(`vendorFollowers.${index}.mobile`, {
                        required: "Mobile is required",
                        pattern: {
                          value:
                            /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
                          message: "Mobile number is not valid",
                        },
                      })}
                      placeholder="Mobile number"
                    />
                    {errors?.vendorFollowers?.[index]?.mobile && (
                      <span className="form-error-message">
                        {errors.vendorFollowers[index].mobile.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {fields.length > 0 && (
            <div className="flex justify-end">
              <Button
                type="button"
                onClick={async () => {
                  const isValid = await trigger("vendorFollowers");
                  if (isValid) {
                    setAddFollowers(false); // Close the modal
                  }
                }}
                className="black-button"
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </Modal>

      {/* Modal for Marketing Table */}
      <Modal
        isOpen={marketingTable}
        // onClose={() => setMarketingTable(false)}
        onClose={() => setMarketingTable(false)}
        title="Shopping Cart"
      >
        {/* <MarketingPrice /> */}
        <MarketingPrice
          suburb={property.suburb}
          price={watch("endPrice") || property.logicalPrice}
          postcode={property.postcode}
          onConfirm={handleSaveMarketingData} // Pass function to save selected items
          existingData={watch("marketing")} // Watch for existing form data
        />
      </Modal>

      {/* Modal for Events */}
      <Modal
        isOpen={eventsVisible}
        onClose={() => setEventsVisible(false)}
        title="Events"
        style={{ zIndex: "1009" }}
      >
        <EventsCalendar
          events={events}
          setEvents={setEvents}
          data={getValues()}
          property={property}
          register={register}
          trigger={trigger}
          errors={errors}
          setEventsVisible={setEventsVisible}
          setMarketingTable={setMarketingTable}
        />
      </Modal>
    </div>
  );
};

export default AuthoriseSchedule;
