import React, { useState, useRef, useEffect } from "react";
import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import { useForm, useFieldArray } from "react-hook-form";
import axios from "utils/axios";
import { Autocomplete } from "@react-google-maps/api"; // Removed useJsApiLoader
import {
  fetchSignatureUrl,
  formatCurrency,
  formatDateToAEDT,
} from "utils/helperFunctions";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import "./AuthoriseSchedule.css";
import MarketingPrice from "components/ui/MarketingPrice";
import "../chat/BookAppraisal.css";
import EventsCalendar from "./EventsCalendar";
import CampaignAgent from "./CampaignAgent";
import EventsCalendarComplete from "./EventsCalendarComplete";
import ChatButton from "./Chatbot";
import { showToast } from "components/ui/toast";

const SolicitorSearch = ({ defaultValues, id, propertyId }) => {
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = useForm({
    defaultValues,
    mode: "onChange", // Enable real-time validation
  });

  const [address, setAddress] = useState(defaultValues.address);
  const autocompleteRef = useRef(null);

  const handleLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const handlePlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();

      const name = place.name || "";
      const formattedAddress = place.formatted_address
        ? place.formatted_address.replace(", Australia", "")
        : "";
      const phoneNumber = place.formatted_phone_number || "";

      setAddress(formattedAddress);

      // Update form values
      setValue("name", name, { shouldValidate: true });
      setValue("address", formattedAddress, { shouldValidate: true });
      setValue("mobile", phoneNumber, { shouldValidate: true });

      // Trigger validation
      trigger(["name", "address", "mobile"]);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    // Check if the 'tab' parameter is set to 'authorise-and-schedule'
    if (searchParams.get("tab") === "authorise-and-schedule") {
      const styleSheets = document.styleSheets;
      for (let i = 0; i < styleSheets.length; i++) {
        try {
          const rules = styleSheets[i].cssRules;
          for (let j = 0; j < rules.length; j++) {
            // Find the rule that applies to .pac-container.pac-logo and remove it
            if (rules[j].selectorText === ".pac-container.pac-logo") {
              styleSheets[i].deleteRule(j);
              break;
            }
          }
        } catch (e) {
          // Ignore cross-origin or inaccessible stylesheets
          if (e.name !== "SecurityError") {
            console.error(e);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    // Apply the necessary styles for Google Places Autocomplete dropdown positioning
    const style = document.createElement("style");
    style.textContent = `
      .pac-container.pac-logo {
        z-index:1020 !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setAddress(value); // Updates the local state
    setValue("address", value, { shouldValidate: true }); // Updates the form value
  };

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveEmail, setLoadingSaveEmail] = useState(false);

  const saveSolicitor = async () => {
    try {
      setLoadingSave(true);

      const isValid = await trigger();
      if (!isValid) {
        return;
      }

      const solicitor = getValues();

      const response = await axios.put(`/authSchedule/edit/${id}`, {
        solicitor,
      });
      if (response.data.success) {
        showToast("success", "Solicitor saved successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
      showToast("error", "Something went wrong");
    } finally {
      setLoadingSave(false);
    }
  };

  const saveSolicitorandEmail = async () => {
    try {
      setLoadingSaveEmail(true);

      const isValid = await trigger();
      if (!isValid) {
        return;
      }

      const solicitor = getValues();

      const response = await axios.put(`/authSchedule/edit/${id}`, {
        solicitor,
      });

      if (response.data.success) {
        const emailResponse = await axios.post(
          "/authSchedule/resendProviderEmail",
          {
            propertyId,
            providerName: "solicitor",
          }
        );

        if (emailResponse.data.success) {
          showToast("success", "Solicitor saved and email sent successfully");
          window.location.reload();
        }
      }
    } catch (error) {
      console.log(error.message);
      showToast("error", "Something went wrong");
    } finally {
      setLoadingSaveEmail(false);
    }
  };

  return (
    <>
      <Autocomplete
        onLoad={handleLoad}
        onPlaceChanged={handlePlaceChanged}
        options={{
          componentRestrictions: { country: ["au"] },
          fields: [
            "name",
            "formatted_address",
            "formatted_phone_number",
            "address_components",
            "geometry",
          ],
          types: ["establishment"],
        }}
      >
        <div className="relative">
          <input
            type="text"
            value={address}
            onChange={handleInputChange}
            placeholder="Search solicitor on Google"
            className={`search-input w-full relative z-10 flex-grow min-h-[39px] p-2.5 bg-lightgray rounded outline-none focus:outline-none resize-none overflow-y-hidden ${
              errors?.address ? "border border-red-500" : ""
            }`}
          />
          {/* Display Validation Error */}
          {errors.address && (
            <span className="form-error-message text-start pt-1">
              {errors.address.message}
            </span>
          )}
        </div>
      </Autocomplete>

      <div className="col-span-12 ">
        <input
          type="text"
          className={`form-input border ${
            errors.name ? "border-red-500" : "border-mediumgray"
          }`}
          {...register(`name`, {
            required: "Name is required",
          })}
          placeholder="NAME"
        />
        {errors.name && (
          <span className="form-error-message text-start pt-1">
            {errors.name.message}
          </span>
        )}
      </div>

      <div className="col-span-12">
        <input
          type="email"
          className={`form-input border ${
            errors.email ? "border-red-500" : "border-mediumgray"
          }`}
          {...register(`email`, {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Email is not valid",
            },
          })}
          placeholder="EMAIL"
        />
        {errors.email && (
          <span className="form-error-message text-start pt-1">
            {errors.email.message}
          </span>
        )}
      </div>

      <div className="col-span-12 ">
        <input
          type="text"
          className={`form-input border ${
            errors.mobile ? "border-red-500" : "border-mediumgray"
          }`}
          {...register(`mobile`, {
            required: "Mobile is required",
          })}
          placeholder="MOBILE"
        />
        {errors.mobile && (
          <span className="form-error-message text-start pt-1">
            {errors.mobile.message}
          </span>
        )}
      </div>

      <div className="flex justify-end gap-2 mt-4">
        <Button
          className="gray-button"
          onClick={saveSolicitor}
          loading={loadingSave}
          disabled={loadingSave || loadingSaveEmail}
        >
          Save
        </Button>
        <Button
          className="black-button"
          onClick={saveSolicitorandEmail}
          loading={loadingSaveEmail}
          disabled={loadingSave || loadingSaveEmail}
        >
          Save and Email
        </Button>
      </div>
    </>
  );
};

const AuthoriseScheduleComplete = ({ fetchedData }) => {
  const propertyId = fetchedData.propertyId;
  const [resetConfirm, setResetConfirm] = useState(false);

  const resetAuthSchedule = async () => {
    const response = await axios.delete(`/authSchedule/${propertyId}`);
    if (response.data.success) {
      window.location.reload();
    }
  };

  const data = {
    agentContribution:
      fetchedData?.marketing?.agentContribution.amount.replace("$", "") || 0,
    dataEstimatedSalePrice: fetchedData.endPrice.replace(/[$,]/g, ""),
    listingId: fetchedData.propertyId,
    apiConsumerName: "AusRealty",
    userEmail: fetchedData.agent.email,
    hasPermission: true,
    userName: fetchedData.agent.name,
    dataMode: "advanced",
    officeName: "AusRealty",
    officeOwnershipGroupName: fetchedData.agent.officeName,
    officeOwnershipGroup_Ui: fetchedData.agent.officeId,
    officeUniqueIdentifier: fetchedData.agent.officeId,
    owners: fetchedData.vendors.map((vendor) => ({
      email: vendor.email,
      mobile: vendor.mobile,
      name: vendor.firstName + " " + vendor.lastName,
    })),
    propertyAddress: fetchedData.property.address + " NSW",
    requestedAmount: fetchedData?.marketing?.total || 0,
    listingAgentName: fetchedData.agent.name,
    listingAgentEmail: fetchedData.agent.email,
    listingAgentMobile: fetchedData.agent.mobile,
  };

  const [secondCart, setSecondCart] = useState(false);

  const handleSaveSecondCartData = async (selectedItems) => {
    console.log(selectedItems);
    await axios.post("/authSchedule/saveSecondCart", {
      propertyId: fetchedData.propertyId,
      marketingSecond: selectedItems,
    });
  };

  const [loading, setLoading] = useState(false);
  const [editSolicitor, setEditSolicitor] = useState(false);

  const resendEmail = async (providerName) => {
    try {
      setLoading(true);
      const response = await axios.post("/authSchedule/resendProviderEmail", {
        propertyId: fetchedData.propertyId,
        providerName,
      });
      if (response.data.success) {
        showToast("success", "Email resent successfully");
      }
    } catch (error) {
      console.log(error.message);
      showToast("error", "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="authorise-form w-full h-full max-w-4xl mx-auto px-4">
        <p className="mb-2 text-darkgray text-center">{fetchedData.address}</p>
        <p className="mb-4 text-darkgray text-center">
          Agent: {fetchedData.agent.name}
        </p>

        <div className="flex items-center justify-between w-full">
          <div className="flex-1 text-center">
            <h5 className="inline-block">Status: Completed</h5>
          </div>
          <Button
            className="black-button px-2 py-1 text-xs"
            onClick={() => setResetConfirm(true)}
          >
            Reset
          </Button>
        </div>

        <table className="w-full border border-lightgray border-collapse mt-2">
          <thead>
            <tr className="bg-lightgray">
              <th className="py-2 px-4 text-center">Signer</th>
              <th className="py-2 px-4 text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {fetchedData.vendors.map((vendor, index) => (
              <Disclosure key={index}>
                {({ open }) => (
                  <>
                    <Disclosure.Button as="tr" className="cursor-pointer">
                      <td className="py-2 px-4 border-b text-center">
                        {vendor.firstName} {vendor.lastName}
                      </td>

                      <td className="py-2 px-4 border-b text-center">
                        {vendor.isSigned
                          ? "Signed"
                          : vendor.viewedDate
                          ? "Viewed"
                          : vendor.sentDate
                          ? "Sent"
                          : ""}
                      </td>
                    </Disclosure.Button>
                    <Disclosure.Panel as="tr">
                      <td colSpan="2" className="border-t">
                        <div className="w-full overflow-x-auto">
                          <table className="w-full table-fixed border-collapse">
                            <thead>
                              <tr className="bg-lightgray">
                                <th className="py-2 px-4 text-center">
                                  Sent Date
                                </th>
                                <th className="py-2 px-4 text-center">
                                  Viewed Date
                                </th>
                                <th className="py-2 px-4 text-center">
                                  Signed Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="py-2 px-4 text-center">
                                  {vendor.sentDate || "N/A"}
                                </td>
                                <td className="py-2 px-4 text-center">
                                  {vendor.viewedDate || "N/A"}
                                </td>
                                <td className="py-2 px-4 text-center">
                                  {vendor.signedDate || "N/A"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </tbody>
        </table>

        <br></br>

        <div className="flex items-center justify-between py-2">
          <div className="flex flex-col">
            <p>Solicitor: {fetchedData.solicitor.name}</p>
            <p>{fetchedData.solicitor.sentDate}</p>
          </div>
          <div className="flex gap-2">
            <Button
              className="gray-button px-2 py-1 text-xs h-auto"
              onClick={() => setEditSolicitor(true)}
            >
              Edit
            </Button>
            <Button
              className="black-button px-2 py-1 text-xs h-auto"
              onClick={() => resendEmail("solicitor")}
              loading={loading}
              disabled={loading}
            >
              Resend
            </Button>
          </div>
        </div>

        <div className="flex items-center justify-between py-2">
          <p>Building & Pest - {fetchedData.services[0].value}</p>
          <p>{fetchedData.services[0].sentDate}</p>
        </div>

        <div className="flex items-center justify-between py-2">
          <p>Strata - {fetchedData.services[1].value}</p>
          <p>{fetchedData.services[1].sentDate}</p>
        </div>

        <div className="flex items-center justify-between py-2">
          <p>Styling - {fetchedData.services[2].value}</p>
          <p>{fetchedData.services[2].sentDate}</p>
        </div>

        {fetchedData.vendorFollowers.length > 0 && (
          <div className="flex flex-col items-center justify-between py-2">
            <h6>Vendor Followers</h6>
            <table className="w-full border border-lightgray border-collapse mt-2">
              <thead>
                <tr className="bg-lightgray">
                  <th className="py-2 px-4 text-center">Name</th>
                  <th className="py-2 px-4 text-center">Email</th>
                  <th className="py-2 px-4 text-center">Mobile</th>
                </tr>
              </thead>
              <tbody>
                {fetchedData.vendorFollowers.map((vendorFollower, index) => (
                  <tr>
                    <td className="py-2 px-4 border-b text-center">
                      {vendorFollower?.name}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {vendorFollower?.email}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {vendorFollower?.mobile}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {fetchedData.followers.length > 0 && (
          <div className="flex flex-col items-center justify-between py-2">
            <h6>Co-Agent</h6>
            <table className="w-full border border-lightgray border-collapse mt-2">
              <thead>
                <tr className="bg-lightgray">
                  <th className="py-2 px-4 text-center">Name</th>
                  <th className="py-2 px-4 text-center">Email</th>
                  <th className="py-2 px-4 text-center">Mobile</th>
                </tr>
              </thead>
              <tbody>
                {fetchedData.followers.map((follower, index) => (
                  <tr>
                    <td className="py-2 px-4 border-b text-center">
                      {follower?.name}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {follower?.email}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {follower?.mobile}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* {requiredAddresses.includes(fetchedData.property.address) && (
          <div className="flex items-center justify-between py-2">
            <p>WhatsApp group created - Yes</p>
          </div>
        )} */}

        <br></br>

        <div className="col-span-12 grid grid-cols-12 gap-3">
          <div className="col-span-4 flex flex-col justify-between">
            <label className="form-label">Prepare Marketing</label>
            <input
              type="text"
              className={`form-input border border-mediumgray`}
              value={fetchedData.prepareMarketing}
              readOnly
            />
          </div>
          <div className="col-span-4 flex flex-col justify-between">
            <label className="form-label">
              {fetchedData.saleProcess === "Auction"
                ? "Auction Date"
                : "Closing Date"}
            </label>
            <input
              type="text"
              className={`form-input border border-mediumgray`}
              value={fetchedData.conclusionDate}
              readOnly
            />
          </div>
          <div className="col-span-4 flex flex-col justify-between">
            <label className="form-label">Access</label>
            <input
              type="text"
              className={`form-input border border-mediumgray`}
              value={fetchedData.access}
              readOnly
            />
          </div>
        </div>

        <br></br>

        {fetchedData?.marketing?.categories && (
          <div className="w-full">
            <label className="text-center form-label">SHOPPING CART</label>
            <div className="w-full flex items-center justify-center">
              <MarketingPrice
                suburb={fetchedData?.property?.suburb}
                price={fetchedData?.endPrice}
                postcode={fetchedData?.property?.postcode}
                isEdit={false}
                existingData={fetchedData?.marketing}
              />
            </div>
          </div>
        )}

        <br></br>

        {!secondCart && !fetchedData?.marketingSecond && (
          <Button onClick={() => setSecondCart(true)} className="black-button">
            Add Second Cart
          </Button>
        )}

        {(secondCart || fetchedData?.marketingSecond) && (
          <div className="w-full">
            <label className="text-center form-label">Shopping Cart 2</label>
            <div className="w-full flex items-center justify-center">
              <MarketingPrice
                suburb={fetchedData?.property?.suburb}
                price={fetchedData?.endPrice}
                postcode={fetchedData?.property?.postcode}
                onConfirm={handleSaveSecondCartData} // Uncomment if needed
                existingData={fetchedData?.marketingSecond} // Watch for existing form data
              />
            </div>
          </div>
        )}

        <br></br>
        <br></br>
        <CampaignAgent data={data} />

        <br></br>

        <EventsCalendarComplete
          propertyId={fetchedData.propertyId}
          data={fetchedData}
        />

        <ChatButton />
      </div>

      <Modal
        isOpen={resetConfirm}
        onClose={() => setResetConfirm(false)}
        title="Reset Agreement"
      >
        <div className="flex flex-col items-center justify-center">
          <p>
            Are you sure you want to reset the agreement? Once reset it cannot
            be reverted
          </p>
          <div className="w-full flex gap-2 justify-end py-4">
            <Button
              onClick={() => setResetConfirm(false)}
              className="gray-button"
            >
              No
            </Button>
            <Button onClick={resetAuthSchedule} className="black-button">
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={editSolicitor}
        onClose={() => setEditSolicitor(false)}
        title="Edit Solicitor"
      >
        <SolicitorSearch
          defaultValues={fetchedData?.solicitor}
          id={fetchedData?._id}
          propertyId={fetchedData?.propertyId}
        />
      </Modal>
    </>
  );
};

export default AuthoriseScheduleComplete;
