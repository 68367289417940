import React, { useState, useEffect, useContext, useRef } from "react";
import { isEqual } from "lodash";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import axios from "utils/axios";
import Error from "components/ui/Error";
import Button from "components/ui/Button";
import { useForm } from "react-hook-form";
import { MdOutlineEdit } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi2";
import GoogleMaps from "components/GoogleMaps";
import GoogleChart from "components/GoogleChart";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EditableField from "components/ui/EditableField";
import LogicalPriceEdit from "components/ui/LogicalPriceEdit";
import DeleteField from "components/ui/DeleteField";
import Tooltip from "components/ui/Tooltip";
import arrow from "assets/images/arrow.png";
import GooglePieChart from "components/GooglePieChart";
import { AuthContext } from "context/AuthContext";
import Modal from "components/ui/Modal";
import propertyImg2 from "assets/images/property-img-2.png";
import propertyImg5 from "assets/images/property-img-5.jpg";
import propertyImg7 from "assets/images/property-img-7.jpg";
import processChainImg1 from "assets/images/process-chain-1.jpg";
import processChainImg2 from "assets/images/process-chain-2.jpg";
import processChainImg3 from "assets/images/process-chain-3.jpg";
import processChainImg4 from "assets/images/process-chain-4.jpg";
import processExampleImg from "assets/images/process-example.png";
import "./PriceProcess.css";
import RecommendedSalesProcess from "./RSP";
import PageLoader from "components/ui/PageLoader";
import PdfViewer from "components/ui/PdfViewer";
import Adcopy from "assets/images/ADCOPY/Kangaroo Adcopy.pdf";
import FiveStepProcess from "./FiveStepProcess";
import Carousel from "components/carousel/Carousel";
import ViewReport from "components/report/ViewReport";
import Card from "components/ui/Card";
import AudioTranscript from "components/record_audio/AudioTranscript";
import PostList from "./Postlist copy";
import NoImage from "assets/images/no-image.jpg";
import { PropertyDetailsModal } from "components/ui/PropertyDetailsModal";
import RecentAreaSoldProcess from "./RecentAreaSoldProcess";
import GooglePlacesAutocomplete from "components/GooglePlacesAutoComplete";
import { showToast } from "components/ui/toast";

const tabs = [
  {
    title: "Get Started",
    tab: "getStarted",
  },
  {
    title: "Presentation",
    tab: "presentation",
  },
  {
    title: "Logical Price",
    tab: "logicalPrice",
  },
  {
    title: "Process",
    tab: "process",
  },
  {
    title: "Solution",
    tab: "solution",
  },
  {
    title: "Marketing",
    tab: "marketing",
  },
  {
    title: "Tools",
    tab: "tools",
  },
  {
    title: "Postlist",
    tab: "postlist",
  },
];

function importAllImages(requireContext) {
  const images = requireContext.keys().map(requireContext);

  // Use a Set to ensure only unique images are used (by file path)
  const uniqueImages = new Set(images.map((image) => image.default || image));

  return [...uniqueImages];
}

function importAllVideos(requireContext) {
  const videos = requireContext.keys().map(requireContext);

  // Use a Set to ensure only unique videos are used (by file path)
  const uniqueVideos = new Set(videos.map((video) => video.default || video));

  return [...uniqueVideos];
}

const brochure8Folder1 = importAllImages(
  require.context(
    "assets/images/LANDSCAPE/BROCHURE 8 PG/26-765 Princes Highway, Blakehurst",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const brochure8Folder2 = importAllImages(
  require.context(
    "assets/images/LANDSCAPE/BROCHURE 8 PG/4 5 B E L M O R E R O A D , P E A K H U R S T",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const brochure16Folder1 = importAllImages(
  require.context(
    "assets/images/LANDSCAPE/BROCHURE 16 PG/45 BELMORE",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
// const brochure16Folder2 = importAllImages(
//   require.context(
//     "assets/images/LANDSCAPE/BROCHURE 16 PG/12 Dodson",
//     false,
//     /\.(png|jpe?g|svg)$/
//   )
// );
// const brochure16Folder3 = importAllImages(
//   require.context(
//     "assets/images/LANDSCAPE/BROCHURE 16 PG/152 Lugarno",
//     false,
//     /\.(png|jpe?g|svg)$/
//   )
// );
const droneFolder1 = importAllImages(
  require.context(
    "assets/images/LANDSCAPE/DRONE PHOTOS",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const duskFolder1 = importAllImages(
  require.context(
    "assets/images/LANDSCAPE/DUSK PHOTOS",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const floorplanFolder1 = importAllImages(
  require.context(
    "assets/images/LANDSCAPE/FLOORPLAN",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const photosFolder1 = importAllImages(
  require.context("assets/images/LANDSCAPE/PHOTOS", false, /\.(png|jpe?g|svg)$/)
);
const socialMediaFolder1 = importAllImages(
  require.context(
    "assets/images/LANDSCAPE/SOCIAL MEDIA",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const mailCardsFolder = importAllImages(
  require.context(
    "assets/images/LANDSCAPE/MAILCARDS",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const signboardFolder1 = importAllImages(
  require.context(
    "assets/images/SIGNBOARDS/STANDARD",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const signboardFolder2 = importAllImages(
  require.context(
    "assets/images/SIGNBOARDS/ILLUMINATED",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
// const videosFolder = importAllVideos(
//   require.context(
//     "assets/images/LANDSCAPE/VIDEO STANDARD", // Your video folder path
//     false,
//     /\.(mp4|webm|ogg)$/
//   )
// );

// const storyFolder = importAllVideos(
//   require.context(
//     "assets/images/LANDSCAPE/VIDEO STORYTELLING", // Your video folder path
//     false,
//     /\.(mp4|webm|ogg)$/
//   )
// );

const formatCurrency = (value) => {
  if (value === undefined || value === null) return "N/A";
  return "$" + new Intl.NumberFormat().format(value);
};

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  adaptiveHeight: true,
  arrows: true,
  slidesToShow: 1, // Show only 2 slides if they are large in width
  slidesToScroll: 1,
  variableWidth: true, // Allows variable width for slides
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

const mapSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  adaptiveHeight: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ProcessChain = ({ property }) => {
  const [chainStep, setChainStep] = useState(0);
  const [isProcessChain, setIsProcessChain] = useState(false);
  const [steps, setSteps] = useState(property.processChain);

  const goToChainStep = async (index) => {
    if (index === 0 || steps[index - 1].selected === true) {
      setChainStep(index);
      setIsProcessChain(true);
    }
    if (index === 6) {
      const updatedSteps = [...steps];
      updatedSteps[6].selected = true;
      setSteps(updatedSteps);

      try {
        await axios.put("/userProperty", {
          address: property.address,
          processChain: steps,
        });
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const selectOption = (stepIndex, isSelected) => {
    const updatedSteps = [...steps];
    updatedSteps[stepIndex].selected = isSelected;
    setSteps(updatedSteps);

    if (isSelected) {
      if (stepIndex < steps.length - 1) {
        setChainStep(stepIndex + 1);
      } else {
        setIsProcessChain(false);
      }
    } else {
      resetFollowingSteps(stepIndex);
      setChainStep(stepIndex);
    }
    setIsProcessChain(false);
  };

  const resetFollowingSteps = async (fromIndex) => {
    const updatedSteps = [...steps];
    for (let i = fromIndex + 1; i < updatedSteps.length; i++) {
      updatedSteps[i].selected = null;
    }
    setSteps(updatedSteps);
    await axios.put("/userProperty", {
      address: property.address,
      processChain: [
        { label: "1", name: "Views", selected: null },
        { label: "2", name: "Enquiry", selected: null },
        { label: "3", name: "Inspection", selected: null },
        { label: "4", name: "Offers", selected: null },
        { label: "5", name: "Close Feedback", selected: null },
        { label: "6", name: "Vendor Acceptance", selected: null },
        { label: "7", name: "Maximum Outcome", selected: null },
      ],
    });
  };

  return (
    <div className="w-full space-y-8">
      <h4>THE PROCESS CHAIN</h4>
      <div className="w-full flex overflow-x-auto box-scrollbar">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center">
            <div>
              <div
                className="flex items-center justify-center border-4 chain border-gray-300 bg-white text-gray-500 cursor-pointer"
                onClick={() => goToChainStep(index)}
              >
                {step.selected === true && "✔️"}
                {step.selected === false && "❌"}
                {step.selected === null && ""} {/* Initial state */}
              </div>
              <p className="mt-2 mb-0 min-h-[32px] max-w-[100px] text-xs">
                {step.name}
              </p>
            </div>
            {index < steps.length - 1 && (
              <div className="chain-stick bg-gray-300"></div>
            )}
          </div>
        ))}
      </div>

      <Modal
        isOpen={isProcessChain}
        onClose={() => setIsProcessChain(false)}
        title=""
      >
        {chainStep === 0 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <img src={processChainImg1} class="max-w-[300px] h-auto" />
              <button
                class="black-button"
                onClick={() => selectOption(0, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <img src={processChainImg2} class="max-w-[300px] h-auto" />
              <button
                class="black-button"
                onClick={() => selectOption(0, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 1 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>ATTENDANCE</p>
                <p>Automated open home message</p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(1, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>NO ATTENDANCE</p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(1, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 2 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>CORRECT PRICE GUIDE</p>
                <p>
                  Automated message with guide. “We will have a more accurate
                  indication on price once we receive buyer feedback”.
                </p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(2, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>PRICE GUIDE TOO HIGH</p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(2, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 3 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <img src={processChainImg3} class="max-w-[300px] h-auto" />
              <button
                class="black-button"
                onClick={() => selectOption(3, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <img src={processChainImg4} class="max-w-[300px] h-auto" />
              <button
                class="black-button"
                onClick={() => selectOption(3, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 4 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>AUTOMATED MESSAGE</p>
                <p>Tender Process</p>
                <p>
                  “Ausrealty has received multiple interests in the property at
                  ‘address’ and advises all parties to submit their best and
                  final offers, including terms, by ‘date’ via text to ‘agent’
                  at ‘mobile’. Contracts will be exchanged without further
                  notice. For FAQs, visit the provided link. Today is the final
                  opportunity to secure the home, so submit your absolute best
                  offer to ensure fairness”.
                </p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(4, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>AUTOMATED MESSAGE</p>
                <p>Other First Offer</p>
                <p>
                  “To buy this property before auction, your offer must be $2
                  million. The auction guide price is $1.7 million, but offers
                  at this price can only be considered at auction.”
                </p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(4, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 5 && (
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>VENDOR ACCEPTSE</p>
                <p>Maximum Price e.g. $1.9m</p>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(5, true)}
              >
                Select
              </button>
            </div>
            <div class="flex flex-col gap-4">
              <div class="border border-gray-200 p-4 flex flex-col items-center justify-center gap-4">
                <p>VENDOR DECLINES</p>
                <div class="overflow-x-auto">
                  <table class="min-w-full border border-black">
                    <tbody>
                      <tr>
                        <td class="border border-black px-4 py-2">Vendor</td>
                        <td class="border border-black px-4 py-2">3m</td>
                      </tr>
                      <tr>
                        <td class="border border-black px-4 py-2">1</td>
                        <td class="border border-black px-4 py-2">1.9m</td>
                      </tr>
                      <tr>
                        <td class="border border-black px-4 py-2">1.8m</td>
                        <td class="border border-black px-4 py-2">Offer</td>
                      </tr>
                      <tr>
                        <td class="border border-black px-4 py-2">1.6m</td>
                        <td class="border border-black px-4 py-2">Offer</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <button
                class="black-button"
                onClick={() => selectOption(5, false)}
              >
                Select
              </button>
            </div>
          </div>
        )}

        {chainStep === 6 && (
          <div class="flex flex-col gap-8">
            <p>Congratulations</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

const FinishesSelector = ({
  register,
  errors,
  selectedValue,
  onChange,
  onEdit,
}) => {
  const finishesData = [
    {
      label: "High-end finishes",
      value: "High-end finishes",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/high-end.png",
    },
    {
      label: "Updated",
      value: "Updated",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/updated.png",
    },
    {
      label: "Original",
      value: "Original",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/finishesGuide/original.png",
    },
  ];

  useEffect(() => {
    register("finishes", { required: "Finishes selection is required" });
  }, [register]);

  return (
    <div>
      <label className="form-label">Select Finishes</label>
      {selectedValue ? (
        <div className="flex items-center justify-between form-input border border-mediumgray p-2">
          <span>{selectedValue}</span>
          <button type="button" onClick={onEdit} className="text-darkergray">
            <MdOutlineEdit />
          </button>
        </div>
      ) : (
        <div className="flex overflow-x-auto box-scrollbar gap-4">
          {finishesData.map((finish) => (
            <div
              key={finish.value}
              className="cursor-pointer rounded mr-4 transition"
              onClick={() => onChange(finish.value)}
            >
              <img
                src={finish.imgSrc}
                alt={finish.label}
                className="w-full h-auto min-w-[250px] max-h-[256px] object-cover"
              />
              <span className="block text-sm text-center mt-2">
                {finish.label}
              </span>
            </div>
          ))}
        </div>
      )}
      {errors.finishes && (
        <span className="form-error-message">{errors.finishes.message}</span>
      )}
    </div>
  );
};

const WaterViewsSelector = ({
  register,
  errors,
  selectedValue,
  onChange,
  onEdit,
}) => {
  const waterViewsData = [
    {
      label: "Deep waterfront with jetty",
      value: "Deep waterfront with jetty",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/deep-waterfront-with-jetty.jpg",
    },
    {
      label: "Water views",
      value: "Water views",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/water-views.jpg",
    },
    {
      label: "Tidal waterfront with jetty",
      value: "Tidal waterfront with jetty",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/tidal-waterfront-with-jetty.jpg",
    },
    {
      label: "Waterfront reserve",
      value: "Waterfront reserve",
      imgSrc:
        "https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/waterViews/waterfront-reserve.jpg",
    },
  ];

  useEffect(() => {
    register("waterViews", { required: "Waterviews selection is required" });
  }, [register]);

  const handleNoCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      onChange("No");
    } else {
      onChange("");
    }
  };

  return (
    <div>
      <label className="form-label">Select Water Aspect</label>

      {/* Display selected value with Edit button if a selection is made */}
      {selectedValue ? (
        <div className="flex items-center justify-between form-input border border-mediumgray p-2">
          <span>{selectedValue}</span>
          <button type="button" onClick={onEdit} className="text-darkergray">
            <MdOutlineEdit />
          </button>
        </div>
      ) : (
        // Display options when no selection is made
        <>
          {/* "No" Checkbox */}
          <label className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={selectedValue === "No"}
              onChange={handleNoCheckboxChange}
              className="mr-2"
            />
            No
          </label>

          {/* Display options if "No" is not selected */}
          {selectedValue !== "No" && (
            <div className="flex overflow-x-auto box-scrollbar gap-4">
              {waterViewsData.map((waterView) => (
                <div
                  key={waterView.value}
                  className="cursor-pointer rounded mr-4 transition"
                  onClick={() => onChange(waterView.value)}
                >
                  <img
                    src={waterView.imgSrc}
                    alt={waterView.label}
                    className="w-full h-auto min-w-[250px] max-h-[256px] object-cover"
                  />
                  <span className="block text-sm text-center mt-2">
                    {waterView.label}
                  </span>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {/* Display errors if any */}
      {errors.waterViews && (
        <span className="form-error-message">{errors.waterViews.message}</span>
      )}
    </div>
  );
};

const TopographyDropdown = ({
  selectedValues = [], // Ensure selectedValues is an array
  onToggleOption,
  isOpen,
  toggleDropdown,
  errors,
  register,
  trigger, // add trigger to manually validate
}) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    register("topography", {
      validate: (value) => {
        if (!Array.isArray(value) || value.length === 0) {
          return "At least one option must be selected"; // Error if no selection
        }
        return true; // No error if validation passes
      },
    });
  }, [register]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  const handleToggleOption = (option) => {
    onToggleOption(option);
    trigger("topography"); // Trigger validation after toggling an option
  };

  return (
    <div ref={dropdownRef} className="relative">
      <div
        className="flex items-center justify-between form-select p-2 border border-mediumgray cursor-pointer"
        onClick={() => toggleDropdown(!isOpen)}
      >
        <div className="truncate">
          {selectedValues?.length > 0
            ? selectedValues.join(", ")
            : "Select options..."}
        </div>

        <HiChevronDown />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full bg-white border border-mediumgray">
          {[
            "High side",
            "Level block",
            "Low side",
            "Irregular block",
            "Unusable land",
          ].map((option) => (
            <div
              key={option}
              className="flex items-center text-xs p-2 cursor-pointer hover:bg-lightgray"
              onClick={() => handleToggleOption(option)}
            >
              <input
                type="checkbox"
                checked={selectedValues?.includes(option) || false}
                readOnly
                className="mr-2"
              />

              {option}
            </div>
          ))}
        </div>
      )}
      {errors.topography && (
        <span className="form-error-message">{errors.topography.message}</span>
      )}
    </div>
  );
};

const PropertyForm = ({ property, onSubmitForm }) => {
  const { address, waterViews } = property || {};
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(property);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
    reset,
  } = useForm({
    defaultValues: formData,
    // Ensure that fields are not unregistered when they are removed from the UI
    shouldUnregister: false,
  });
  const medianPrice = watch("medianPrice");

  useEffect(() => {
    setFormData(property);
    reset(property); // Reset form with new property data
  }, [property, reset]);

  const [editMode, setEditMode] = useState(false);
  const [topographyOpen, setTopographyOpen] = useState(false);

  const prevStep = () => {
    setStep(step - 1);
    reset(formData);
  };

  const selectedFinish = watch("finishes");
  const selectedWaterView = watch("waterViews");
  const selectedTopography = watch("topography");
  const propertyType = watch("propertyType"); // Watch propertyType

  const onSubmit = (data) => {
    const updatedData = { ...formData, ...data };

    setFormData(updatedData);

    onSubmitForm(updatedData);
  };

  const handleSelectFinish = (value) => {
    setValue("finishes", value);
    trigger("finishes"); // Trigger validation after setting the value
    setEditMode(false);
  };

  const handleEditFinish = () => {
    setEditMode(true);
    setValue("finishes", ""); // Clear the value when editing
    trigger("finishes"); // Trigger validation after setting the value
  };

  const handleSelectWaterView = (value) => {
    setValue("waterViews", value);
    trigger("waterViews"); // Trigger validation after setting the value
    setEditMode(false);
  };

  const handleEditWaterView = () => {
    setEditMode(true);
    setValue("waterViews", ""); // Clear the value when editing
    trigger("waterViews"); // Trigger validation after setting the value
  };

  const handleToggleTopographyOption = (option) => {
    let newSelectedTopography = [...(selectedTopography || [])];
    if (option === "High side") {
      if (selectedTopography?.includes("Low side")) {
        // Remove "Low side" if "High side" is selected
        newSelectedTopography = selectedTopography.filter(
          (item) => item !== "Low side"
        );
      }
    } else if (option === "Low side") {
      if (selectedTopography?.includes("High side")) {
        // Remove "High side" if "Low side" is selected
        newSelectedTopography = selectedTopography.filter(
          (item) => item !== "High side"
        );
      }
    }

    // Toggle the current option
    if (newSelectedTopography?.includes(option)) {
      newSelectedTopography = newSelectedTopography.filter(
        (item) => item !== option
      );
    } else {
      newSelectedTopography.push(option);
    }

    setValue("topography", newSelectedTopography);
    trigger("topography"); // Trigger validation after changing value
  };

  const toggleTopographyDropdown = () => {
    setTopographyOpen(!topographyOpen);
  };

  const [aiLoading, setAILoading] = useState(false);

  useEffect(() => {
    // AI Scan Function
    const aiScan = async () => {
      if (!property.autoFillAI) {
        try {
          setAILoading(true);

          // Call the AI Cleanup API
          const response = await axios.post("/userProperty/ai-cleanup", {
            address: property.address,
          });

          // Extract data from the response
          const result = response.data?.data || {};

          // Update only the fields that are present in the AI response
          Object.keys(result).forEach((key) => {
            if (result[key] !== null && result[key] !== undefined) {
              setValue(key, result[key]); // Update the field in the form
            }
          });

          // Update the local property state with the new data
          setFormData((prev) => ({ ...prev, ...result }));
        } catch (error) {
          console.error("Error during AI cleanup:", error.message);
        } finally {
          setAILoading(false);
        }
      }
    };

    aiScan();
  }, [property, setValue, setFormData]); // Add dependencies appropriately

  const debounceRef = useRef(null);
  const lastFetchedParams = useRef({
    suburb: property?.suburb || null,
    postcode: property?.postcode || null,
    propertyType:
      property?.propertyType === "ApartmentUnitFlat"
        ? "unit"
        : property?.propertyType
        ? "house"
        : null,
    bedrooms: property?.bedrooms || null,
  }); // Store the last parameters used for the API call

  useEffect(() => {
    const fetchMedianPrice = async () => {
      try {
        const suburb = watch("suburb");
        const postcode = watch("postcode");
        const propertyType = watch("propertyType");
        const bedrooms = watch("bedrooms");

        // Check if required fields are missing
        if (!suburb || !postcode || !propertyType || bedrooms == null) {
          return; // Avoid API calls if any required field is missing
        }

        // Check if the current parameters are the same as the last fetched ones
        const currentParams = { suburb, postcode, propertyType, bedrooms };
        const paramsUnchanged = Object.keys(currentParams).every(
          (key) => currentParams[key] === lastFetchedParams.current[key]
        );

        if (paramsUnchanged) {
          return; // Skip API call if parameters are unchanged
        }

        // Update last fetched parameters
        lastFetchedParams.current = currentParams;

        // Make the API call
        const response = await axios.get("/property/suburbMedianPrice", {
          params: currentParams,
        });

        if (response.data.success) {
          setValue("medianPrice", response.data.data.medianPrice); // Update medianPrice
          setValue("medianPriceSource", response.data.data.medianPriceSource); // Update medianPrice
        }
      } catch (error) {
        console.error("Error fetching median price:", error);
      }
    };

    // Debounce API calls to prevent excessive requests
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      fetchMedianPrice();
    }, 1000); // Debounce delay: 2000ms
  }, [
    watch("suburb"),
    watch("postcode"),
    watch("propertyType"),
    watch("bedrooms"),
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      {step === 1 && (
        <div className="space-y-4">
          {property.domainPrice &&
            property.domainPrice.lowerPrice !== undefined &&
            property.domainPrice.upperPrice !== undefined && (
              <div className="flex flex-col items-center justify-center">
                <h4>
                  {formatCurrency(property.domainPrice.lowerPrice)} -{" "}
                  {formatCurrency(property.domainPrice.upperPrice)}
                </h4>
                <p>Domain Price</p>
              </div>
            )}

          <div className="max-w-lg mx-auto">
            <Slider {...mapSettings}>
              {/* Slide for the image */}
              <div>
                <img
                  src={property?.media?.[0]?.url || NoImage}
                  alt="Property"
                  className="w-full h-auto object-cover"
                />
              </div>

              {/* Slide for the map */}
              <div>
                <GoogleMaps
                  lat={property?.latitude}
                  lon={property?.longitude}
                />
              </div>
            </Slider>
          </div>

          {/* <div className="w-full flex justify-end">
            <Button
              className="black-button"
              onClick={aiScan}
              loading={aiLoading}
              disabled={aiLoading}
            >
              Auto-Fill with AI
            </Button>
          </div> */}

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 sm:col-span-6">
              <label className="form-label">Property Address</label>
              <input
                type="text"
                className={`form-input border ${
                  errors.propertyAddress
                    ? "border-red-500"
                    : "border-mediumgray"
                }`}
                {...register("propertyAddress", {
                  required: false,
                })}
                value={address}
                disabled
                readOnly
              />
              {errors.propertyAddress && (
                <span className="form-error-message">
                  {errors.propertyAddress.message}
                </span>
              )}
            </div>

            <div className="col-span-12 sm:col-span-6 relative">
              <label className="form-label">Property Type</label>
              <select
                className={`form-select border ${
                  errors.propertyType ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("propertyType", {
                  required: "Property Type is required",
                })}
              >
                <option value="">Select Property Type</option>
                {[
                  "ApartmentUnitFlat",
                  "Duplex",
                  "House",
                  "Terrace",
                  "Townhouse",
                  "VacantLand",
                  "Villa",
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {errors.propertyType && (
                <span className="form-error-message">
                  {errors.propertyType.message}
                </span>
              )}
            </div>

            {property?.features && property?.features.length > 0 && (
              <div className="col-span-12">
                <label className="form-label">Property Features</label>
                {property?.features && property.features.join(", ")}
              </div>
            )}

            {/* Conditionally render Land Area and Frontage */}
            {propertyType !== "ApartmentUnitFlat" && (
              <>
                <div className="col-span-6">
                  <label className="form-label">Land Area</label>
                  <input
                    type="number"
                    step="any"
                    className={`form-input border ${
                      errors.landArea ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("landArea", {
                      required:
                        propertyType !== "ApartmentUnitFlat"
                          ? "Land Area is required"
                          : false,
                    })}
                  />
                  {errors.landArea && (
                    <span className="form-error-message">
                      {errors.landArea.message}
                    </span>
                  )}
                </div>

                <div className="col-span-6">
                  <label className="form-label">Frontage</label>
                  <input
                    type="number"
                    step="any" // Allows decimal values
                    className={`form-input border ${
                      errors.frontage ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("frontage")}
                  />
                  {errors.frontage && (
                    <span className="form-error-message">
                      {errors.frontage.message}
                    </span>
                  )}
                </div>
              </>
            )}

            <div className="col-span-12 relative">
              <label className="form-label">
                Median Price{" "}
                <span className="italic font-light text-xs">
                  *{watch("medianPriceSource") || property?.medianPriceSource}
                </span>
              </label>
              <input
                type="number"
                className={`form-input border ${
                  errors.medianPrice ? "border-red-500" : "border-mediumgray"
                }`}
                {...register("medianPrice", {
                  required: "Median price is required",
                })}
                value={medianPrice}
              />
              {errors.medianPrice && (
                <span className="form-error-message">
                  {errors.medianPrice.message}
                </span>
              )}
            </div>

            {propertyType !== "VacantLand" && (
              <>
                <div className="col-span-6 sm:col-span-4">
                  <label className="form-label">Bedrooms</label>
                  <input
                    type="number"
                    className={`form-input border ${
                      errors.bedrooms ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("bedrooms", {
                      required: "Number of Beds is required",
                    })}
                    min={0}
                  />
                  {errors.bedrooms && (
                    <span className="form-error-message">
                      {errors.bedrooms.message}
                    </span>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label className="form-label">Bathrooms</label>
                  <input
                    type="number"
                    className={`form-input border ${
                      errors.bathrooms ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("bathrooms", {
                      required: "Number of Baths is required",
                    })}
                    min={0}
                  />
                  {errors.bathrooms && (
                    <span className="form-error-message">
                      {errors.bathrooms.message}
                    </span>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label className="form-label">Car Spaces</label>
                  <input
                    type="number"
                    className={`form-input border ${
                      errors.carspaces ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("carspaces", {
                      required: "Number of Car Spaces is required",
                    })}
                    min={0}
                  />
                  {errors.carspaces && (
                    <span className="form-error-message">
                      {errors.carspaces.message}
                    </span>
                  )}
                </div>
              </>
            )}

            {propertyType !== "VacantLand" && (
              <div className="col-span-12">
                <FinishesSelector
                  register={register}
                  errors={errors}
                  selectedValue={editMode ? null : selectedFinish}
                  onChange={handleSelectFinish}
                  onEdit={handleEditFinish}
                />
              </div>
            )}

            <div className="col-span-12">
              <WaterViewsSelector
                register={register}
                errors={errors}
                selectedValue={editMode ? null : selectedWaterView}
                onChange={handleSelectWaterView}
                onEdit={handleEditWaterView}
              />
            </div>

            {/* Conditionally render Topography */}
            {propertyType !== "ApartmentUnitFlat" &&
              propertyType !== "VacantLand" && (
                <div className="col-span-12">
                  <label className="form-label">Topography</label>
                  <TopographyDropdown
                    selectedValues={selectedTopography}
                    onToggleOption={handleToggleTopographyOption}
                    isOpen={topographyOpen}
                    toggleDropdown={toggleTopographyDropdown}
                    errors={errors}
                    register={register}
                    trigger={trigger}
                  />
                </div>
              )}

            {propertyType !== "ApartmentUnitFlat" &&
              propertyType !== "VacantLand" && (
                <div className="col-span-6">
                  <label className="form-label">Build Construction Type</label>
                  <select
                    className={`form-input border ${
                      errors.buildType ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("buildType", {
                      required:
                        propertyType !== "ApartmentUnitFlat"
                          ? "Build Construction Type is required"
                          : false,
                    })}
                  >
                    <option value="">Select Construction Type</option>
                    <option value="1 storey">1 storey</option>
                    <option value="2 storey">2 storey</option>
                    <option value="3 story">3 story</option>
                    <option value="4+ storey">4+ storey</option>
                  </select>
                  {errors.buildType && (
                    <span className="form-error-message">
                      {errors.buildType.message}
                    </span>
                  )}
                </div>
              )}

            {propertyType !== "ApartmentUnitFlat" &&
              propertyType !== "VacantLand" && (
                <div className="col-span-6">
                  <label className="form-label">Granny Flat</label>
                  <select
                    className={`form-select border ${
                      errors.grannyFlat ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("grannyFlat", {
                      required:
                        propertyType !== "ApartmentUnitFlat"
                          ? "Granny Flat selection is required"
                          : false,
                    })}
                  >
                    <option value="">Select Granny Flat</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.grannyFlat && (
                    <span className="form-error-message">
                      {errors.grannyFlat.message}
                    </span>
                  )}
                </div>
              )}

            {propertyType !== "ApartmentUnitFlat" &&
              propertyType !== "VacantLand" && (
                <div className="col-span-6">
                  <label className="form-label">Wall Material</label>
                  <select
                    className={`form-input border ${
                      errors.wallMaterial
                        ? "border-red-500"
                        : "border-mediumgray"
                    }`}
                    {...register("wallMaterial", {
                      required:
                        propertyType !== "ApartmentUnitFlat"
                          ? "Wall Material is required"
                          : false,
                    })}
                  >
                    <option value="">Select Wall Material</option>
                    <option value="Brick">Brick</option>
                    <option value="Double brick">Double brick</option>
                    <option value="Clad">Clad</option>
                    <option value="Fibro">Fibro</option>
                    <option value="Hebel">Hebel</option>
                  </select>
                  {errors.wallMaterial && (
                    <span className="form-error-message">
                      {errors.wallMaterial.message}
                    </span>
                  )}
                </div>
              )}

            {propertyType !== "ApartmentUnitFlat" &&
              propertyType !== "VacantLand" && (
                <div className="col-span-6">
                  <label className="form-label">Pool</label>
                  <select
                    className={`form-select border ${
                      errors.pool ? "border-red-500" : "border-mediumgray"
                    }`}
                    {...register("pool", {
                      required:
                        propertyType !== "ApartmentUnitFlat"
                          ? "Pool selection is required"
                          : false,
                    })}
                  >
                    <option value="">Select Pool</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.pool && (
                    <span className="form-error-message">
                      {errors.pool.message}
                    </span>
                  )}
                </div>
              )}

            {propertyType !== "ApartmentUnitFlat" &&
              propertyType !== "VacantLand" && (
                <div className="col-span-6">
                  <label className="form-label">Tennis Court</label>
                  <select
                    className={`form-select border ${
                      errors.tennisCourt
                        ? "border-red-500"
                        : "border-mediumgray"
                    }`}
                    {...register("tennisCourt", {
                      required:
                        propertyType !== "ApartmentUnitFlat"
                          ? "Tennis Court selection is required"
                          : false,
                    })}
                  >
                    <option value="">Select Tennis Court</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.tennisCourt && (
                    <span className="form-error-message">
                      {errors.tennisCourt.message}
                    </span>
                  )}
                </div>
              )}

            {propertyType !== "ApartmentUnitFlat" && (
              <div className="col-span-6">
                <label className="form-label">Street Traffic</label>
                <select
                  className={`form-select border ${
                    errors.streetTraffic
                      ? "border-red-500"
                      : "border-mediumgray"
                  }`}
                  {...register("streetTraffic", {
                    required:
                      propertyType !== "ApartmentUnitFlat"
                        ? "Street Traffic selection is required"
                        : false,
                  })}
                >
                  <option value="">Select Street Traffic</option>
                  <option value="Low traffic">Low traffic</option>
                  <option value="Moderate traffic">Moderate traffic</option>
                  <option value="High traffic">High traffic</option>
                </select>
                {errors.streetTraffic && (
                  <span className="form-error-message">
                    {errors.streetTraffic.message}
                  </span>
                )}
              </div>
            )}

            {propertyType !== "ApartmentUnitFlat" &&
              propertyType !== "VacantLand" && (
                <div className="col-span-12 sm:col-span-6">
                  <label className="form-label">Development Potential</label>
                  <select
                    className={`form-select border ${
                      errors.developmentPotential
                        ? "border-red-500"
                        : "border-mediumgray"
                    }`}
                    {...register("developmentPotential")}
                  >
                    <option value="">Select Development Potential</option>
                    <option value="Childcare">Childcare</option>
                    <option value="Duplex site">Duplex site</option>
                    <option value="Townhouse site">Townhouse site</option>
                    <option value="Unit site">Unit site</option>
                  </select>
                  {errors.developmentPotential && (
                    <span className="form-error-message">
                      {errors.developmentPotential.message}
                    </span>
                  )}
                </div>
              )}

            <div className="col-span-12 sm:col-span-6">
              <label className="form-label">Additional Information</label>
              <textarea
                className={`form-textarea border ${
                  errors.additionalInformation
                    ? "border-red-500"
                    : "border-mediumgray"
                }`}
                {...register("additionalInformation")}
                rows={3}
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-end gap-4 mt-6">
        <Button type="submit" className="black-button">
          Update
        </Button>
      </div>
    </form>
  );
};

const PropertyResult = ({
  property,
  onEdit,
  updateBoxLoading,
  updateBoxStatus,
  newUpdate = false,
  setPostListClick,
}) => {
  const [activeTab, setActiveTab] = useState("");
  const [stale, setStale] = useState(false);
  const [newTranscript, setNewTranscript] = useState(
    property?.priceProcessTranscript || ""
  );
  const handleDelete = async (fieldPath) => {
    try {
      const deleteData = {
        fieldPath, // This will be something like `soldProperties[0]`
        remove: true, // Mark that this field is to be removed
      };

      await axios.put("/userProperty", {
        _id: property._id,
        address: property.address,
        deleteData,
      });
    } catch (error) {
      console.error("Error deleting post list item:", error);
    }
  };

  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);

  const [logicalPrice, setLogicalPrice] = useState(null);
  const [microPockets, setMicroPockets] = useState(null);
  const [saleProperties, setSaleProperties] = useState([]);
  const [soldProperties, setSoldProperties] = useState([]);
  const [areaDynamics, setAreaDynamics] = useState(null);
  const [logicalReasoning, setLogicalReasoning] = useState(null);
  const [engagedPurchaser, setEngagedPurchaser] = useState(null);
  const [pieChartData, setPieChartData] = useState([["Process", "Count"]]);

  const [mediaItems, setMediaItems] = useState([]);
  const [pdfItem, setPdfItem] = useState();
  const [openCarousel, setOpenCarousel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [recentAreaSoldProcess, setRecentAreaSoldProcess] = useState(
    property?.recentAreaSoldProcess || []
  );

  const [duplexProperties, setDuplexProperties] = useState([]);
  const [highEndProperties, setHighEndProperties] = useState([]);
  const [lowEndProperties, setLowEndProperties] = useState([]);
  const [isPropertyClicked, setIsPropertyClicked] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [recommendedSaleProcess, setRecommendedSaleProcess] = useState(null);

  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
    setIsPropertyClicked(true);
  };

  useEffect(() => {
    if (!property?._id) return; // Ensure property exists before making API calls

    const fetchAreaDynamics = async () => {
      try {
        const response = await axios.get(`/property/suburb/${property.suburb}`);

        setAreaDynamics(response?.data?.data);

        let formattedData = [["Process", "Count"]]; // Initialize with header row

        if (property.propertyType === "ApartmentUnitFlat") {
          const unitSoldStats = response?.data?.data.unitSoldStats;

          formattedData.push([
            "Private treaty adjustment",
            unitSoldStats?.numberSold,
          ]);
          formattedData.push(["Auction", unitSoldStats?.auctionNumberSold]);
          formattedData.push([
            "Not sold at auction",
            unitSoldStats?.auctionNumberAuctioned -
              unitSoldStats?.auctionNumberSold,
          ]);
        } else {
          const houseSoldStats = response?.data?.data.houseSoldStats; // Assuming correct data path

          formattedData.push([
            "Private treaty adjustment",
            houseSoldStats?.numberSold,
          ]);
          formattedData.push(["Auction", houseSoldStats?.auctionNumberSold]);
          formattedData.push([
            "Not sold at auction",
            houseSoldStats?.auctionNumberAuctioned -
              houseSoldStats?.auctionNumberSold,
          ]);
        }

        setPieChartData(formattedData);
      } catch (error) {
        console.error("Error fetching area dynamics:", error);
      }
    };

    const fetchRecommendProperties = async () => {
      try {
        // not a good logic but for now to check if the form is updated
        if (
          property.engagedPurchaser &&
          property.microPockets &&
          property.logicalReasoning &&
          !newUpdate
        ) {
          setLogicalPrice(property.logicalPrice || null);
          setLogicalReasoning(property.logicalReasoning || null);
          setSaleProperties(property.recommendedSales || []);
          setSoldProperties(property.recommendedSold || []);
          setDuplexProperties(property.duplexProperties || []);
          setEngagedPurchaser(property.engagedPurchaser || null);
          setRecommendedSaleProcess(property.recommendedSaleProcess);
          setMicroPockets(property.microPockets);
          setHighEndProperties(property.highEndProperties);
          setLowEndProperties(property.lowEndProperties);
          return;
        }

        const response = await axios.post("/property/recommend", {
          property, // Send the whole property object in the request body
        });

        if (response.data.success) {
          setLogicalPrice(response?.data?.data?.logical?.logicalPrice || null);
          setLogicalReasoning(
            response?.data?.data?.logical?.logicalReasoning || null
          );
          setSaleProperties(response?.data?.data?.recommendedSales || []);
          setSoldProperties(response?.data?.data?.recommendedSold || []);
          setDuplexProperties(response?.data?.data?.duplexProperties || []);
          setEngagedPurchaser(response?.data?.data?.engagedPurchaser || null);
          setMicroPockets(response?.data?.data?.microPockets || null);
          setRecommendedSaleProcess(
            response?.data?.data?.recommendedSaleProcess
          );
          setHighEndProperties(response?.data?.data?.highEndProperties);
          setLowEndProperties(response?.data?.data?.lowEndProperties);
          const {
            logical,
            recommendedSales,
            recommendedSold,
            duplexProperties,
            engagedPurchaser,
            microPockets,
          } = response.data.data;

          await axios.put("/userProperty", {
            address: property.address,
            logicalPrice: logical.logicalPrice,
            logicalReasoning: logical.logicalReasoning,
            recommendedSales: recommendedSales,
            recommendedSold: recommendedSold,
            duplexProperties,
            engagedPurchaser,
            microPockets,
          });
        }
      } catch (error) {
        console.error("Error fetching recommended properties:", error);
      } finally {
        setLoading(false);
      }
    };

    const executeFetchFunctions = async () => {
      try {
        setLoading(true);
        await fetchAreaDynamics();
        await fetchRecommendProperties();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    executeFetchFunctions();

    // Only trigger the useEffect when property._id changes
  }, [property._id]);

  const convertDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  function getAverageValueInUnits(range) {
    // Remove dollar signs, commas, and split the range into two numbers
    const [low, high] = range
      .replace(/\$|,/g, "") // Remove dollar signs and commas
      .split("-") // Split by the hyphen into low and high values
      .map((str) => parseFloat(str.trim())); // Trim spaces and parse as numbers

    if (isNaN(low) || isNaN(high)) {
      return 0;
    }

    // Calculate the average
    const average = (low + high) / 2;

    // Return the average in units format
    return average;
  }

  // Create dataPoints for GoogleChart
  const dataPoints = [
    [
      property.landArea || 0,
      logicalPrice ? getAverageValueInUnits(logicalPrice) : 0,
      property.address,
    ],
    ...(() => {
      // Ensure soldProperties is defined and is an array
      if (!Array.isArray(soldProperties)) return [];

      return soldProperties
        .filter(
          ({ property }) =>
            (property?.landArea || property?.propertyDetails?.landArea) && // Ensure landArea exists
            (property?.price || property?.priceDetails?.price) // Ensure price exists
        )
        .map(({ property }) => [
          property?.landArea || property?.propertyDetails?.landArea || 0, // Fallback to 0 if no landArea
          property?.price || property?.priceDetails?.price || 0, // Fallback to 0 if no price
          property?.address ||
            property?.propertyDetails?.displayableAddress ||
            "Unknown Address", // Fallback to "Unknown Address"
        ]);
    })(),
  ];

  const [regenerateLogicalPrice, setRegenerateLogicalPrice] = useState(false);

  const handleSave = async (fieldPath, newValue) => {
    try {
      console.log(fieldPath, newValue);
      await axios.put("/userProperty", {
        address: property.address,
        [fieldPath]: newValue,
      });
    } catch (error) {
      console.error("Error updating post list:", error);
    }
  };

  const [selectedAddress, setSelectedAddress] = useState("");
  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
    handleAdd(address);
  };
  const [addLoading, setAddLoading] = useState(false);

  const handleAdd = async (address) => {
    try {
      setAddLoading(true);
      const response = await axios.post("/property/soldMatch", {
        sourceAddress: property?.address,
        address,
      });
      if (response.data.success) {
        setSoldProperties((prevProperties) => [
          ...prevProperties,
          response.data.data, // Add the new property to the existing list
        ]);
        showToast("success", "Property added successfully");
      } else {
        showToast("error", "Property not exists on domain");
      }
    } catch (error) {
      console.error("Error fetching recent area sold process:", error);
      showToast("error", "Property not exists on PriceFinder");
    } finally {
      setAddLoading(false);
      setSelectedAddress("");
    }
  };

  if (loading) {
    return <PageLoader text={"Loading your price and process..."} />;
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        className="max-w-3xl"
      >
        <PdfViewer pdf={pdfItem} />
      </Modal>
      <Carousel
        isVisible={openCarousel}
        onClose={() => {
          setOpenCarousel(false);
          setMediaItems([]);
        }}
        media={mediaItems}
      />
      <div className="price-process flex flex-col items-center justify-center overflow-x-hidden">
        {/* <div className="w-full h-[90vh] relative pb-16">
          <video
            autoPlay
            muted
            loop
            preload="metadata"
            playsInline
            webkit-playsinline="true"
            className="w-full h-full object-cover"
          >
            <source
              src="https://beleef-public.s3.ap-southeast-2.amazonaws.com/assets/videos/postlist-video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div> */}

        <div className="w-full max-w-4xl mx-auto flex flex-col items-center justify-center text-center space-y-2 overflow-hidden">
          <div className="relative">
            <div className="fixed top-[80px] left-0 right-0 z-10 bg-white px-6 py-1 w-full">
              <div className="max-w-md mx-auto grid grid-cols-4 gap-2">
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer flex flex-col items-center justify-center px-2 py-3 rounded font-semibold ${
                      activeTab === tab.tab
                        ? "bg-black text-white"
                        : "bg-lightgray"
                    }`}
                    onClick={() => {
                      setActiveTab(tab.tab);
                      if (tab.tab === "postlist") {
                        setPostListClick(true);
                      } else {
                        setPostListClick(false);
                      }
                    }}
                  >
                    <span className="text-xs ">{tab.title}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="pt-20 w-full flex justify-end gap-2">
            <Button className="gray-button" onClick={onEdit} disabled={loading}>
              Edit
            </Button>
          </div>
          <br></br>
          <p className="dark-gray mb-2">{property.address}</p>
          <p className="dark-gray">Agent {property.userId.name}</p>
          <br></br>

          {/* 
          the audio transcript component should be hidden on all the tabs other than the getStarted tab but should be in dom so that the audio player can be used to record or play the audio in the background 
           */}
          <div className={`${activeTab === "getStarted" ? "block" : "hidden"}`}>
            <AudioTranscript
              property={property}
              setNewTranscript={setNewTranscript}
              setStale={setStale}
            />
          </div>

          {activeTab === "getStarted" && (
            <div className="w-full space-y-8">
              <div class="text-start space-y-2">
                <h4>Assess situation</h4>
                <p>What is your situation?</p>
              </div>
              <div class="text-start space-y-2">
                <h4>Assess timeline</h4>
                <p>What is your timeline?</p>
              </div>
              <div class="text-start space-y-2">
                <h4>Other Options</h4>
                <p>
                  We want to establish a great reputation in the area and giving
                  people great advice is part of that -{" "}
                </p>

                <p>This is a great property - </p>
                <p>
                  Have you considered other options other then selling such as
                  renting?
                </p>
              </div>
              <div class="text-start space-y-2">
                <h4>Maximum outcome</h4>
                <p>
                  It’s not about selling the property. It’s about getting the
                  maximium outcome. That takes a process. Let’s go through that.
                </p>
              </div>
              {microPockets && (
                <Disclosure as="div" className="w-full" defaultOpen={false}>
                  <DisclosureButton className="rounded-lg w-full cursor-pointer bg-lightgray p-4 my-1 flex items-center justify-between text-black font-semibold">
                    <span className="font-bold">The micro pockets</span>
                    <HiChevronDown className="group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2">
                    <div
                      className="engaged-purchaser"
                      dangerouslySetInnerHTML={{ __html: microPockets }}
                    />
                  </DisclosurePanel>
                </Disclosure>
              )}
              <div className="w-full h-full grayscale flex flex-col items-center justify-center">
                <GoogleMaps lat={property.latitude} lon={property.longitude} />
              </div>

              <div class="w-full max-w-lg mx-auto space-y-4">
                <h4>YOUR AREA DYNAMICS</h4>
                {areaDynamics ? (
                  <div className="overflow-x-auto">
                    <table className="w-full text-sm text-start border border-mediumgray border-collapse">
                      <thead>
                        <tr className="bg-mediumgray border border-mediumgray">
                          <th className="py-2 px-3 border border-mediumgray"></th>
                          <th className="py-2 px-3 text-left border border-mediumgray">
                            <i className="fa-solid fa-house mr-2"></i> House
                          </th>
                          <th className="py-2 px-3 text-left border border-mediumgray">
                            <i className="fa-solid fa-building mr-2"></i> Unit
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Median Sale Price
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? `$${areaDynamics.houseStats.medianSalePrice?.toLocaleString()}`
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? `$${areaDynamics.unitStats.medianSalePrice?.toLocaleString()}`
                              : "N/A"}
                          </td>
                        </tr>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Annual Sales Volume
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.annualSalesVolume
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.annualSalesVolume
                              : "N/A"}
                          </td>
                        </tr>

                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            Suburb Growth
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.suburbGrowth
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.suburbGrowth
                              : "N/A"}
                          </td>
                        </tr>
                        <tr className="border-b border-mediumgray">
                          <td className="py-2 px-3 border border-mediumgray">
                            High Demand Area
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.houseStats
                              ? areaDynamics.houseStats.highDemandArea
                              : "N/A"}
                          </td>
                          <td className="py-2 px-3 border border-mediumgray">
                            {areaDynamics.unitStats
                              ? areaDynamics.unitStats.highDemandArea
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <i className="fa-solid fa-spinner animate-spin mr-2"></i>{" "}
                    Loading ...
                  </div>
                )}
              </div>

              <div class="space-y-8 flex flex-col items-center justify-center">
                <h4>MOST LIKELY ENGAGED PURCHASER</h4>

                {engagedPurchaser && (
                  <div
                    className="engaged-purchaser"
                    dangerouslySetInnerHTML={{ __html: engagedPurchaser }}
                  />
                )}
              </div>
            </div>
          )}

          {activeTab === "presentation" && (
            <div className="w-full space-y-8">
              <div class="space-y-4 flex flex-col items-center justify-center">
                <h4>PROCESS MAXIMISES THE OUTCOME</h4>
                <img
                  src={propertyImg2}
                  alt="Property"
                  class="w-full h-auto max-w-[600px]"
                />
              </div>

              <ProcessChain property={property} />

              <div className="max-w-sm mx-auto space-y-8 flex flex-col items-center justify-center">
                <div className="flex flex-row justify-between w-full text-sm">
                  <span>Buyers start logically</span>
                  <span>and finish emotionally</span>
                </div>
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          )}

          {activeTab === "logicalPrice" && (
            <div className="w-full space-y-8">
              <div className="space-y-8">
                {property.domainPrice &&
                  property.domainPrice.lowerPrice !== undefined &&
                  property.domainPrice.upperPrice !== undefined && (
                    <div className="flex flex-col items-center justify-center">
                      <h4>DOMAIN PRICE</h4>
                      <p>
                        {formatCurrency(property.domainPrice.lowerPrice)} -{" "}
                        {formatCurrency(property.domainPrice.upperPrice)}
                      </p>
                    </div>
                  )}

                <div>
                  <h4>LOGICAL PRICE</h4>

                  {/* Price and Info Icon */}
                  <div className="flex items-center justify-center gap-4">
                    {!loading ? (
                      <>
                        <span className="font-bold font-Inter">
                          <LogicalPriceEdit
                            value={logicalPrice ? logicalPrice : "N/A"}
                            onSave={(newValue) => {
                              handleSave("logicalPrice", newValue); // Call the save function
                              setLogicalPrice(newValue);
                            }}
                          />
                        </span>
                        <i
                          class="fas fa-sync-alt cursor-pointer"
                          onClick={() => setRegenerateLogicalPrice(true)}
                        ></i>
                      </>
                    ) : (
                      <i className="fa-solid fa-spinner animate-spin"></i>
                    )}
                    <Tooltip
                      className="w-[250px]"
                      text={<i className="fa fa-info-circle text-xs"></i>}
                      tooltip="This is just a logical estimated price, and is grounded on a comprehensive set of factors including recent local sales, property size, number of bedrooms, the topography of the land, street traffic, recent updates to the property, and various other determinants. The information is sourced from public datasets which, while extensive, might be incomplete or contain inaccuracies, so should not be solely relied upon. For a more precise and accurate estimation of price, we strongly recommend consulting with a licensed real estate agent or a registered valuer. Occasionally, we may send you updates on the property market"
                    />
                  </div>
                </div>

                {/* Logical Reasoning */}
                {logicalReasoning && (
                  <div className="text-start my-1 text-sm">
                    <span className="font-bold">Reasoning:</span>{" "}
                    <div
                      className="font-Inter"
                      dangerouslySetInnerHTML={{ __html: logicalReasoning }}
                    />
                  </div>
                )}

                {/* Property Details */}
                <div className="max-w-md mx-auto space-y-6">
                  {/* Property Image */}
                  {property?.media?.[0]?.url && (
                    <img
                      className="w-full h-auto rounded-lg grayscale"
                      src={property?.media?.[0]?.url}
                      alt="property"
                    />
                  )}

                  {/* Property Address */}
                  <p className="text-center">{property.address}</p>

                  {/* Property Features */}
                  <div className="flex items-center justify-center gap-4">
                    <div className="text-sm">
                      <i className="fa fa-bed me-2"></i>
                      {property.bedrooms || "N/A"}
                    </div>
                    <div className="text-sm">
                      <i className="fa fa-bath me-2"></i>
                      {property.bathrooms || "N/A"}
                    </div>
                    <div className="text-sm">
                      <i className="fa fa-car me-2"></i>
                      {property.carspaces || "N/A"}
                    </div>
                    <div className="text-sm">{property.propertyType}</div>
                  </div>

                  {/* Sale Details */}
                  <div className="flex justify-between gap-4 text-left text-sm">
                    <div className="flex flex-col">
                      <span className="font-semibold">Sale Price:</span>
                      <span>{formatCurrency(property.price)}</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold">Sale Date:</span>
                      <span>
                        {property.dateListed
                          ? convertDate(property.dateListed)
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold">Days to Sell:</span>
                      <span>{property.daysListed}</span>
                    </div>
                  </div>
                </div>
              </div>

              {soldProperties && soldProperties.length > 0 && (
                <div class="w-full space-y-8">
                  <h4>SOLD MATCHES</h4>
                  <p>
                    Getting the first part of the process right helps to create
                    the maximum outcome. This is a logical range derived from
                    market insights and the final outcome may vary significantly
                  </p>
                  <GooglePlacesAutocomplete
                    onSelectAddress={handleAddressSelect}
                    isNSWCheck={false}
                    address={selectedAddress}
                    setAddress={setSelectedAddress}
                    placeholder="Add a property..."
                  />
                  {addLoading && (
                    <p>
                      <i className="fa-solid fa-spinner animate-spin mr-2"></i>{" "}
                      Loading ...
                    </p>
                  )}
                  <Slider {...settings} className="w-full">
                    {soldProperties.map(({ property }, index) => (
                      <div
                        key={property._id}
                        className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                      >
                        <img
                          className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                          src={
                            property?.media?.[0]?.url ||
                            "/placeholder-image.jpg"
                          }
                          alt="property"
                          onClick={() => handlePropertyClick(property)}
                        />
                        <div className="text-center mt-4 space-y-3">
                          <p className="font-semibold text-center">
                            <EditableField
                              value={formatCurrency(
                                property?.priceDetails?.price || property?.price
                              )}
                              onSave={(newValue) => {
                                // Remove currency formatting for backend update
                                const numericValue = newValue
                                  .replace(/\$/g, "") // Remove dollar signs
                                  .replace(/,/g, "") // Remove commas
                                  .trim(); // Remove any leading/trailing whitespace

                                // Create a new array with the updated property (immediately)
                                const updatedProperties = soldProperties.map(
                                  (prop, propIndex) =>
                                    propIndex === index
                                      ? {
                                          ...prop,
                                          property: {
                                            ...prop.property,
                                            price: numericValue,
                                          },
                                        }
                                      : prop
                                );

                                // Optimistically update the UI
                                setSoldProperties(updatedProperties);

                                handleSave(
                                  `recommendedSold`,
                                  updatedProperties
                                );
                              }}
                              onClick={(e) => e.stopPropagation()} // Prevent event bubbling to the parent
                              className="justify-center"
                            />
                          </p>
                          <p
                            className="text-sm"
                            onClick={() => handlePropertyClick(property)}
                          >
                            {property?.propertyDetails?.displayableAddress ||
                              property?.address}
                          </p>
                          <div className="flex items-center justify-center gap-4">
                            <div className="text-sm">
                              <i className="fa fa-bed me-2"></i>
                              {property?.propertyDetails?.bedrooms ||
                                property?.bedrooms ||
                                "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-bath me-2"></i>
                              {property?.propertyDetails?.bathrooms ||
                                property?.bathrooms ||
                                "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-car me-2"></i>
                              {property?.propertyDetails?.carspaces ||
                                property?.carspaces ||
                                "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}

              {saleProperties && saleProperties.length > 0 && (
                <div class="w-full space-y-8">
                  <h4>ON MARKET MATCHES</h4>
                  <Slider {...settings} className="w-full">
                    {saleProperties.map(({ property }, index) => (
                      <div
                        key={property._id}
                        className="max-w-[320px] sm:max-w-[350px] cursor-pointer flex flex-column bg-lightgray hover:bg-mediumgray py-4 px-6"
                      >
                        <img
                          className="rounded-lg mx-auto max-w-[250px] h-[200px] sm:max-w-[300px] sm:h-[250px] grayscale"
                          src={
                            property?.media?.[0]?.url ||
                            "/placeholder-image.jpg"
                          }
                          alt="property"
                          onClick={() => handlePropertyClick(property)}
                        />
                        <div className="text-center mt-4 space-y-3">
                          <p className="font-semibold text-center">
                            <EditableField
                              value={formatCurrency(
                                property?.priceDetails?.price || property?.price
                              )}
                              onSave={(newValue) => {
                                // Remove currency formatting for backend update
                                const numericValue = newValue
                                  .replace(/\$/g, "") // Remove dollar signs
                                  .replace(/,/g, "") // Remove commas
                                  .trim(); // Remove any leading/trailing whitespace

                                // Create a new array with the updated property (immediately)
                                const updatedProperties = saleProperties.map(
                                  (prop, propIndex) =>
                                    propIndex === index
                                      ? {
                                          ...prop,
                                          property: {
                                            ...prop.property,
                                            price: numericValue,
                                          },
                                        }
                                      : prop
                                );

                                // Optimistically update the UI
                                setSaleProperties(updatedProperties);

                                handleSave(
                                  `recommendedSales`,
                                  updatedProperties
                                );
                              }}
                              onClick={(e) => e.stopPropagation()} // Prevent event bubbling to the parent
                              className="justify-center"
                            />
                          </p>
                          <p
                            className="text-sm"
                            onClick={() => handlePropertyClick(property)}
                          >
                            {property?.propertyDetails?.displayableAddress ||
                              property?.address}
                          </p>
                          <div className="flex items-center justify-center gap-4">
                            <div className="text-sm">
                              <i className="fa fa-bed me-2"></i>
                              {property?.propertyDetails?.bedrooms ||
                                property?.bedrooms ||
                                "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-bath me-2"></i>
                              {property?.propertyDetails?.bathrooms ||
                                property?.bathrooms ||
                                "N/A"}
                            </div>
                            <div className="text-sm">
                              <i className="fa fa-car me-2"></i>
                              {property?.propertyDetails?.carspaces ||
                                property?.carspaces ||
                                "N/A"}
                            </div>
                          </div>
                          {/* <div className="text-xs text-start space-y-1">
                            <p className="font-semibold">Key Matches</p>
                            <p className="italic">{keyMatches.join(", ")}</p>
                          </div> */}
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          )}

          {activeTab === "process" && (
            <div className="w-full space-y-16">
              {soldProperties && soldProperties.length > 0 && (
                <div className="space-y-4 w-full">
                  <h4>SCORE MATCH ON MARKET</h4>
                  <GoogleChart dataPoints={dataPoints} />
                </div>
              )}

              <div className="space-y-4 w-full">
                <h4>AREA SALES PROCESS BREAKDOWN</h4>
                <GooglePieChart data={pieChartData} />
              </div>

              {recentAreaSoldProcess && recentAreaSoldProcess.length > 0 && (
                <RecentAreaSoldProcess
                  recentAreaSoldProcess={recentAreaSoldProcess}
                  propertyType={property.propertyType}
                  bedrooms={property.bedrooms}
                  sourceAddress={property.address}
                />
              )}
            </div>
          )}

          {activeTab === "solution" && (
            <div className="w-full space-y-8">
              {logicalPrice && (
                <RecommendedSalesProcess
                  recommendedSaleProcess={recommendedSaleProcess}
                  highEndProperties={highEndProperties}
                  lowEndProperties={lowEndProperties}
                  address={property.address}
                  developmentPotential={property.developmentPotential}
                  logicalPrice={logicalPrice}
                  setLogicalPrice={setLogicalPrice}
                  soldProperties={soldProperties}
                  areaDynamics={areaDynamics}
                  propertyType={property.propertyType}
                  duplexProperties={duplexProperties}
                />
              )}

              {property.fiveStepProcess && (
                <div class="flex flex-col items-center justify-center">
                  <h4>5 STEP PROCESS</h4>
                  <FiveStepProcess
                    address={property.address}
                    fiveStepProcess={property.fiveStepProcess}
                  />
                </div>
              )}
            </div>
          )}

          {activeTab === "marketing" && (
            <div className="w-full">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                <Card
                  title={"AD COPY"}
                  mediaUrl={
                    "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/adcopy/Kangaroo+Adcopy.pdf"
                  }
                  type={"pdf"}
                  onClick={() => {
                    setPdfItem(
                      "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/adcopy/Kangaroo+Adcopy.pdf"
                    );
                    setOpenModal(true);
                  }}
                />

                <Card
                  title={"BROCHURE 8"}
                  mediaUrl={brochure8Folder1[0].default || brochure8Folder1[0]}
                  type={"image"}
                  onClick={() => {
                    setMediaItems(
                      brochure8Folder1
                        .concat(brochure8Folder2)
                        ?.map((image) => ({
                          src: image.default || image,
                          type: "image",
                        }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"BROCHURE 16"}
                  mediaUrl={
                    brochure16Folder1[1].default || brochure16Folder1[1]
                  }
                  type={"image"}
                  onClick={() => {
                    setMediaItems(
                      [
                        ...brochure16Folder1,
                        // ...brochure16Folder2,
                        // ...brochure16Folder3,
                      ]?.map((image) => ({
                        src: image.default || image,
                        type: "image",
                      }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"DRONE PHOTOS"}
                  mediaUrl={droneFolder1[0].default || droneFolder1[0]}
                  type={"image"}
                  onClick={() => {
                    setMediaItems(
                      droneFolder1?.map((image) => ({
                        src: image.default || image,
                        type: "image",
                      }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"DUSK PHOTOS"}
                  mediaUrl={duskFolder1[0].default || duskFolder1[0]}
                  type={"image"}
                  onClick={() => {
                    setMediaItems(
                      duskFolder1?.map((image) => ({
                        src: image.default || image,
                        type: "image",
                      }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"FLOORPLAN"}
                  mediaUrl={floorplanFolder1[0].default || floorplanFolder1[0]}
                  type={"image"}
                  onClick={() => {
                    setMediaItems(
                      floorplanFolder1?.map((image) => ({
                        src: image.default || image,
                        type: "image",
                      }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"MAILCARDS"}
                  mediaUrl={mailCardsFolder[0]}
                  type={"image"}
                  onClick={() => {
                    setMediaItems(
                      mailCardsFolder?.map((image) => ({
                        src: image,
                        type: "image",
                      }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"PHOTOS"}
                  mediaUrl={photosFolder1[0].default || photosFolder1[0]}
                  type={"image"}
                  onClick={() => {
                    setMediaItems(
                      photosFolder1?.map((image) => ({
                        src: image.default || image,
                        type: "image",
                      }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"SOCIAL MEDIA"}
                  mediaUrl={
                    socialMediaFolder1[0].default || socialMediaFolder1[0]
                  }
                  type={"image"}
                  onClick={() => {
                    setMediaItems(
                      socialMediaFolder1?.map((image) => ({
                        src: image.default || image,
                        type: "image",
                      }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"STANDARD"}
                  mediaUrl={
                    "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-standard/landScape/1.mp4 "
                  }
                  type={"video"}
                  onClick={() => {
                    setMediaItems(
                      [
                        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-standard/landScape/1.mp4 ",
                        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-standard/landScape/2.mp4",
                        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-standard/landScape/3.mp4",
                        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-standard/landScape/4.mp4",
                      ]?.map((video) => ({
                        src: video,
                        type: "video",
                      }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"STORYTELLING"}
                  mediaUrl={
                    "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-storytelling/landScape/1.mp4"
                  }
                  type={"video"}
                  onClick={() => {
                    setMediaItems(
                      [
                        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-storytelling/landScape/1.mp4",
                        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-storytelling/landScape/2.mp4",
                        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-storytelling/landScape/3.mp4",
                        "https://beleef-public-uploads.s3.ap-southeast-2.amazonaws.com/pictures/video-storytelling/landScape/4.mp4",
                      ]?.map((video) => ({
                        src: video,
                        type: "video",
                      }))
                    );
                    setOpenCarousel(true);
                  }}
                />
                <Card
                  title={"SIGNBOARDS"}
                  mediaUrl={signboardFolder1[0].default || signboardFolder1[0]}
                  type={"image"}
                  onClick={() => {
                    setMediaItems(
                      signboardFolder1
                        ?.concat(signboardFolder2)
                        ?.map((image) => ({
                          src: image,
                          type: "image",
                        }))
                    );
                    setOpenCarousel(true);
                  }}
                />
              </div>
            </div>
          )}

          {activeTab === "tools" && (
            <div className="w-full">
              <div class="w-full space-y-8 flex flex-col items-center justify-center">
                <h4>PROCESS TIMELINE</h4>
                <img
                  src={propertyImg5}
                  alt="Property"
                  class="w-full h-auto max-w-[600px]"
                />
              </div>

              <div class="flex gap-4 items-center justify-center">
                <div class="flex items-center justify-center w-24 h-24 bg-neutral-100 rounded-full text-sm text-center">
                  Price
                </div>
                <div class="flex items-center justify-center w-24 h-24 bg-neutral-100 rounded-full text-sm text-center">
                  Marketing
                </div>
                <div class="flex items-center justify-center w-24 h-24 bg-neutral-100 rounded-full text-sm text-center">
                  Process
                </div>
              </div>

              <div class="w-full space-y-8 flex flex-col items-center justify-center">
                <h4>THE GAP</h4>
                <img
                  src={propertyImg7}
                  alt="Property"
                  class="w-full h-auto max-w-[600px]"
                />
              </div>
            </div>
          )}
          {activeTab === "postlist" && (
            <div className="w-full">
              <PostList
                property={{
                  ...property,
                  priceProcessTranscript: newTranscript,
                  isPostListAnswersStale: stale,
                }}
                updateBoxLoading={updateBoxLoading}
                updateBoxStatus={updateBoxStatus}
              />
            </div>
          )}
        </div>
        <RegenerateLogicalPrice
          property={property}
          regenerateLogicalPrice={regenerateLogicalPrice}
          setRegenerateLogicalPrice={setRegenerateLogicalPrice}
          soldProperties={soldProperties}
          setLogicalPrice={setLogicalPrice}
          setLogicalReasoning={setLogicalReasoning}
        />

        <PropertyDetailsModal
          isOpen={isPropertyClicked}
          onClose={() => setIsPropertyClicked(false)}
          property={selectedProperty}
        />
      </div>
    </>
  );
};

const RegenerateLogicalPrice = ({
  property,
  regenerateLogicalPrice,
  setRegenerateLogicalPrice,
  soldProperties,
  setLogicalPrice,
  setLogicalReasoning,
}) => {
  const [checkedProperties, setCheckedProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  // Handle checkbox selection
  const handleCheckboxChange = (propertyId) => {
    setCheckedProperties(
      (prevChecked) =>
        prevChecked.includes(propertyId)
          ? prevChecked.filter((id) => id !== propertyId) // Uncheck
          : [...prevChecked, propertyId] // Check
    );
  };

  // Handle regenerate button click
  const handleRegenerate = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/property/regenerateLogicalPrice", {
        property: property,
        checkedProperties: soldProperties.filter(({ property }) =>
          checkedProperties.includes(property._id || property.id)
        ),
      });
      if (response.data.success) {
        setRegenerateLogicalPrice(false);
        setLogicalPrice(response.data.data.logicalPrice);
        setLogicalReasoning(response.data.data.logicalReasoning);
        await axios.put("/userProperty", {
          address: property.address,
          logicalPrice: response.data.data.logicalPrice,
          logicalReasoning: response.data.data.logicalReasoning,
        });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={regenerateLogicalPrice}
      onClose={() => setRegenerateLogicalPrice(false)}
      title="Regenerate Logical Price"
    >
      {soldProperties && soldProperties.length > 0 && (
        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse text-center">
            <thead>
              <tr className="bg-lightgray">
                <th className="py-2 px-3">Select</th>
                <th className="py-2 px-3">Address</th>
                <th className="py-2 px-3">Price</th>
              </tr>
            </thead>
            <tbody>
              {soldProperties.map(({ property }, index) => (
                <tr key={property._id} className="border-b">
                  <td className="py-2 px-3">
                    <input
                      type="checkbox"
                      checked={checkedProperties.includes(
                        property._id || property.id
                      )}
                      onChange={() =>
                        handleCheckboxChange(property._id || property.id)
                      }
                    />
                  </td>
                  <td className="py-2 px-3 flex flex-col gap-2">
                    <img
                      src={property?.media?.[0]?.url}
                      alt="property"
                      className="w-auto h-24 sm:h-auto max-h-32"
                    ></img>
                    {property.address ||
                      property?.propertyDetails?.displayableAddress}
                    <div className="flex items-center justify-center gap-4">
                      <div className="text-xs">
                        <i className="fa fa-bed me-2"></i>
                        {property.bedrooms || "N/A"}
                      </div>
                      <div className="text-xs">
                        <i className="fa fa-bath me-2"></i>
                        {property.bathrooms || "N/A"}
                      </div>
                      <div className="text-xs">
                        <i className="fa fa-car me-2"></i>
                        {property.carspaces || "N/A"}
                      </div>

                      <div className="text-xs">
                        <i className="fas fa-chart-area me-2"></i>
                        {property.landArea + " m²" || "N/A"}
                      </div>
                    </div>
                  </td>
                  <td className="py-2 px-3">
                    {property?.priceDetails?.price || property?.price}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-end mt-4">
        <Button
          onClick={handleRegenerate}
          className="black-button"
          loading={loading}
        >
          Regenerate
        </Button>
      </div>
    </Modal>
  );
};

const PriceProcess = ({
  isCleaned,
  property, // Use 'property' directly
  updateBoxLoading,
  updateBoxStatus,
  setPostListClick,
}) => {
  const [formData, setFormData] = useState(property);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newUpdate, setNewUpdate] = useState(false);
  // Fields to compare and send in the PUT request
  const fieldsToCompare = [
    "propertyType",
    "bedrooms",
    "bathrooms",
    "carspaces",
    "landArea",
    "buildType",
    "wallMaterial",
    "pool",
    "tennisCourt",
    "waterViews",
    "finishes",
    "streetTraffic",
    "topography",
    "additionalInformation",
    "frontage",
    "configurationPlan",
    "grannyFlat",
    "developmentPotential",
    "medianPrice",
    "autoFillAI",
  ];

  // Function to extract only the relevant fields from an object
  const extractFields = (obj) => {
    return fieldsToCompare.reduce((result, field) => {
      if (obj.hasOwnProperty(field)) {
        result[field] = obj[field];
      }
      return result;
    }, {});
  };

  // Function to handle the form submission and pass data to PropertyResult
  const handleFormSubmit = async (data) => {
    try {
      updateBoxLoading(true);

      // Extract the relevant fields from both the property and the new data
      const initialFields = extractFields(property);
      const newFields = extractFields(data);

      // Check if there's any difference between the fields
      if (
        isEqual(initialFields, newFields) &&
        property.boxStatus[1].status === "complete"
      ) {
        console.log(
          "No changes detected in the relevant fields, skipping update."
        );
        setNewUpdate(false);
        updateBoxLoading(false);
        return; // Skip the update if there's no change
      }

      if (newFields.developmentPotential === "") {
        newFields.developmentPotential = null;
      }

      // Proceed with the update if the relevant fields have changed
      const response = await axios.put("/userProperty", {
        address: property.address,
        ...newFields, // Send only the relevant fields in the PUT request
        autoFillAI: true,
        // Include boxStatusUpdates if needed
        ...(data.boxStatus[1].status !== "complete" && {
          boxStatusUpdates: [
            {
              name: "priceProcess",
              status: "complete",
            },
            {
              name: "postList",
              status: "unlock",
            },
            {
              name: "authoriseSchedule",
              status: "unlock",
            },
          ],
        }),
      });

      if (response.data.success) {
        setNewUpdate(true);
        // Update formData with response data
        setFormData(response.data.data); // Assuming response.data.data contains the updated property data
        setIsSubmitted(true); // This will show the PropertyResult component
        setIsEditing(false);
        // Trigger the status update in the parent component with the latest boxStatus
        updateBoxStatus(response.data.data.boxStatus);
      }
    } catch (error) {
      console.log("Error in handleFormSubmit:", error.message);
    } finally {
      setIsEditing(false);
      // Trigger the loading state as false (done)
      updateBoxLoading(false);
    }
  };

  // Function to go back to editing the form
  const handleEdit = () => {
    setIsSubmitted(false); // Switch back to the form
    setIsEditing(true); // Enable editing mode
  };

  useEffect(() => {
    // Update the form data if the property changes
    setFormData(property);
  }, [property]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    // Check if the 'tab' parameter is set to 'authorise-and-schedule'
    if (searchParams.get("tab") === "price-and-process") {
      const styleSheets = document.styleSheets;
      for (let i = 0; i < styleSheets.length; i++) {
        try {
          const rules = styleSheets[i].cssRules;
          for (let j = 0; j < rules.length; j++) {
            // Find the rule that applies to .pac-container.pac-logo and remove it
            if (rules[j].selectorText === ".pac-container.pac-logo") {
              styleSheets[i].deleteRule(j);
              break;
            }
          }
        } catch (e) {
          // Ignore cross-origin or inaccessible stylesheets
          if (e.name !== "SecurityError") {
            console.error(e);
          }
        }
      }
    }
  }, []);

  return (
    <div className="w-full">
      {isEditing ? (
        <PropertyForm
          key={property.id}
          property={formData}
          onSubmitForm={handleFormSubmit}
        />
      ) : !isSubmitted && property.boxStatus[1].status !== "complete" ? (
        <PropertyForm property={formData} onSubmitForm={handleFormSubmit} />
      ) : (
        <PropertyResult
          property={formData}
          onEdit={handleEdit}
          updateBoxLoading={updateBoxLoading}
          updateBoxStatus={updateBoxStatus}
          newUpdate={newUpdate}
          setPostListClick={setPostListClick}
        />
      )}
    </div>
  );
};

export default PriceProcess;
