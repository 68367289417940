import Button from "components/ui/Button";
import PdfViewer from "components/ui/PdfViewer";
import React, { useState, useRef, useEffect, useContext } from "react";
import { fetchSignatureUrl, formatCurrency } from "utils/helperFunctions";
import { AuthContext } from "context/AuthContext";
import { FaPlus } from "react-icons/fa6";
import EditableField from "components/ui/EditableField";
import DeleteField from "components/ui/DeleteField";

const AuthoriseScheduleEditor = ({
  formData,
  onSaveTerms,
  recommendedSales,
  recommendedSold,
}) => {
  const { user } = useContext(AuthContext);
  const {
    name,
    email,
    mobile,
    company,
    companyAddress,
    licenseNumber,
    gst,
    abn,
    conjunctionAgent,
  } = user;
  const {
    startPrice,
    endPrice,
    terms,
    commissionFee,
    commissionRange,
    saleProcess,
    vendors,
    marketing,
    propertyAddress,
    status,
    termsConditions,
    solicitor,
    agentSignature,
    privateTerms,
    auctionTerms,
  } = formData;

  const uneditableContent = `
  <div class="terms-condition">
  <header>
      <h1>
          ${
            saleProcess === "Auction"
              ? `SALES INSPECTION REPORT AND <br>
          EXCLUSIVE AUCTION AGENCY <br>
          AGREEMENT AND CONTINUING AGENCY`
              : `SALES INSPECTION REPORT AND <br>
          EXCLUSIVE SELLING AGENCY <br>
          AGREEMENT AND CONTINUING AGENCY`
          }
      </h1>
  </header>
  
  <section>
      <div>
          <h2 style="margin-bottom:1px;">PART 1 | SALES INSPECTION REPORT</h2>
          <small>SCHEDULE 2, PART 1, CLAUSE 2 OF THE PROPERTY AND STOCK AGENTS REGULATION</small>
      </div>
      <br>
      ${vendors
        .map(
          (vendor) => `
      <div>
          <h3>VENDOR</h3>
          <div><strong>NAME:</strong> ${vendor.firstName} ${
            vendor.lastName
          }</div>
          <div><strong>ADDRESS:</strong> ${vendor.address}</div>
          <div><strong>PHONE:</strong> ${vendor.mobile}</div>
          <div><strong>EMAIL:</strong> ${vendor.email}</div>
          ${
            vendor.isCompany
              ? `
          <div><strong>COMPANY:</strong> ${vendor.company}</div>
          <div><strong>ABN:</strong> ${vendor.abn}</div>
          <div><strong>GST:</strong> ${vendor.gst}</div>
          `
              : ""
          }
      </div>
      `
        )
        .join("")}
      <br>
     
        ${
          conjunctionAgent === "Yes"
            ? `
      <div>
          <h3>LICENSEE</h3>
          <div><strong>BUSINESS NAME:</strong> Ausrealty (Riverwood) Pty Ltd (Licenced user of Ausrealty) <strong>ABN:</strong> 97 610 838 643 <strong>Registered for
                  GST:</strong> Yes </div>
          <div><strong>ADDRESS:</strong> 166 Belmore Road, Riverwood NSW 2210 </div>
          <div><strong>PHONE:</strong> 0290116888 </div>
          <div><strong>EMAIL:</strong> riverwood@ausrealty.com.au </div>
          <div><strong>LICENCE NUMBER:</strong> 10044297 </div>
      </div>
      <br>
      <div>
          <h3>CONJUNCTION AGENT</h3>
          <div><strong>NAME:</strong> ${company}</div>
          <div><strong>ABN:</strong> ${abn}</div>
          <div><strong>Registered for GST:</strong> ${gst}</div>
          <div><strong>ADDRESS:</strong> ${companyAddress}</div>
          <div><strong>PHONE:</strong> ${mobile}</div>
          <div><strong>EMAIL:</strong> ${email}</div>
          <div><strong>LICENCE NUMBER:</strong> ${licenseNumber}</div>
      </div>`
            : `
      <div>
          <h3>LICENSEE</h3>
          <div><strong>BUSINESS NAME:</strong> ${company} <strong>ABN:</strong> ${abn} <strong>Registered for GST:</strong> ${gst}</div>
          <div><strong>NAME:</strong> ${name}</div>
          <div><strong>ADDRESS:</strong> ${companyAddress}</div>
          <div><strong>PHONE:</strong> ${mobile}</div>
          <div><strong>EMAIL:</strong> ${email}</div>
          <div><strong>LICENCE NUMBER:</strong> ${licenseNumber}</div>
      </div>
  `
        }
  
      <br>
      <div>
          <h3>VENDORS SOLICITOR/CONVEYANCER</h3>
          <div><strong>COMPANY NAME:</strong> ${solicitor?.name}</div>
          <div><strong>ADDRESS:</strong> ${solicitor?.address}</div>
          <div><strong>PHONE:</strong> ${solicitor?.mobile}</div>
          <div><strong>EMAIL:</strong> ${solicitor?.email}</div>
      </div>
  
      <br>
      <div class="page-break"></div>
  
      <h3>
          PROPERTY
          <small>
              [This form is for the sale of Residential Property only and not
              for Rural Land]
          </small>
      </h3>
      <strong>ADDRESS:</strong> ${propertyAddress}
      <div>
          <strong>Occupation status of Property: </strong>
          <span>${status}</span>
      </div>
      <div>
          <strong>
              Other terms and conditions of sale known to the Agent:
          </strong>
          <span>To be advised, as per contract, not known yet.</span>
      </div>
      <div>
          <strong>Fittings and Fixtures included in the sale: </strong>
          <span>To be advised, as per contract, not known yet.</span>
      </div>
      <div>
          <strong>Fittings and Fixtures excluded in the sale: </strong>
          <span>To be advised, as per contract, not known yet.</span>
      </div>
      <div>
          <strong>
              Details of any Covenants, Easements, Defects, Local Government
              Notices or Orders affecting the property that are known to the
              Agent:
          </strong>
          <span>To be advised, as per contract, not known yet.</span>
      </div>
      <div>
          <strong>
              Agents Recommendation as to Most Suitable Method of Sale:
          </strong>
          <span>${saleProcess}</span>
      </div>
      <div>
          <strong>
              Agents estimate of the Selling Price (or Price Range) for the
              Property:
          </strong>
          <span>
              ${startPrice} - ${endPrice}
          </span>
      </div>
      <div>
          <strong>
              Any special Instructions about Marketing and Showing of the
              Property:
          </strong>
          <span>At Agent's discretion and accompanied by an agent.</span>
      </div>
      <div class="mt-3">
          <strong>SIGNATURE OF SALES INSPECTION REPORT</strong>
      </div>
      <div>
          <p>Agents Signature</p>
          <img src=${agentSignature} alt="agent sign" class="w-auto h-8"></img>
      </div>
      <div>
          <p>
              Date of Report <br /> agreement date comes here
          </p>
      </div>
  </section>
  
  <h2>
      PART 2 | PARTICULARS FOR EXCLUSIVE
      ${saleProcess === "Auction" ? "AUCTION" : "SELLING"} AGENCY AGREEMENT
      AND CONTINUING AGENCY AGREEMENT
  </h2>
  
  <section>
      <h3>
          A. AGENCY APPOINTMENT <small>[CLAUSE 2]</small>
      </h3>
  
      <div>
          <strong>EXCLUSIVE AGENCY PERIOD: </strong>
          <span>
              The vendor grants the Licensee exclusive selling rights over the
              Property for the period from
              <strong>${terms} days of agreement</strong>
          </span>
      </div>
  
      <div>
          <strong>Continuing Agency Period: </strong>
          <span>
              The Vendor grants the Licensee non-exclusive selling rights over
              the Property from the [day after above end date] being the day
              after the expiry of the Exclusive Agency Period, until the
              earlier of:
          </span>
          <ol type="a">
              <li>the sale of the Property; or </li>
              <li>
                  the termination of this Agreement
              </li>
          </ol>
      </div>
  </section>
  <section>
      <h3>B. METHOD OF SALE</h3>
  
      <div>
          <strong>Method of sale: </strong>
          <span>${saleProcess}</span>
      </div>
  
      ${
        saleProcess === "Auction" &&
        `<div>
          <strong>Auction date: </strong>
          <span>To be confirmed</span>
      </div>`
      }
  </section>
  <section>
      <h3>
          ${
            saleProcess === "Auction"
              ? `C. RESERVE PRICE
          <small> (FOR AUCTION SALES) [CLAUSE 3]</small>`
              : `C. PRICE AT WHICH THE PROPERTY IS TO BE OFFERED
          <small> (FOR PRIVATE TREAT SALES) [CLAUSE 3]</small>`
          }
      </h3>
  
      <div>
          <strong>
              ${saleProcess === "Auction" ? "RESERVE PRICE" : "PRICE"}:
          </strong>
          <span>
              ${saleProcess === "Auction" ? "To be advised" : "Offers Invited"}
          </span>
      </div>
  </section>
  <section>
      <h3>
          D. LICENSEE’S REMUNERATION <small>[CLAUSE 4]</small>
      </h3>
  
      <div>
          <p>
              <strong>
                  The Licensee’s GST inclusive commission shall be calculated on
                  the GST inclusive selling price in the following way:
              </strong>
              <strong>Commission payable ${commissionFee}%</strong>
          </p>
          <p>
              [e.g. % of sale price/fixed amount/% of sale price plus fixed
              amount/other]
          </p>
  
          <p>
              Should the sale price be more or less than the estimated selling
              price, the commission payable shall be calculated on the sale
              price alone, at the percentage (if any) indicated above.
          </p>
  
          <p>
              If the Property is sold for the Licensees ESTIMATE of:
              <strong>
                  ${startPrice + (endPrice ? " - " + endPrice : "")}
              </strong>
          </p>
  
          <p>
              the GST inclusive remuneration would be:
              <strong> ${commissionRange}</strong>
          </p>
  
          <p>Commission notes:</p>
  
          <p>
              <strong>IMPORTANT: </strong>
              <span>
                  This is an exclusive agency agreement. This means you may have
                  to pay the agent commission even if another agent (or you)
                  sells the property or introduces a buyer who later buys the
                  property.
              </span>
          </p>
          <p>
              <strong>WARNING: </strong>
              <span>
                  Have you signed an agency agreement for the sale of this
                  Property with another agent? If you have, you may have to pay
                  2 commissions (if this agreement or the other agreement you
                  have signed is a sole or exclusive agency agreement).
              </span>
          </p>
      </div>
  </section>
  <section>
      <h3>
          E. EXPENSES AND CHARGES <small>[CLAUSE 5]</small>
      </h3>
  
      <div class="col-12">
          <p>
              Government and other imposts are to be reimbursed as charged.
          </p>
          <p>
              The expenses or charges the Licensee expects to incur in
              connection with services to be provided under the Agency
              Agreement, and for which the Licensee is entitled under the
              Agency Agreement to be reimbursed (including any advertising and
              promotion costs) are as follows:
          </p>
          <p>
              Description of expense or charge, and services it is connected
              with:
          </p>
      </div>
      <div>
          <table>
              <thead class="border-bottom text-start">
                  <tr>
                      <th class="py-1 pr-2">
                          Description of expense of charge, and services it is
                          connected with,
                      </th>
                      <th class="py-1 px-2">
                          Estimated or Actual (GST inclusive) See attached annexure
                      </th>
                      <th class="py-1 pl-2">
                          When reimbursement is expected to be due and payable to
                          the Licensee
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td class="py-1 pr-2">Total marketing expenses</td>
                      <td class="py-1 px-2 text-start">
                          As per shopping cart
                      </td>
                      <td class="py-1 pl-2 text-center">
                          Upon Invoice
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </section>
  <section>
      <h3>
          F. ADVERTISING AND PROMOTION <small>[CLAUSE 6]</small>
      </h3>
      <div>
          <p>
              <strong>Manner of Advertising and Promotion: </strong>
              At Agent discretion upon meeting with Vendor/s.
          </p>
      </div>
  </section>
  <section>
      <h3>
          G. INSPECTION OF PROPERTY <small>[CLAUSE 7]</small>
      </h3>
      <div>
          <p>
              <strong>Manner of Inspection: </strong>
              Accompanied by an Agent.
          </p>
      </div>
  </section>
  <section>
      <h3>
          H. DISCLOSURE OF REBATES, DISCOUNTS, COMMISSIONS OR OTHER BENEFITS
          <small>[CLAUSE 20]</small>
      </h3>
  
      <div>
          <table>
              <thead class="border-bottom text-start">
                  <tr>
                      <th class="py-1 pr-2">Name of source:</th>
                      <th class="py-1 px-2">Description: </th>
                      <th class="py-1 pl-2">
                          Actual or Estimate (to the extent it can reasonably be
                          estimated) (GST inclusive:)
                      </th>
                  </tr>
              </thead>
              <tbody class="text-secondary">
                  <tr>
                      <td class="py-1 pr-2">NIL</td>
                      <td class="py-1 px-2">NIL</td>
                      <td class="py-1 pl-2">NIL</td>
                  </tr>
              </tbody>
          </table>
      </div>
  </section>
   <section>
          <h3>ADDITIONAL INSTRUCTIONS</h3>
          <div>
              <p>
                  Termination clause: The vendor may terminate this agreement at
                  any time with one (1) days notice
              </p>
          </div>
      </section>
  
      <section>
          <h3>MATERIAL FACT DISCLOSURE</h3>
          <div>
              <p>
                  This disclosure is to be read in conjunction with clause 9 of
                  the Agreement.
              </p>
              <p>
                  In accordance with the requirements pursuant to section 52(1)(b)
                  of the Property and Stock Agents Act 2002 and Regulation 54 of
                  the Property and Stock Agents Regulation 2014, please disclose
                  whether the following material facts are applicable to the
                  property the subject of this agreement.
              </p>
              <p>Don't Know.</p>
              <p>
                  Building product rectification order has the same meaning as in
                  the Building Products (Safety) Act 2017.
              </p>
              <p>
                  External combustible cladding and fire safety order have the
                  same meanings as in the Environmental Planning and Assessment
                  Regulation 2000.
              </p>
          </div>
      </section>
  
      <section>
          <h3>ESTIMATED SELLING PRICE - EVIDENCE</h3>
          <div>
              <p>
                  By signing below the Vendor acknowledges having received the
                  following evidence of the reasonableness of the estimated
                  selling price
              </p>
              <p>
                  <strong>Comparative Market Analysis</strong>
              </p>
              <p>
  
                  The Agent and the Vendor/s acknowledge and confirm that before
                  signing this agreement the Agent and the Vendor/s have read and
                  understood and agree to the terms and conditions in Part 3 of
                  this agreement and the Vendor/s acknowledges being served (by
                  electronic means or otherwise) with a copy of this agreement
                  within 48 hours after this agreement was signed by or on behalf
                  of the Licensee.
              </p>
              <p>
                  The approved guide entitled “Agency Agreements for the sale of
                  Residential Property” was provide to the Vendor within one month
                  of this agreement being signed or on behalf of the Vendor/s
                  (failure to do so is an offence)
              </p>
              <p>Yes</p>
              <p>Date Provided: signing date comes here [Clause 13].</p>
          </div>
      </section>
  
      <section>
          <table class="w-full border-collapse">
              <thead>
                  <tr class="bg-gray-100">
                      <th class="py-2 px-3 text-start">Name</th>
                      <th class="py-2 px-3 text-start">Signature</th>
                      <th class="py-2 px-3 text-start">Date</th>
                  </tr>
              </thead>
              <tbody>
                  ${vendors
                    .map(
                      (vendor) => `
                  <tr class="border-b">
                      <td class="py-2 px-3">${vendor.firstName} ${vendor.lastName}</td>
                      <td class="py-2 px-3"> <img src=${vendor.signature} alt="agent sign" class="w-auto h-8"></img></td>
                      <td class="py-2 px-3">${vendor.signedDate}</td>
                  </tr>
                  `
                    )
                    .join("")}
              </tbody>
          </table>
      </section>
  </div>`;
console.log(recommendedSold)
  const [isEditing, setIsEditing] = useState(false);
  const [editableContent, setEditableContent] = useState(
    saleProcess === "Auction" ? auctionTerms : privateTerms
  );
  const [originalContent, setOriginalContent] = useState(editableContent);
  const editableRef = useRef(null);

  const handleEditClick = () => {
    setOriginalContent(editableContent); // Store the current content before editing
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (editableRef.current) {
      const updatedContent = editableRef.current.innerHTML;
      setEditableContent(updatedContent);

      // Save the updated content in the parent component
      const termsType =
        saleProcess === "Auction" ? "auctionTerms" : "privateTerms";
      onSaveTerms(updatedContent, termsType);
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    if (editableRef.current) {
      editableRef.current.innerHTML = originalContent; // Restore the content immediately
    }
    setIsEditing(false);
  };

  useEffect(() => {
    if (editableRef.current && isEditing) {
      editableRef.current.innerHTML = editableContent;
    }
  }, [isEditing, editableContent]);

  // Handle adding a new item to recommendedSales or recommendedSold
  const handleAddItem = (type) => {
    if (type === "recommendedSales") {
      recommendedSales.onAdd({
        address: "",
        price: "",
        score: "",
      });
    } else if (type === "recommendedSold") {
      recommendedSold.onAdd({
        address: "",
        price: "",
        score: "",
      });
    }
  };

  // Handle deleting an item from recommendedSales or recommendedSold
  const handleDeleteItem = (type, index) => {
    if (type === "recommendedSales") {
      recommendedSales.onDelete(index);
    } else if (type === "recommendedSold") {
      recommendedSold.onDelete(index);
    }
  };

  // Handle updating an item in recommendedSales or recommendedSold
  const handleUpdateItem = (type, index, field, value) => {
    if (type === "recommendedSales") {
      recommendedSales.onUpdate(index, {
        ...recommendedSales.recommendedSales[index],
        [field]: value,
      });
    } else if (type === "recommendedSold") {
      recommendedSold.onUpdate(index, {
        ...recommendedSold.recommendedSold[index],
        [field]: value,
      });
    }
  };

  return (
    <div className="terms-condition">
      <div
        dangerouslySetInnerHTML={{
          __html: uneditableContent,
        }}
      />

      <div className="terms-condition">
        <div className="text-right my-4">
          {!isEditing ? (
            <Button className="black-button" onClick={handleEditClick}>
              Edit
            </Button>
          ) : (
            <>
              <Button className="black-button mr-2" onClick={handleSaveClick}>
                Save
              </Button>
              <Button className="gray-button" onClick={handleCancelClick}>
                Cancel
              </Button>
            </>
          )}
        </div>

        <div
          ref={editableRef}
          className={`engaged-purchaser ${
            isEditing
              ? "border border-mediumgray outline-none focus:outline-none"
              : ""
          }`}
          contentEditable={isEditing}
          dangerouslySetInnerHTML={{ __html: editableContent }}
        />
      </div>

      <h4 className="text-center">SOLD MATCHES</h4>
      <div className="flex justify-end">
        <FaPlus
          onClick={() => handleAddItem("recommendedSold")}
          className="cursor-pointer"
        />{" "}
      </div>
      {recommendedSold.recommendedSold.length > 0 && (
        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-lightgray">
                <th className="py-2 px-3 text-start">Address</th>
                <th className="py-2 px-3 text-start">Price</th>
                <th className="py-2 px-3 text-start"></th>
              </tr>
            </thead>
            <tbody>
              {recommendedSold.recommendedSold.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-3">
                    <EditableField
                      value={item.address}
                      onSave={(value) =>
                        handleUpdateItem(
                          "recommendedSold",
                          index,
                          "address",
                          value
                        )
                      }
                    />
                  </td>
                  <td className="py-2 px-3">
                    <EditableField
                      value={item.price}
                      onSave={(value) =>
                        handleUpdateItem(
                          "recommendedSold",
                          index,
                          "price",
                          value
                        )
                      }
                      isCurrency
                    />
                  </td>
                  <td className="py-2 px-3">
                    {" "}
                    <DeleteField
                      onDelete={() =>
                        handleDeleteItem("recommendedSold", index)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <br />

      <h4 className="text-center">SALE MATCHES</h4>
      <div className="flex justify-end">
        <FaPlus
          onClick={() => handleAddItem("recommendedSales")}
          className="cursor-pointer"
        />{" "}
      </div>
      {recommendedSales.recommendedSales.length > 0 && (
        <div className="w-full overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-lightgray">
                <th className="py-2 px-3 text-start">Address</th>
                <th className="py-2 px-3 text-start">Price</th>
                <th className="py-2 px-3 text-start"></th>
              </tr>
            </thead>
            <tbody>
              {recommendedSales.recommendedSales.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-3">
                    <EditableField
                      value={item.address}
                      onSave={(value) =>
                        handleUpdateItem(
                          "recommendedSales",
                          index,
                          "address",
                          value
                        )
                      }
                    />
                  </td>
                  <td className="py-2 px-3">
                    <EditableField
                      value={item.price}
                      onSave={(value) =>
                        handleUpdateItem(
                          "recommendedSales",
                          index,
                          "price",
                          value
                        )
                      }
                      isCurrency
                    />
                  </td>
                  <td className="py-2 px-3">
                    {" "}
                    <DeleteField
                      onDelete={() =>
                        handleDeleteItem("recommendedSales", index)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <br />
      <h4 className="text-center">MARKETING</h4>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border">
          <thead>
            <tr>
              <th className="border px-4 py-2 bg-lightgray text-left">Item</th>
              <th className="border px-4 py-2 bg-lightgray text-left">Price</th>
            </tr>
          </thead>
          <tbody>
            {marketing?.categories
              ?.flatMap((category) =>
                category.items.filter((item) => item.isChecked)
              )
              ?.map((item) => (
                <tr key={item._id}>
                  <td className="border px-4 py-2">{item.name || "N/A"}</td>
                  <td className="border px-4 py-2">${item.price || "0"}</td>
                </tr>
              ))}

            {!marketing?.categories?.some((category) =>
              category.items.some((item) => item.isChecked)
            ) && (
              <tr>
                <td className="border px-4 py-2" colSpan="2">
                  No items selected
                </td>
              </tr>
            )}

            {marketing?.agentContribution?.isChecked && (
              <tr>
                <td className="border px-4 py-2">Agent Contribution</td>
                <td className="border px-4 py-2">
                  ${marketing.agentContribution.amount || "N/A"}
                </td>
              </tr>
            )}

            <tr>
              <td className="border px-4 py-2 font-bold">TOTAL</td>
              <td className="border px-4 py-2">${marketing?.total || "0"}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <br />
      <PdfViewer />
    </div>
  );
};

export default AuthoriseScheduleEditor;
