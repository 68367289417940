import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import logo from "assets/images/logo.png";
import ausrealtyLogo from "assets/images/ausrealy-logo.svg";
import "assets/css/style.css";
import Button from "./Button";

const sections = [
  { id: "video", label: "VIDEO" },
  { id: "gallery", label: "GALLERY" },
  { id: "map", label: "MAP" },
  { id: "description", label: "DESCRIPTION" },
  { id: "floorplan", label: "FLOORPLAN" },
  { id: "contact", label: "CONTACT" },
  { id: "documents", label: "RELEVANT DOCUMENTS" },
];

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const offset = -120; // Adjust for the navbar height
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const MarketingNavbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [activeSection, setActiveSection] = useState("");

  // Handle Sidebar Toggle
  const handleTogglerClick = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // Detect Active Section on Scroll
  // useEffect(() => {
  //   const handleScroll = () => {
  //     let currentSection = "";
  //     sections.forEach((section) => {
  //       const element = document.getElementById(section.id);
  //       if (element) {
  //         const rect = element.getBoundingClientRect();
  //         if (rect.top <= 90 && rect.bottom >= 90) {
  //           currentSection = section.id;
  //         }
  //       }
  //     });
  //     setActiveSection(currentSection);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <nav className="bg-white sticky top-0 z-50 mb-4">
      <div className="w-full flex items-center justify-between">
        {/* Menu Icon for Mobile */}
        {/* <Button
          onClick={handleTogglerClick}
          className="lg:hidden flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30"
            height="30"
          >
            <path
              stroke="rgba(33, 37, 41, 0.75)"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2"
              d="M4 7h22M4 15h22M4 23h22"
            />
          </svg>
        </Button> */}

        {/* Logo */}
        <div className="w-full flex-grow flex justify-center">
          <Link to="/" className="w-full">
            {/* <img
              src={ausrealtyLogo}
              alt="Realestate company logo"
              className="w-[150px] h-auto"
            /> */}
            <header className="w-full bg-black text-white px-6 py-10 text-center flex items-center justify-center">
              <img src={ausrealtyLogo} alt="AUSREALTY" />
            </header>
          </Link>
        </div>

        {/* Menu Items for Large Screens */}
        {/* <ul className="hidden lg:flex space-x-8 text-xs list-none">
          {sections.map((section) => (
            <li key={section.id} className="m-0">
              <button
                onClick={() => {
                  scrollToSection(section.id);
                  setActiveSection(section.id);
                }}
                className={`text-sm ${
                  activeSection === section.id ? "underline font-semibold" : ""
                }`}
              >
                {section.label}
              </button>
            </li>
          ))}
        </ul> */}
      </div>

      {/* Sidebar for Small Screens */}
      <div
        className={`fixed inset-y-0 left-0 bg-white shadow-lg w-full max-w-[400px] transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40 overflow-y-auto`}
      >
        <div
          className="mt-4 flex justify-end pr-4 cursor-pointer"
          onClick={() => setIsSidebarOpen(false)}
        >
          <RxCross1 />
        </div>
        <div className="flex flex-col p-4">
          <ul className="mt-8 space-y-8 list-none">
            {sections.map((section) => (
              <li key={section.id} className="m-0 pl-4">
                <button
                  onClick={() => {
                    scrollToSection(section.id);
                    setIsSidebarOpen(false);
                  }}
                  className={`text-sm ${
                    activeSection === section.id
                      ? "underline font-semibold"
                      : ""
                  }`}
                >
                  {section.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Sidebar Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </nav>
  );
};

export default MarketingNavbar;
