import React, { useEffect, useState } from "react";
import FiveStepProcess from "./FiveStepProcess";
import InteractiveTable from "components/ui/Table/InteractiveTable";
import SalesTable from "components/ui/Table/SalesTable";
import axios from "utils/axios";
import ProcessChain from "./ProcessChain";
const OnMarket = ({ property}) => {
  const [authSaleProcess, setAuthSaleProcess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/authSchedule/${property._id}`);
        const data = response.data.data; // Adjust according to your API response structure

        if (data) {
          console.log("data", data);
          setAuthSaleProcess(data?.saleProcess || 'Private');
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching vendor data:", error);
      }
    };

    fetchData();
  }, []);
  
  return (
    <div className="w-full h-full max-w-4xl mx-auto text-center flex flex-col justify-center items-center space-y-6">
      <p>{property.address}</p>
      {/* <AudioTranscript/> */}
      <FiveStepProcess
        address={property.address}
        fiveStepProcess={property.fiveStepProcess}
        conclusion={property.conclusion}
        property={property}
        authSaleProcess={authSaleProcess}
        isLoading={isLoading}
        reserveMeetingReport={property?.reserveMeetingReport}
      />
      <InteractiveTable
        propertyId={property._id}
        address={property.address}
      />
      <ProcessChain property={property} />
      
      <SalesTable 
          salesTable={
            property?.salesTable || []
          }
          propertyId={property._id}
          property={property}
        />
       
    </div>
  );
};

export default OnMarket;
