// App.js
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./utils/ProtectedRoute";
import CompleteProfile from "./pages/CompleteProfile";
import Navbar from "components/ui/Navbar";
import PropertyTable from "./pages/Property";
import PropertyDetail from "./pages/SingleProperty";
import Sandy from "./pages/Sandy";
import StartCampaign from "pages/StartCampaign";

import Chat from "pages/Chat";
import NotFound from "pages/NotFound";
import { ToastContainer } from "react-toastify";
import QuickSearch from "pages/QuickSearch";
import QuickSearchShare from "pages/share/QuickSearchShare";

import { useJsApiLoader } from "@react-google-maps/api";
import PreList from "pages/share/Prelist";
import PostListShare from "pages/share/PostlistShare";
import Profile from "pages/Profile";
import PdfViewer from "pages/PdfView";
import Esign from "pages/ESign";
import FileUpload from "pages/FileUpload";
import CAPayments from "pages/CAPayments";

import DuplicateProperties from "pages/DuplicateProperties";
import Marketing from "pages/Marketing";
import MarketingNavbar from "components/ui/MarketingNavbar";
import Dashboard from "pages/Dashboard";

const libraries = ["places"]; // Removed "maps" as it's invalid

const App = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY, // Ensure this is correctly set
    libraries, // Corrected libraries
    version: "weekly",
  });

  const location = useLocation();

  const hideNavbarRoutes = [
    "/login",
    "/share",
    "/prelist",
    "/postlist",
    "/prelist/share",
    "/postlist/share",
    "/agreement",
    "/esign",
    "/upload",
    "/quick-search/share",
    "/marketing",
  ];

  if (loadError) {
    return <div>Error loading Google Maps. Please try again later.</div>;
  }

  if (!isLoaded) {
    return <div></div>; // You can replace this with a loader/spinner component
  }

  return (
    <AuthProvider>
      {!hideNavbarRoutes.some((route) =>
        location.pathname.startsWith(route)
      ) && <Navbar />}

      {location.pathname.startsWith("/marketing") && <MarketingNavbar />}

      <ToastContainer />

      <Routes>
        <Route path="*" element={<NotFound />} />

        <Route path="/duplicate" element={<DuplicateProperties />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />

        <Route
          path="/complete-profile"
          element={
            <ProtectedRoute>
              <CompleteProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/sandy"
          element={
            <ProtectedRoute>
              <Sandy />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <StartCampaign />
            </ProtectedRoute>
          }
        />

        <Route
          path="/quick-search"
          element={
            <ProtectedRoute>
              <QuickSearch />
            </ProtectedRoute>
          }
        />
        {/* 
        <Route
          path="/share/:id"
          element={
    
              <QuickSearchShare />
   
          }
        /> */}

        <Route path="/quick-search/share/:id" element={<QuickSearchShare />} />

        <Route
          path="/chat/:address"
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />

        <Route path="/property" element={<PropertyTable />} />
        <Route path="/property/:id" element={<PropertyDetail />} />

        <Route path="/prelist/:prelistLink" element={<PreList />} />
        <Route path="/postlist/:postlistLink" element={<PostListShare />} />

        <Route path="/prelist/share/:prelistLink" element={<PreList />} />
        <Route
          path="/postlist/share/:postlistLink"
          element={<PostListShare />}
        />

        <Route path="/agreement/:id" element={<PdfViewer />} />

        <Route path="/esign/:propertyId/:vendorId" element={<Esign />} />

        <Route path="/upload/:path/:propertyId" element={<FileUpload />} />

        <Route path="/campaign" element={<CAPayments />} />

        <Route path="/marketing" element={<Marketing />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
