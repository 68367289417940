import React, { useState, useRef, useEffect } from "react";
import { MdSend } from "react-icons/md";

const CommentBox = ({
  onSend,
  onCancel,
  initialValue = "",
  showActions = false,
  maxRows = 8,
  placeholder = "Type your message...",
  className = "",
  clearAfterSend = true,
  onChange,
  ...rest
}) => {
  const [comment, setComment] = useState(initialValue);
  const textareaRef = useRef(null);

  // Auto-resize textarea with vertical scroll when exceeding maxRows
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to auto to correctly calculate scroll height
      textarea.style.height = "auto";

      // Calculate new height
      const scrollHeight = textarea.scrollHeight;
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
      const maxHeight = lineHeight * maxRows;

      // Set height to scrollHeight or maxHeight
      textarea.style.height = Math.min(scrollHeight, maxHeight) + "px";

      // Enable vertical scrolling if content exceeds maxRows
      textarea.style.overflowY = scrollHeight > maxHeight ? "auto" : "hidden";
    }
  }, [comment, maxRows]);

  const handleChange = (e) => {
    const newComment = e.target.value;
    setComment(newComment);

    // Call optional onChange handler
    if (onChange) {
      onChange(e);
    }
  };

  const handleSend = () => {
    if (comment.trim()) {
      // Call the provided onSend callback
      onSend(comment);

      // Clear textarea if specified
      if (clearAfterSend) {
        setComment("");
      }
    }
  };

  const handleKeyDown = (e) => {
    // Optional: Add Shift+Enter for new line, Enter for send
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className={`w-full relative group ${className}`}>
      <textarea
        ref={textareaRef}
        value={comment}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        rows={1}
        {...rest}
        className={`w-full form-textarea pr-10 text-base rounded-lg bg-lightergray resize-none overflow-y-auto placeholder-darkgray ${
          rest.className || ""
        }`}
        onTouchStart={(e) => e.stopPropagation()}
        onTouchMove={(e) => e.stopPropagation()}
        onTouchEnd={(e) => e.stopPropagation()}
        style={{
          maxHeight: `${maxRows * 1.5}em`, // Adjust max height based on maxRows
        }}
      />
      {showActions ? (
        <div className="flex items-center justify-end gap-2 mt-2">
          <button
            onClick={onCancel}
            className="px-3 py-1 text-xs text-darkergray border border-mediumgray rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSend}
            disabled={!comment.trim() || comment === initialValue}
            className={`px-3 py-1 text-xs text-white rounded ${
              !comment.trim() || comment === initialValue
                ? "bg-mediumgray cursor-not-allowed"
                : "bg-black"
            }`}
          >
            Update
          </button>
        </div>
      ) : (
        comment.trim() && (
          <button
            onClick={handleSend}
            className="absolute right-2 bottom-4 text-darkergray transition-colors duration-200 rounded-full p-1"
          >
            <MdSend />
          </button>
        )
      )}
    </div>
  );
};

export default CommentBox;
