import React, { useRef, useEffect } from "react";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaListOl,
  FaListUl,
  FaHeading,
  FaParagraph,
} from "react-icons/fa";

const CustomEditor = ({ value, setValue, onSave, onCancel }) => {
  const editorRef = useRef(null);

  // Handle text formatting
  const formatText = (command, value = null) => {
    document.execCommand(command, false, value);
    editorRef.current.focus();
  };

  // Sync incoming value (HTML content) to editor
  useEffect(() => {
    if (editorRef.current && value !== editorRef.current.innerHTML) {
      editorRef.current.innerHTML = value || "";
    }
  }, [value]);

  // Handle content changes
  const handleInput = () => {
    if (editorRef.current) {
      setValue(editorRef.current.innerHTML);
    }
  };

  return (
    <div>
      {/* Toolbar */}
      <div className="flex items-center justify-between p-2 bg-lightergray">
        {/* Formatting Buttons */}
        <div className="flex gap-3">
          <button
            onClick={() => formatText("bold")}
            title="Bold"
            style={buttonStyle}
          >
            <FaBold />
          </button>
          <button
            onClick={() => formatText("italic")}
            title="Italic"
            style={buttonStyle}
          >
            <FaItalic />
          </button>
          <button
            onClick={() => formatText("underline")}
            title="Underline"
            style={buttonStyle}
          >
            <FaUnderline />
          </button>
          <button
            onClick={() => formatText("insertOrderedList")}
            title="Ordered List"
            style={buttonStyle}
          >
            <FaListOl />
          </button>
          <button
            onClick={() => formatText("insertUnorderedList")}
            title="Unordered List"
            style={buttonStyle}
          >
            <FaListUl />
          </button>
          <button
            onClick={() => formatText("formatBlock", "h5")}
            title="Heading (H5)"
            style={buttonStyle}
          >
            <FaHeading />
          </button>
          <button
            onClick={() => formatText("formatBlock", "p")}
            title="Paragraph"
            style={buttonStyle}
          >
            <FaParagraph />
          </button>
        </div>

        {/* Save and Cancel Buttons */}
        <div className="flex gap-2">
        <button className="gray-button px-2 py-1 text-xs" onClick={onCancel}>
            Cancel
          </button>
          <button className="black-button px-2 py-1 text-xs" onClick={onSave}>
            Save
          </button>
        </div>
      </div>

      {/* Editable Area */}
      <div
        ref={editorRef}
        contentEditable
        onInput={handleInput}
        style={{
          padding: "10px",
          minHeight: "200px",
          backgroundColor: "#ffffff",
          outline: "none",
          fontSize: "16px",
          lineHeight: "1.5",
        }}
        placeholder="Start writing here..."
      ></div>
    </div>
  );
};

// Button styles for the toolbar
const buttonStyle = {
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  padding: "4px",
  color: "#555",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default CustomEditor;
